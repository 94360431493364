import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { Divider } from 'primereact/divider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import getFiltroPermissao from '../../../service/getFiltroPermissao';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from 'primereact/tree';
import { Nullable } from 'primereact/ts-helpers';

export default function FiltroAutomotiva({
    onProcess,
    onClose
  }: {
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [placa, setPlaca] = useState<Nullable<string | null | undefined>>("");
  const [chassi, setChassi] = useState<string>("");
  const [motor, setMotor] = useState<string>("");
  const [documento, setDocumento] = useState<string>("");
  const [renavam, setRenavam] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [filtros, setFiltros] = useState<any[]>([]);
  const msgs = useRef<Messages>(null);
  const [selectedTipoVeiculo, setselectedTipoVeiculo] = useState();
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | any | null>(null); 
  const tiposVeiculo = [
    { name: "Selecione", code: null },
    { name: "Carros", code: "carros" },
    { name: "Motos", code: "motos" },
    { name: "Caminhões", code: "caminhoes" },
  ];

  const mensagemErroFiltro = (mensagem : string) => {
    window.scrollTo(0, 0);
    msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: mensagem, closable: false }
        
      ]);
  }

  const changeFilter = (value:any) => {
    getFiltroPermissao(value.key).then((data) => {
      setSelectedFilter(value);
      setFiltros(data);
    })
  }

  const validFiltro = (filteWeb :FilterWeb, filtro:any) => {
    if(filtro.tipoFiltro.includes("MOTOR") && filtro.obrigatorio && !filteWeb.motor){
      return false;
    }
    if(filtro.tipoFiltro.includes("PLACA") && filtro.obrigatorio && !filteWeb.placa){
      return false;
    }
    if(filtro.tipoFiltro.includes("CHASSI") && filtro.obrigatorio && !filteWeb.chassi){
      return false;
    }
    if(filtro.tipoFiltro.includes("RENAVAM") && filtro.obrigatorio && !filteWeb.renavam){
      return false;
    }
    if(filtro.tipoFiltro.includes("DOCUMENTO") && filtro.obrigatorio && !filteWeb.documento){
      return false;
    }
    if(filtro.tipoFiltro.includes("TIPO_VEICULO") && filtro.obrigatorio && !filteWeb.tipoVeiculo){
      return false;
    }
    return true;
  }

  const valid = (filteWeb :FilterWeb) => {
    if(filteWeb.consultas.length === 0){
       mensagemErroFiltro('Opções de consulta obrigatório')
       return false;
    }
    let retorno = true;
    filtros.forEach((filtro:any) => {
      if(!validFiltro(filteWeb, filtro)){
        if(!filtro.obrigatorioMinimo || !verifyObrigatorioMinimo(filteWeb, filtro)){
          mensagemErroFiltro(filtro.descricao + ' obrigatório');
          retorno = false;
          return false;
        }
      }
    })


    return retorno;
  }


  const verifyObrigatorioMinimo = (filteWeb :FilterWeb, filtro:any) => {
    let retorno = true;
    filtros.filter((s1:any) => s1.tipoFiltro !== filtro.tipoFiltro && s1.obrigatorioMinimo ).forEach((f:any) => {
      retorno = validFiltro(filteWeb, f);
      if(retorno){
        return true;
      }
    })
  
    if(retorno){
      return true;
    }
    else{
      return false;
    }
  }

  const processConsulta = () => {

    let opcoes: string[] = [];
    if(selectedFilter){
      opcoes.push(selectedFilter.key);
    }
    
   
    const filteWeb :FilterWeb = {
      documento: documento,
      placa: placa,
      chassi: chassi,
      motor: motor, 
      consultas: opcoes,
      valor: null,
      arrojado: null,
      usual:null,
      conservador: null,
      indicadores: [],
      nomeRazao:null,
      uf:null,
      cidade:null,
      dataNascimento:null,
      ddi: null,
      ddd: null,
      telefone: null,
      email: null, 
      renavam : renavam,
      tipoVeiculo: selectedTipoVeiculo
    };
    if(valid(filteWeb)){
      onProcess(filteWeb);
    }
   
  }

  const validDocument = (document:string) => {
    window.scrollTo(0, 0);
    let valid = true
    if(document.length === 14){
      valid = verifyCPF(document)
    }
    else{
      valid = verifyCnpj(document)
    }
    if(valid){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
             
      ]);
    }
  }

  const handleDocumento = (event:any) => {
    event.target.value =  maskCpfCnpj(event.target.value)
  }
 
  const verifyCnpj : any = (cnpj:string) => {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj === '') return false;
     
    if (cnpj.length !== 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho) + '';
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    
 
}
 
  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    if (strCPF == "11111111111") return false;
    if (strCPF == "22222222222") return false;
    if (strCPF == "33333333333") return false;
    if (strCPF == "44444444444") return false;
    if (strCPF == "55555555555") return false;
    if (strCPF == "66666666666") return false;
    if (strCPF == "77777777777") return false;
    if (strCPF == "88888888888") return false;
    if (strCPF == "99999999999") return false;
 
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
 
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
 
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
 
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}
 
  const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}
 
const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}
 
const maskCpfCnpj = (input:string) =>{
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}
const createOptions = () =>{
  const option : any[] = [];

  state.user.consultas.filter((f: {
    permissoes: any;
    tipoConsulta: string; 
  }) => f.tipoConsulta ===  'AUTO')
  .sort( (a:any,b:any) => {
    {
      if(!a.ordenacao){
        return 1;
      }

      if(!b.ordenacao){
        return -1;
      }

      if (a.ordenacao > b.ordenacao) {
        return 1;
      }
      if (a.ordenacao < b.ordenacao) {
        return -1;
      }
      return 0;
    }
  } )
  .map((element: any) => {
      const filhos = element.permissoes ? element.permissoes.filter((f: {
        tipoConsulta: string; 
      }) => f.tipoConsulta ===  'AUTO') : [];
      if(filhos.length > 0){
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(filhos) : []});
      }
      
    });
          expandAll(option)      
  return option;
}

const expandNode = (node:any, _expandedKeys:any) => {
  if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
          expandNode(child, _expandedKeys);
      }
  }
};

const expandAll = (option:any) => {
  let _expandedKeys = {};

  for (let node of option) {
      expandNode(node, _expandedKeys);
  }

 return _expandedKeys;
};

const createPermissoesFilhos = (permissoes:any[]) => {
  const option : any[] = [];
  if(permissoes){
    permissoes
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .forEach((element: {
      children: any; label: string; value: string; permissoes:any[]  
    }) => {
      
      option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
        tipoConsulta: string; 
      }) => f.tipoConsulta === 'AUTO')) : []});
    });
  }
  return option;
}
const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
  let label = <span><RadioButton inputId={node.key} name="filter" value={node} onChange={(e) => changeFilter(e.value)} checked={selectedFilter?.key === node.key} /><label htmlFor={node.key} className="ml-2">{node.label}</label></span>;
  if (node.children.length !== 0) {
     label = <b>{node.label}</b>;
  }

  return label;
}
const footerContent = (
  <React.Fragment>
        <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
        <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setFiltros([])} className="p-button-text" />
  </React.Fragment>
  );

  

  return (<>
    <Panel header={t('consultaAutomotiva')}>
        <div className=" p-fluid flex flex-wrap gap-3">
          <Tree filter filterMode="lenient" nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions())} value={createOptions()} selectionMode="single" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);  }} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
        </div>
 
    </Panel>
    <Dialog header={selectedFilter?.label} visible={filtros.length > 0} style={{ width: '80vw' }} onHide={() => setFiltros([])} footer={footerContent}>
      <Messages ref={msgs} />
      {filtros.filter((s1:any) => s1.tipoFiltro === 'PLACA').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="placa" className={filtros.filter((s1:any) => s1.tipoFiltro === 'PLACA')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Placa</label>
            <InputMask id="placa" value={placa} onChange={(e)=>{setPlaca(e.target.value);}} onBlur={(e)=>{setPlaca(e.target.value);}} mask="aaa-9*99" placeholder="Placa" required></InputMask>
          </div>
          <br></br>
        </>
      )}

      {filtros.filter((s1:any) => s1.tipoFiltro === 'CHASSI').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="chassi" className={filtros.filter((s1:any) => s1.tipoFiltro === 'CHASSI')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Chassi</label>
            <InputText id="chassi" value={chassi} onChange={(e)=>{setChassi(e.target.value);}}  placeholder="Chassi" required></InputText>
          </div>
          <br></br>
        </>
      )}
      
      {filtros.filter((s1:any) => s1.tipoFiltro === 'MOTOR').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="motor" className={filtros.filter((s1:any) => s1.tipoFiltro === 'MOTOR')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Motor</label>
            <InputText id="motor" value={motor} onChange={(e)=>{setMotor(e.target.value);}}  placeholder="Motor" required></InputText>
          </div>
          <br></br>
        </>
      )}

      {filtros.filter((s1:any) => s1.tipoFiltro === 'RENAVAM').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="renavam" className={filtros.filter((s1:any) => s1.tipoFiltro === 'RENAVAM')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Renavam</label>
            <InputText id="renavam" value={renavam} onChange={(e)=>{setRenavam(e.target.value);}}  placeholder="Renavam" required></InputText>
          </div>
          <br></br>
        </>
      )}

      {filtros.filter((s1:any) => s1.tipoFiltro === 'DOCUMENTO').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="documento" className={filtros.filter((s1:any) => s1.tipoFiltro === 'DOCUMENTO')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Documento</label>
            <InputText id="documento" onKeyUp={handleDocumento} value={documento} onChange={(e)=>{setDocumento(e.target.value);}}  placeholder="Documento" required></InputText>
          </div>
          <br></br>
        </>
      )}

      {filtros.filter((s1:any) => s1.tipoFiltro === 'TIPO_VEICULO').length > 0 && (
        <>
          <div className="flex-auto">
            <label htmlFor="tipoVeiculo" className={filtros.filter((s1:any) => s1.tipoFiltro === 'TIPO_VEICULO')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>Tipo Veículo</label>
            <Dropdown
                value={selectedTipoVeiculo}
                onChange={(e) => setselectedTipoVeiculo(e.value)}
                options={tiposVeiculo}
                optionLabel="name"
                style={{width:'18rem'}}
                placeholder="Selecione o tipo veículo"
                optionValue="code"
                className="md:w-14rem"
              />
          </div>
          <br></br>
        </>
      )}


      
    </Dialog>
    </>
  )
}
