import { useTranslation } from "react-i18next";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";

export default function AutomotivaGravame({
  automotivaGravame,
}: {
  automotivaGravame: any;
}) {
  const { t } = useTranslation();
  const automotivaAgregado = automotivaGravame?.AGREGADOSXML;

  const getValue = (value?: string): string => value || "NADA CONSTA";
  return (
    <Panel header={t("Automotiva Agregado")} style={{ width: "98%" }}>
      {automotivaGravame?.RETORNO?.MSG_ERRO && (
        <p>MENSAGEM: {automotivaGravame?.RETORNO?.MSG_ERRO}</p>
      )}

      {!automotivaGravame?.RETORNO?.MSG_ERRO && (
        <>
          <Divider align="left">
            <div className="flex-score justify-content-between justify-content-center">
              <span
                className="p-tag"
                style={{
                  position: "relative",
                  backgroundColor: "#183462",
                  fontSize: "1.1rem",
                  height: "2rem",
                  marginTop: "0.5rem",
                }}
              >
                Resumo do Veículo
              </span>
            </div>
          </Divider>

          <br></br>

          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("placa")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaGravame?.GRAVAMEXML?.PLACA)}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("marcaModelo")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaAgregado?.AGREGADOS_MARCAMODELO)}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("chassi")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaGravame?.RETORNO.CHASSI_SNG)}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("motor")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaAgregado?.AGREGADOS_MOTOR)}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("renavam")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaAgregado?.AGREGADOS_RENAVAM)}
                />
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("cor")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaAgregado?.AGREGADOS_COR)}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("combustivel")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={getValue(automotivaAgregado?.AGREGADOS_COMBUSTIVEL)}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("anoFabricaoModelo")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    getValue(automotivaAgregado?.AGREGADOS_ANOFABRICACAO) +
                    "/" +
                    getValue(automotivaAgregado?.AGREGADOS_ANOMODELO)
                  }
                />
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <Divider align="left">
            <span
              className="p-tag"
              style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
            >
              Agregados
            </span>
          </Divider>
          <br></br>

          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Chassi"
                  value={getValue(automotivaGravame?.RETORNO.CHASSI_SNG)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Cilindradas"
                  value={getValue(
                    automotivaAgregado?.AGREGADOS_CAPACIDADE_CILINDRADA
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Espécie"
                  value={getValue(automotivaAgregado?.AGREGADOS_ESPECIE)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Combustível"
                  value={getValue(automotivaAgregado?.AGREGADOS_COMBUSTIVEL)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Potência"
                  value={getValue(automotivaAgregado?.AGREGADOS_POTENCIA)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Modelo"
                  value={getValue(automotivaAgregado?.AGREGADOS_MARCAMODELO)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Marca"
                  value={getValue(automotivaAgregado?.FABRICANTE)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Placa"
                  value={getValue(automotivaGravame?.GRAVAMEXML?.PLACA)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Ano Fabricação"
                  value={getValue(automotivaAgregado?.AGREGADOS_ANOFABRICACAO)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Ano Modelo"
                  value={getValue(automotivaAgregado?.AGREGADOS_ANOMODELO)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Capacidade Passageiros"
                  value={getValue(
                    automotivaAgregado?.AGREGADOS_QUANTIDADE_PASSAGEIROS
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Tipo"
                  value={getValue(automotivaAgregado?.AGREGADOS_TIPO)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Município"
                  value={getValue(automotivaAgregado?.MUNICIPIO)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="UF"
                  value={getValue(automotivaGravame?.GRAVAMEXML?.UFPLACA)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Procedência"
                  value={getValue(automotivaAgregado?.AGREGADOS_PROCEDENCIA)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Motor"
                  value={getValue(automotivaAgregado?.AGREGADOS_MOTOR)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Cor"
                  value={getValue(automotivaAgregado?.AGREGADOS_COR)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Renavan"
                  value={getValue(automotivaAgregado?.AGREGADOS_RENAVAM)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Situação chassi"
                  value={getValue(
                    automotivaAgregado?.AGREGADOS_SITUACAO_CHASSI
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="CMT"
                  value={getValue(automotivaAgregado?.AGREGADOS_CMT)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Data Atualização"
                  value={getValue(automotivaAgregado?.AGREGADOS_DATA_ATUALIZAO)}
                ></OutPutCard>
              </div>
            </div>

            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Capacidade Carga"
                  value={getValue(
                    automotivaAgregado?.AGREGADOS_CAPACIDADE_CARGA
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="PBT"
                  value={getValue(automotivaAgregado?.AGREGADOS_PBT)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Tipo Carroceria"
                  value={getValue(
                    automotivaAgregado?.AGREGADOS_TIPO_CARROCERIA
                  )}
                ></OutPutCard>
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <Divider align="left">
            <span
              className="p-tag"
              style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
            >
              Gravame
            </span>
          </Divider>
          <br></br>

          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Código Financeira"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.CODIGOFINANCEIRA
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Vigência"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.DATAGRAVAMEVIGENCIA
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="UF Gravame"
                  value={getValue(automotivaGravame?.GRAVAMEXML?.UFGRAVAME)}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Data Gravame"
                  value={getValue(automotivaGravame?.GRAVAMEXML?.DATAGRAVAME)}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Status"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.DESCRICAOSTATUS
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Financeira"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.FINANCEIRANOME
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Documento Financeira"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.DOCUMENTOFINANCEIRA
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Contrato"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.NUMEROCONTRATO
                  )}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Documento Proprietário"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.DOCUMENTOPROPRIETARIOATUAL
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Status Veículo"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.STATUSDOVEICULO
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Nome Financiado"
                  value={getValue(
                    automotivaGravame?.GRAVAMEXML?.NOMEFINANCIADO
                  )}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap"></div>
          </div>
        </>
      )}
    </Panel>
  );
}
