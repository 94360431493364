import { Panel } from "primereact/panel";
import { useTranslation } from "react-i18next";
import { InfoDisplay } from "../../../components/InfoDisplay";
import { TitleDivider } from "../../../components/TitleDivider";
import { IAutomotivaRenainf } from "../../../interface/AutomotivaRenainf";
import OutPutCard from "../../../components/outputCard";

export default function AutomotivaRenainf(data: IAutomotivaRenainf) {
  const { t } = useTranslation();
  const { REGISTROS, RETORNO } = data;

  const getValue = (value?: string): string => value || "Não informado";

  return (
    <Panel header={t("Automotiva Renainf")} style={{ width: "98%" }}>
      {!RETORNO?.MSG_ERRO && (
        <>
          <TitleDivider title="Resumo do Veículo" />
          <br />

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "24px",
            }}
          >
            <InfoDisplay label="Placa" value={getValue(RETORNO?.PLACA)} />
            <InfoDisplay
              label="Periodo informado"
              value={getValue(RETORNO?.PERIODOINFORMADO)}
            />
            <InfoDisplay
              label="Total de ocorrências"
              value={getValue(RETORNO?.QTDOCORRENCIATOTAL.toString())}
            />
          </div>

          <br />

          <TitleDivider title="Renainf" />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {REGISTROS.map((registro, index) => (
              <Panel key={index} header={`Registro ${index + 1}`} toggleable>
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "24px",
                  }}
                >
                  {Object.entries(registro).map(([key, value]) => (
                    <OutPutCard
                      key={`${key}-${index}`}
                      label={labels[key]}
                      value={getValue(String(value))}
                    />
                  ))}
                </div>
              </Panel>
            ))}
          </div>
        </>
      )}

      {RETORNO?.MSG_ERRO && <p>{RETORNO?.MSG_ERRO}</p>}
    </Panel>
  );
}

const labels: Record<string, string> = {
  DETALHE_VALOR_INFRACAO: "Valor da Infração",
  NUMEROAUTOINFRACAO: "Número do Auto de Infração",
  DETALHE_PLACA: "Placa do Veículo",
  DADOSDOPAGAMENTO_VALOR_PAGO: "Valor Pago",
  ORGAO: "Órgão Responsável",
  DADOSINFRATOR_CNH_INFRATOR: "CNH do Infrator",
  DETALHE_COD_MUN_EMPLACAMENTO: "Código do Município de Emplacamento",
  DETALHE_UF_ORGAO_AUTUADOR: "Estado do Órgão Autuador",
  DETALHE_UF_JURISDICAO_VEICULO: "Estado de Jurisdição do Veículo",
  DETALHE_COD_INFRACAO: "Código da Infração",
  DADOSINFRATOR_CNH_CONDUTOR: "CNH do Condutor",
  DETALHE_MEDICAO_REAL: "Velocidade Real",
  DETALHE_UF_PLACA: "Estado da Placa",
  DETALHE_DT_NOTIFICACAO_INFRACAO: "Data da Notificação da Infração",
  EXIGIBILIDADE: "Exigibilidade",
  CONSULTADETALHE_MENSAGEM: "Mensagem de Detalhe",
  CONSULTADETALHE_EXISTE_ERRO: "Erro no Detalhe",
  DETALHE_LIMITE_PERMITIDO: "Limite de Velocidade Permitido",
  DETALHE_CADASTRAMENTO_INFRACAO: "Data de Cadastramento da Infração",
  DETALHE_UNIDADE_MEDIDA: "Unidade de Medida",
  DADOSDOPAGAMENTO_DT_PAGAMENTO: "Data do Pagamento",
  DADOSDASUSPENSAO_ORIGEM: "Origem da Suspensão",
  DETALHE_DT_INFRACAO: "Data da Infração",
  DADOSDOPAGAMENTO_UF_PAGAMENTO: "Estado do Pagamento",
  DETALHE_MEDICAO_CONSIDERADA: "Medição Considerada",
  DETALHE_NUM_AUTO_INFRACAO: "Número do Auto de Infração",
  DADOSDASUSPENSAO_TIPO: "Tipo de Suspensão",
  INFRACAO: "Código da Infração",
  DETALHE_LOCAL_INFRACAO: "Local da Infração",
  DETALHE_ORGAO_AUTUADOR: "Órgão Autuador",
  DADOSDOPAGAMENTO_DADOS_PGMTO: "Dados do Pagamento",
  DETALHE_TIPO_AUTO_INFRACAO: "Tipo do Auto de Infração",
  DATADAINFRACAO: "Data da Infração",
  DETALHE_COD_MUN_INFRACAO: "Código do Município da Infração",
  DETALHE_AMRCAMODELO: "Modelo do Veículo",
  DADOSDASUSPENSAO_ACEITE_UF_JURISDICAO: "Estado de Aceite da Suspensão",
  DETALHE_HR_INFRACAO: "Horário da Infração",
  DADOSDOPAGAMENTO_DT_DO_REGISTRO_DO_PGMTO: "Data de Registro do Pagamento",
  DADOSDASUSPENSAO_DATA_REGISTRO: "Data de Registro da Suspensão",
  DETALHE_DT_EMISSAO_PENALIDADE: "Data de Emissão da Penalidade",
};
