import { useTranslation } from "react-i18next";
import {Tooltip } from 'react-tooltip'
import Divider from "./Divider";
import {MenuItemInterface} from "../../interface/MenuItemInterface"
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import { GlobalSaveData } from "../globalState/GlobalSaveData";
type Props = {
  isActive: boolean,
  item: MenuItemInterface
}
 
const MenuItemTree = ({ isActive, item }: Props) => {
  const { t } = useTranslation();
  const stateGlobal = useGlobalState();
  const navigate = useNavigate();
  const handleIsActive = (url: string) => {
    const isActive = window.location.pathname.split('\/')[window.location.pathname.split('\/').length - 1] === url.split('\/')[url.split('\/').length - 1];
    return isActive;
   };
  const handleNavigate = (url:string, params:any) => (event:any) => {
    event.preventDefault();
    navigate(url, { state: params} );
  };
  const handleToggleSubMenu = (value:string) => {
    GlobalSaveData("submenu", value);
  };
  type PropsWrapper = {
    item:MenuItemInterface
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
  }
  const Wrapper = ({item, children} : PropsWrapper) => {
   
    if (!!stateGlobal?.state.sidebarOpen) return <>{children}</>;
    return (
      <>
        { item.itens ? ( <a  className={handleIsActive(item.url) ? "active" : ""}  style={{cursor:"pointer"}}
            onClick={() => handleToggleSubMenu(item.permission)} data-tooltip-id="my-tooltip" data-tooltip-content={t(item.label)}><span className={'icon pi ' + item.icon}></span></a>) : ( <a  className={handleIsActive(item.url) ? "active" : ""}  style={{cursor:"pointer"}}
            onClick={handleNavigate(item.url, item.params)} data-tooltip-id="my-tooltip" data-tooltip-content={t(item.label)}><span className={'icon pi ' + item.icon}></span></a>)}
       
        <Tooltip positionStrategy="fixed" id="my-tooltip" place="right"  children={children}/>
       
      </>
    );
  };
 
  return (
    <>
      {!!item.hasDivider && <Divider style={{ margin: 0 }} />}
      <li key={'li_' + item.label} className={(stateGlobal?.state.submenu?.includes(item.permission) ? "active-menuitem " : "") + (!!stateGlobal?.state.sidebarOpen ? " active-menuitem-open" : "" )}>
        <Wrapper item={item} >
          <a
            key={item.permission}
            style={{cursor:"pointer"}}
            onClick={()=>handleToggleSubMenu(item.permission)}
            className={handleIsActive(item.url) ? "active" : ""}
          >
            <span className={'icon pi ' + item.icon}></span>
            <span className="label">{t(item.label)}</span>
          </a>
        </Wrapper>
        <ul>
          {item.itens!.map(i => (
            <li key={'children_' + i.permission} className="">
              <Wrapper item={i}>
                <a
                  href={i.url}
                  onClick={handleNavigate(i.url, i.params)}
                  className={handleIsActive(i.url) ? "active" : ""}
                >
                  <span className={'icon pi ' + i.icon}></span>
                  <span className="label">{t(i.label)}</span>
                </a>
              </Wrapper>
            </li>
          ))}
        </ul>
      </li>
    </>
  );
};
 
export default MenuItemTree;