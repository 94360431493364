import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from 'primereact/tree';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { Fieldset } from 'primereact/fieldset';
import { PickList } from 'primereact/picklist';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import getFiltroPermissao from '../../../service/getFiltroPermissao';
import { RadioButton } from 'primereact/radiobutton';
import { Nullable } from 'primereact/ts-helpers';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';


export default function FiltroClassificacao({
    classificacao,
    onProcess,
    onClose,
    msgs
  }: {
    classificacao:string
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void,
    msgs:any
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [cpf, setCpf] = useState<Nullable<string | null | undefined>>("");
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null>(null); 
  const [selectedOptionCheck, setSelectedOptionCheck] = useState<any[]>([]);
  const [targets, setTargets] = useState<any[]>([]);
  const [nomeRazao, setNomeRazao] = useState<string>("");
  const [uf, setUf] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [nascimento, setNascimento] = useState<string>("");
  const [ddi, setDDI] = useState<string>("");
  const [ddd, setDDD] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [filtros, setFiltros] = useState<any[]>([]);
  const [cnpj, setCnpj] = useState<Nullable<string | null | undefined>>("");
  const [valor, setValor] = useState<number|null|undefined>(null);
  const [arrojado, setArrojado] = useState<boolean|undefined>(false);
  const [usual, setUsual] = useState<boolean|undefined>(false);
  const [conservador, setConservador] = useState<boolean|undefined>(false);

  const validCPF = (documento:string) => {
    if(verifyCPF(documento)){
      msgs.current?.clear();
      
    }
    else{
      window.scrollTo(0, 0);
      msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
          
        ]);
    }

  }

  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
  if (strCPF == "00000000000") return false;
  if (strCPF == "11111111111") return false;
  if (strCPF == "22222222222") return false;
  if (strCPF == "33333333333") return false;
  if (strCPF == "44444444444") return false;
  if (strCPF == "55555555555") return false;
  if (strCPF == "66666666666") return false;
  if (strCPF == "77777777777") return false;
  if (strCPF == "88888888888") return false;
  if (strCPF == "99999999999") return false;

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

  Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

const validCnpj = (documento:string) => {
  if(verifyCnpj(documento)){
    msgs.current?.clear();
    
  }
  else{
    window.scrollTo(0, 0);
    msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
        
      ]);
  }
}

const verifyCnpj = (cnpj:string) => {
 
  cnpj = cnpj.replace(/[^\d]+/g,'');

  if(cnpj == '') return false;
  
  if (cnpj.length != 14)
      return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" || 
      cnpj == "11111111111111" || 
      cnpj == "22222222222222" || 
      cnpj == "33333333333333" || 
      cnpj == "44444444444444" || 
      cnpj == "55555555555555" || 
      cnpj == "66666666666666" || 
      cnpj == "77777777777777" || 
      cnpj == "88888888888888" || 
      cnpj == "99999999999999")
      return false;
       
  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho) + '';
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
          pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != parseInt(digitos.charAt(0)))
      return false;
       
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != parseInt(digitos.charAt(1)))
        return false;
         
  return true;
  

}

const expandNode = (node:any, _expandedKeys:any) => {
  if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
          expandNode(child, _expandedKeys);
      }
  }
};

const createOptions = () =>{
  const option : any[] = [];

  state.user.consultas.filter((f: {
    tipoClassificacao: string; 
  }) => f.tipoClassificacao ===  classificacao)
  .sort( (a:any,b:any) => {
    {
      if(!a.ordenacao){
        return 1;
      }

      if(!b.ordenacao){
        return -1;
      }

      if (a.ordenacao > b.ordenacao) {
        return 1;
      }
      if (a.ordenacao < b.ordenacao) {
        return -1;
      }
      return 0;
    }
  } )
  .map((element: any) => {
     
      option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
        tipoClassificacao: string; 
      }) => f.tipoClassificacao ===  classificacao)) : []});
          });
    
  

          expandAll(option)      
  return option;
}

const createPermissoesFilhos = (permissoes:any[]) => {
  const option : any[] = [];
  if(permissoes){
    permissoes
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .forEach((element: {
      children: any; label: string; value: string; permissoes:any[]  
    }) => {
      
      option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
        tipoClassificacao: string; 
      }) => f.tipoClassificacao === classificacao)) : []});
    });
  }
  return option;
}

const mensagemErroFiltro = (mensagem : string) => {
  window.scrollTo(0, 0);
  msgs.current?.clear();
    msgs.current?.show([
        { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: mensagem, closable: false }
      
    ]);
}

const validFiltro = (filteWeb :FilterWeb, filtro:any) => {
  if(filtro.tipoFiltro.includes("CPF") && filtro.obrigatorio && !filteWeb.documento){
    return false;
  }
  if(filtro.tipoFiltro.includes("INDICADORES") && filtro.obrigatorio && !filteWeb.indicadores){
    return false;
  }
  if(filtro.tipoFiltro.includes("NOME") && filtro.obrigatorio && !filteWeb.nomeRazao){
    return false;
  }
  if(filtro.tipoFiltro.includes("EMAIL") && filtro.obrigatorio && !filteWeb.email){
    return false;
  }
  if(filtro.tipoFiltro.includes("TELEFONE") && filtro.obrigatorio && !filteWeb.telefone){
    return false;
  }
  if(filtro.tipoFiltro.includes("CNPJ") && filtro.obrigatorio && !filteWeb.documento){
    return false;
  }
  if(filtro.tipoFiltro.includes("QUODVERIFIQPJ_MAISNEGOCIO") && filtro.obrigatorio && !filteWeb.valor){
    return false;
  }
  return true;
}

const verifyObrigatorioMinimo = (filteWeb :FilterWeb, filtro:any) => {
  let retorno = true;
  filtros.filter((s1:any) => s1.tipoFiltro !== filtro.tipoFiltro && s1.obrigatorioMinimo ).forEach((f:any) => {
    retorno = validFiltro(filteWeb, f);
    if(retorno){
      return true;
    }
  })
  if(retorno){
    return true;
  }
  else{
    return false;
  }
}

const valid = (filteWeb :FilterWeb) => {
  if(filteWeb.consultas.length === 0){
     mensagemErroFiltro('Opções de consulta obrigatório')
     return false;
  }
  let retorno = true;
  filtros.forEach((filtro:any) => {
    if(!validFiltro(filteWeb, filtro)){
      if(!filtro.obrigatorioMinimo || !verifyObrigatorioMinimo(filteWeb, filtro)){
        mensagemErroFiltro(filtro.descricao + ' obrigatório');
        retorno = false;
        return false;
      }
    }
  })
  return retorno;
}
const expandAll = (option:any) => {
  let _expandedKeys = {};

  for (let node of option) {
      expandNode(node, _expandedKeys);
  }

 return _expandedKeys;
};

  const processConsulta = () => {
    const opcoes: string[] = [];
    if(selectedFilter){
      opcoes.push(selectedFilter.key);
    }
    selectedOptionCheck.map((item) => opcoes.push(item.value));
    const filteWeb :FilterWeb = {
      documento: cpf ? cpf : cnpj ? cnpj : null,
      consultas: opcoes,
      valor: valor,
      arrojado: arrojado,
      usual: usual,
      conservador: conservador,
      indicadores: targets.filter((s:any) => opcoes.includes(s.value)),
      placa: null,
      motor: null,
      chassi: null,
      nomeRazao:nomeRazao,
      uf:uf,
      cidade:cidade,
      dataNascimento:nascimento,
      ddi: ddi,
      ddd: ddd,
      telefone: telefone,
      email: email,
      renavam:  null
    };
    if(valid(filteWeb)){
      onProcess(filteWeb);
    }
   
  }
  const footerContent = (
    <React.Fragment>
          <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setFiltros([])} className="p-button-text" />
    </React.Fragment>
  );
  const onChange = (event:any, key:string) => {
    const targetsOut = [{value: key, target: event.target}]
    targets.filter((s:any) => s.value !== key).map((item:any) => targetsOut.push(item));
    setTargets(targetsOut)
};
  const pickItemTemplate = (item:any) => {
    return (
        <div className="flex flex-wrap p-2 align-items-center gap-3">
            <div className="flex-1 flex flex-column gap-2">
                <span className="font-bold">{item.label}</span>
            </div>
        </div>
    );
};

const changeFilter = (value:any) => {
  getFiltroPermissao(value.key).then((data) => {
    setSelectedFilter(value);
    setFiltros(data);
  })
}

const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
  let label = <span><RadioButton inputId={node.key} name="filter" value={node} onChange={(e) => changeFilter(e.value)} checked={selectedFilter?.key === node.key} /><label htmlFor={node.key} className="ml-2">{node.label}</label></span>;
  if (node.children.length !== 0) {
     label = <b>{node.label}</b>;
  }

  return label;
}

  return (<>
    <Panel header={t(classificacao === 'CADASTRAL' ? 'consultaCadastral' : 'consultaCredito')}>
        <div className=" p-fluid flex flex-wrap gap-3">
          <Tree filter filterMode="lenient" nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions())} value={createOptions()} selectionMode="single" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);  }} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
        </div>
        <Dialog header={selectedFilter?.label} visible={filtros.length > 0} style={{ width: '80vw' }} onHide={() => setFiltros([])} footer={footerContent}>
          <Messages ref={msgs} />
          
          {filtros.filter((s1:any) => s1.tipoFiltro === 'CPF').length > 0 && (
            <>
              <div className="flex-auto">
                <label htmlFor="cpf" className={filtros.filter((s1:any) => s1.tipoFiltro === 'CPF')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>CPF</label>
                <InputMask id="cpf" value={cpf} onChange={(e) => setCpf(e.target.value)} onBlur={(e)=>{setCpf(e.target.value);validCPF(e.target.value)}} mask="999.999.999-99" placeholder="999.999.999-99" required></InputMask>
              </div>
              <br></br>
            </>
          )}
          {filtros.filter((s1:any) => s1.tipoFiltro === 'CNPJ').length > 0 && (
                      <>
                        <div className="flex-auto">
                          <label htmlFor="cnpj" className={filtros.filter((s1:any) => s1.tipoFiltro === 'CNPJ')[0].obrigatorio ? 'font-bold block mb-2 required' : 'font-bold block mb-2'}>CNPJ</label>
                          <InputMask id="cnpj" value={cnpj} onChange={(e) => setCnpj(e.target.value)} onBlur={(e)=>{setCnpj(e.target.value);validCnpj(e.target.value)}} mask="99.999.999/9999-99" placeholder="99.999.999/9999-99" required></InputMask>
                        </div>
                        <br></br>
                      </>
                    )}
          
                    {filtros.filter((s1:any) => s1.tipoFiltro === 'QUODVERIFIQPJ_MAISNEGOCIO').length > 0 && (
                      <>
                        <div className="flex-auto">
                          <Fieldset onClick={(e)=> e.stopPropagation()} legend="Mais Negócio">
                            <div className="flex-auto">
                              <label htmlFor="currency" className="font-bold block mb-2 required">{t('valor')}</label>
                              <InputNumber inputId="currency" onValueChange={(e) => setValor(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                            </div>
                            <br></br>
                            <label htmlFor="currency" className="font-bold block mb-2 required">{t('perfilRisco')}</label>
                            <br></br>
                            <div className="flex align-items-center">
                              <Checkbox inputId="arrojado" name="arrojado" onChange={e => {e.originalEvent?.stopPropagation(); setArrojado(e.checked)}} checked={arrojado? arrojado : false}  />
                              <label htmlFor="arrojado" className="ml-2">{t('arrojado')}</label>
                            </div>
                            <br></br>
                            <div className="flex align-items-center">
                              <Checkbox inputId="usual" name="usual" onChange={e =>{e.originalEvent?.stopPropagation(); setUsual(e.checked)}} checked={usual? usual : false}  />
                              <label htmlFor="usual" className="ml-2">{t('usual')}</label>
                            </div>
                            <br></br>
                            <div className="flex align-items-center">
                              <Checkbox inputId="conservador" name="conservador" onChange={e =>{e.originalEvent?.stopPropagation(); setConservador(e.checked)}} checked={conservador? conservador : false}  />
                              <label htmlFor="conservador" className="ml-2">{t('conservador')}</label>
                            </div>
                          </Fieldset>
                        </div>
                        <br></br>
                      </>
                    )}

          
          {filtros.filter((s1:any) => s1.tipoFiltro === 'TELEFONE').length > 0 && (
            <>
              <div className="flex-auto">
                <Fieldset onClick={(e)=> e.stopPropagation()} legend={'Telefone'}>
                  <div className="flex-auto">
                    <label className="font-bold block mb-2 required">{t('DDI')}</label>
                    <InputText id="ddi" value={ddi} onChange={(e)=>{setDDI(e.target.value);}}  placeholder="DDI" required></InputText>         
                  </div>
                  <br></br>
                  <div className="flex-auto">
                    <label className="font-bold block mb-2 required">{t('ddd')}</label>
                    <InputText id="ddd" value={ddd} onChange={(e)=>{setDDD(e.target.value);}}  placeholder="DDD" required></InputText>         
                  </div>
                  <br></br>
                  <div className="flex-auto">
                    <label className="font-bold block mb-2 required">{t('fone')}</label>
                    <InputText id="telefone" value={telefone} onChange={(e)=>{setTelefone(e.target.value);}}  placeholder="Telefone" required></InputText>         
                  </div>
                  
                </Fieldset>
              </div>
              <br></br>
            </>
          )}
          {filtros.filter((s1:any) => s1.tipoFiltro === 'EMAIL').length > 0 && (
            <>
              <div className="flex-auto">
              <label className="font-bold block mb-2 required">{t('email')}</label>
              <InputText id="email" value={email} onChange={(e)=>{setEmail(e.target.value);}}  placeholder="E-mail" required></InputText>         
              </div>
              <br></br>
            </>
          )} 
          
          {filtros.filter((s1:any) => s1.tipoFiltro === 'NOME').length > 0 && (
            <>
              <div className="flex-auto">
                <label htmlFor="nome" className="font-bold block mb-2 required">{t('nome')}</label>
                <InputText id="nome" value={nomeRazao} onChange={(e)=>{setNomeRazao(e.target.value);}}  placeholder="Nome" required></InputText>         
              </div>
              <br></br>
              {selectedFilter.tipoConsulta === 'PF' && (
                <>
                   <div className="flex-auto">
                    <label htmlFor="uf" className="font-bold block mb-2">{t('uf')}</label>
                    <InputText id="uf" value={uf} onChange={(e)=>{setUf(e.target.value);}}  placeholder="UF" required></InputText>         
                  </div>
                  <br></br>
                  <div className="flex-auto">
                    <label htmlFor="cidade" className="font-bold block mb-2">{t('cidade')}</label>
                    <InputText id="cidade" value={cidade} onChange={(e)=>{setCidade(e.target.value);}}  placeholder="Cidade" required></InputText>      
                  </div>
                  <br></br>
                  <div className="flex-auto">
                    <label htmlFor="nascimento" className="font-bold block mb-2">{t('dataNascimento')}</label>
                    <InputText id="nascimento" value={nascimento} onChange={(e)=>{setNascimento(e.target.value);}}  placeholder="Data Nascimento" required></InputText>      
                  </div>
                  <br></br>
                </>
              ) }
             
            </>
          )} 
          {filtros.filter((s1:any) => s1.tipoFiltro === 'INDICADORES').length > 0 && (
            <>
              <div className="flex-auto">
              <Fieldset onClick={(e)=> e.stopPropagation()} legend={selectedFilter.label}>
                <PickList dataKey="value" onChange={(e) => {onChange(e, selectedFilter.key)}} itemTemplate={pickItemTemplate} showSourceControls={false} showTargetControls={false} source={selectedFilter.pickList.filter((s:any) => {return targets.filter((s:any) => s.value === selectedFilter.key)[0].target.filter((s2:any) => s.value === s2.value).length == 0} )} target={targets.filter((s:any) => s.value === selectedFilter.key)[0].target}  filter filterBy="label" 
                  sourceHeader="Indicadores" targetHeader="Selecionados" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }}
                  sourceFilterPlaceholder="Busca" targetFilterPlaceholder="Busca" />
              </Fieldset>
              </div>
              <br></br>
            </>
          )} 

        </Dialog>  
            
    </Panel>
   
    </>
  )
}
