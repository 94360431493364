import React from "react";
import { Panel } from "primereact/panel";
import { Image } from "primereact/image";
import { Card } from "../../../../components/Card";
import { transformBase64ToPng } from "../../../../utils/open_image_png_base64";
import { INexoImagensLeilao } from "../../../../interface/NexoImagensLeilao";

function isNull(data: any): boolean {
  if (!data?.imagens || data?.imagens?.length === 0) {
    return true;
  }

  return data?.imagens?.every((img: any) => img?.url === null);
}

export const NexoImagensLeilaoPage = (data: INexoImagensLeilao) => {
  return (
    <Panel header="Nexo Imagens Leilão" style={{ width: "98%" }} toggleable>
      <Card.Container>
        <Card.Item label="Placa" value={data?.placa} />
        <Card.Item label="Chassi" value={data?.chassi} />
      </Card.Container>

      <br />
      <br />

      {data?.leiloes?.map((item) => (
        <React.Fragment key={item?.numeroLeilao}>
          <Panel header={`Imagens Leilão ${item?.numeroLeilao}.0`} toggleable>
            <Card.Container style={{ gap: "40px" }}>
              {!isNull(item) &&
                item?.imagens?.map((img) => {
                  if (img?.url === null) return null;

                  return (
                    <Image
                      key={img?.url}
                      src={transformBase64ToPng(img?.url)}
                      preview
                      imageStyle={{
                        maxWidth: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  );
                })}
            </Card.Container>

            {isNull(item) && <p>Nada consta</p>}
          </Panel>

          <br />
          <br />
        </React.Fragment>
      ))}
    </Panel>
  );
};
