import { http } from '../../config/axios'

export default function putVeiculoTransferenciaComunicar(veiculoTransferencia: any): Promise<string> {
    veiculoTransferencia = { id: veiculoTransferencia.id};
    return http
        .put('api/veiculotransferencia/comunicartransferencia', veiculoTransferencia)
        .then((res) => {
            return res.data as string
        })
        .catch((error) => {
            console.error('Erro put veiculo transferencia comunicar', error)
            throw Error(error.response.data)
        })
}
