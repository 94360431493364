import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findParametroAplicacao from '../../service/findParametroAplicacao';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Nullable } from 'primereact/ts-helpers';
import getTipoParametroAplicacao from '../../service/getTipoParametroAplicacao';
import { Toolbar } from 'primereact/toolbar';


export default function ParametroAplicacaoList({onEdit}:{onEdit:(parametroAplicacao:any)=>void}) {
  const { t } = useTranslation();
  const [parametros, setParametros] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tipo, setTipo] = useState<Nullable<String | null>>(null);
  const [tipos, setTipos] = useState([]);
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    tipo: tipo,
    sortField: 'tipo',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      tipo: tipo,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      tipo: tipo,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const loadLazyData = () => {
    findParametroAplicacao(lazyState).then(data => {setTotalRecords(data.total);setParametros(data.parametros)}).catch(() => {setTotalRecords(0);setParametros(undefined)});
  }

  const editBodyTemplate = (servico:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(servico)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };

  const changeTipo = (tipo:Nullable<any>) => {
      tipo = tipo ? tipo : null
      if(tipo instanceof Object){
        tipo = null
      }
   
      setTipo(tipo);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        tipo: tipo,
        sortField: 'tipo',
        sortOrder: SortOrder.DESC
      });
    
  }

  const startContent = (
    <React.Fragment>
      <div  style={{background:'transparent'}}>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="tipo" className="font-bold block mb-2">{t('tipo')} </label>
                  <Dropdown value={tipo} onChange={(e) => changeTipo(e.value)} options={tipos} optionLabel="label" 
                    placeholder="Selecione o Tipo" optionValue='value' className="w-full md:w-14rem" />
                </div>
              </div>
        </div>
      </div>
      
    </React.Fragment>
  );
  
  useEffect(() => {
    getTipoParametroAplicacao().then( (data) => setTipos(data))
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div className="card">
        <Toolbar start={startContent} />
        <br></br>
         <br></br>
        <DataTable value={parametros} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros"
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="tipo" sortable header={t('tipo')}  />
           <Column field="valor" sortable header={t('valor')}  />
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


