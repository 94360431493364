import {
  CadastralChequeContumaciaProps,
  DadosFormatados,
  ApiResponseCadastralChequeContumacia,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from 'primereact/message';
import './index.css';
import { formatSpecificValuesToString, formatDefaultValuesToString } from '../../../../utils/ValidationUtils';

const labelDadosDetalhes = (key: string): string => {
  const labelDadosDetalhes: { [key: string]: string } = {
    STATUS_RETORNO: "Status Retorno:",
    REGISTRO_LOCALIZADO: "Registro Localizado:",
    QUANTIDADE_OCORRENCIA: "Quantidade Ocorrências:",
    CORRENTISTA: "Correntista:",
    TIPO_PESSOA: "Tipo Pessoa:",
    CPFCNPJ: "CPF/CNPJ:",
    DATA_PRIMEIRA_OCORRENCIA: "Data Primeira Ocorrência:",
    DATA_ULTIMA_OCORRENCIA: "Data Última Ocorrência:",
  };

  return labelDadosDetalhes[key] || key;
};

const labelEndereco = (key: string): string => {
  const labelEndereco: { [key: string]: string } = {
    UF: "Estado (UF):",
    BAIRRO: "Bairro:",
    ENDERECO: "Endereço:",
    CIDADE: "Cidade:",
    CEP: "CEP:",
  };

  return labelEndereco[key] || key;
};

const labelDadosReceitaFederal = (key: string): string => {
  const labelDadosReceitaFederal: { [key: string]: string } = {
    TIPO_PESSOA: "Tipo de Pessoa:",
    RAZAO_SOCIAL: "Razão Social:",
    SITUACAO_RECEITA: "Situação na Receita:",
    DATA_ATUALIZACAO: "Data de Atualização:",
    NATUREZA_JURIDICA: "Natureza Jurídica:",
    DATA_NASCIMENTO_FUNDACAO: "Data de Nascimento/Fundação:",
    EMAIL: "E-mail:",
    NOME: "Nome:",
    NOME_MAE: "Nome da Mãe:",
    SEXO: "Sexo:",
    ATIVIDADE_ECONOMICA_PRINCIPAL: "Atividade Econômica Principal:",
    //STATUS_RETORNO: "Status Retorno:",
  };

  return labelDadosReceitaFederal[key] || key;
};

const formatarDadosReceitaFederal = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosReceitaFederal(key) !== key).map(([key, value]) => ({
    label: labelDadosReceitaFederal(key),
    value: formatDefaultValuesToString(value),
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    array: [],
    statusRetorno: '',
  };
};

const formatarDadosEndereco = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelEndereco(key) !== key).map(([key, value]) => ({
    label: labelEndereco(key),
    value: formatSpecificValuesToString(key, value),
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    array: [],
    statusRetorno: '',
  };
};

const formatarDadosDetalhes = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];
  const renderizarDinamico = (rowData: any, field: string) => {
    const value = rowData[field];
    return formatDefaultValuesToString(value);
  };

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosDetalhes(key) !== key).map(([key, value]) => ({
    label: labelDadosDetalhes(key),
    value: formatDefaultValuesToString(value as string),
  }));

  const resultado: JSX.Element[] = [];
  if(dados.INFOEMAILS){
    resultado.push(
      <DataTable header="E-mails" size="normal" stripedRows value={dados.INFOEMAILS}>
        <Column field="ENDERECO" header="Endereço de E-mail" />
      </DataTable>
    );
  }

  if(dados.DADOS){
    if (categoriaNome === 'Somente Telefone') {
      resultado.push(
        <DataTable header="Telefones" size="normal" stripedRows value={dados.SOMENTE_TELEFONE}>
          <Column field="TIPO_PESSOA" header="Tipo Pessoa" />
          <Column field="DOCUMENTO" header="Documento" />
          <Column field="DDD" header="DDD" />
          <Column field="NUM_TELEFONE" header="Num. Telefone" />
          <Column field="PONTUACAO" header="Pontuação" />
          <Column field="TIPO_TELEFONE" header="Tipo Telefone" />
          <Column field="COD_IDENTIFICADAO_TELEFONE" header="Cod. Identificação Telefone" />
        </DataTable>
      );
    } else if (categoriaNome === 'Somente Endereço') {
      resultado.push(
        <DataTable header="Endereços" size="normal" stripedRows value={dados.SOMENTE_ENDERECO}>
          <Column field="TIPO_PESSOA" header="Tipo Pessoa" />
          <Column field="DOCUMENTO" header="Documento" />
          <Column field="UF" header="UF" />
          <Column field="COD_IDENTIFICACAO_ENDERECO" header="Cod. Identificação Endereço" />
          <Column field="BAIRRO" header="Bairro" />
          <Column field="COMPLEMENTO" header="Complemento" />
          <Column field="NUMERO" header="Número" />
          <Column field="PONTUACAO" header="Pontuação" />
          <Column field="ENDERECO" header="Endereço" />
          <Column field="CIDADE" header="Cidade" />
          <Column field="CEP" header="Cep" />
        </DataTable>
      ); 
    }
  }

  if (dados.OCORRENCIAS) {
    if (categoriaNome === 'Passagens Comerciais') {
      resultado.push(
        <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
          <Column field="CLIENTE_CONSULTA" header="Cliente Consulta" />
          <Column field="CIDADE_UF_CLIENTE" header="Cidade/UF Cliente" />
          <Column field="DATA_CONSULTA" header="Data Consulta" />
          <Column field="HORA_CONSULTA" header="Hora Consulta" />
          <Column field="TELEFONE_CLIENTE" header="Telefone Cliente" />
        </DataTable>
      );
    } else if (categoriaNome == "Cheques sem Fundos Bacen") {
      resultado.push(
        <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
          <Column field="NUM_BANCO" header="Código Banco" />
          <Column field="DCR_BANCO" header="Banco" />
          <Column field="NUM_AGENCIA" header="Agência" />
          <Column field="DADOS_AGENCIA" header="Dados Agência" />
          <Column field="MOTIVO_DEVOLUCAO" header="Motivo" />
          <Column field="DOC_RELACIONADO" header="Doc. Relacionado" />
          <Column field="QTD_CHEQUES" header="Quantidade" />
          <Column field="DT_ULTIMA_OCORRENCIA" header="Dt. Última Ocorrência" />
        </DataTable>
      );
    } else if (categoriaNome == "Cheques sem Fundos Varejo") {
      resultado.push(
        <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
          <Column field="NUM_BANCO" header="Código Banco" />
          <Column field="DCR_BANCO" header="Banco" />
          <Column field="NUM_AGENCIA" header="Agência" />
          <Column field="DADOS_AGENCIA" header="Dados Agência" />
          <Column field="MOTIVO_DEVOLUCAO" header="Motivo" />
          <Column field="DOC_RELACIONADO" header="Doc. Relacionado" />
          <Column field="QTD_CHEQUES" header="Quantidade" />
          <Column field="DT_ULTIMA_OCORRENCIA" header="Dt. Última Ocorrência" />
        </DataTable>
      );
    } else if (categoriaNome == "Informações Alertas Restrições") {
      resultado.push(
        <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
          <Column field="DESCRICAO_TIPO_INFORMACAO" header="Descrição Tipo Informação" />
          <Column field="OBSERVACOES" header="Observações" />
          <Column field="CODIGO_TIPO_INFORMACAO" header="Código Tipo Informação" />
          <Column field="TITULO" header="Título" />
        </DataTable>
      );
    } else {
      const fields = Object.keys(dados.OCORRENCIAS[0] || {});
      resultado.push(
        <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={labelDadosDetalhes(field)}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      );
    }
  }

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    array: resultado,
    statusRetorno: dados.STATUS_RETORNO ? 'Status Retorno: ' + dados.STATUS_RETORNO.CODIGO + " - " + dados.STATUS_RETORNO.DESCRICAO : '',
  };
};

const getDadosReceitaFederal  = (
  data: ApiResponseCadastralChequeContumacia
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.DADOS_RECEITA_FEDERAL)
    resultado.push(formatarDadosReceitaFederal(data.DADOS_RECEITA_FEDERAL, "Dados Receita Federal"));

  return resultado;
};

const getDadosEndereco  = (
  data: ApiResponseCadastralChequeContumacia
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.ENDERECO)
    resultado.push(formatarDadosEndereco(data.ENDERECO, "Endereço"));

  return resultado;
};

const getDadosDetalhes = (
  data: ApiResponseCadastralChequeContumacia
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.CONTUMACIA)
    resultado.push(formatarDadosDetalhes(data.CONTUMACIA, "Contumácia"));

  if (data.PASSAGENS_COMERCIAIS)
    resultado.push(formatarDadosDetalhes(data.PASSAGENS_COMERCIAIS, "Passagens Comerciais"));

  if (data.CH_SEM_FUNDOS_BACEN)
    resultado.push(formatarDadosDetalhes(data.CH_SEM_FUNDOS_BACEN, "Cheques sem Fundos Bacen"));
  
  if (data.INFORMACOES_ALERTAS_RESTRICOES)
    resultado.push(formatarDadosDetalhes(data.INFORMACOES_ALERTAS_RESTRICOES, "Informações Alertas Restrições"));

  if (data.CH_SEM_FUNDOS_VAREJO)
    resultado.push(formatarDadosDetalhes(data.CH_SEM_FUNDOS_VAREJO, "Cheques sem Fundos Varejo"));

  if (data.RECHEQUE)
    resultado.push(formatarDadosDetalhes(data.RECHEQUE, "Recheque"));

  if (data.SOMENTE_TELEFONE)
    resultado.push(formatarDadosDetalhes(data.SOMENTE_TELEFONE, "Somente Telefone"));

  if (data.SOMENTE_ENDERECO)
    resultado.push(formatarDadosDetalhes(data.SOMENTE_ENDERECO, "Somente Endereço"));

  if (data.EMAILS)
    resultado.push(formatarDadosDetalhes(data.EMAILS, "E-mails"));

  return resultado;
};

export function CadastralChequeContumacia({
  data,
}: CadastralChequeContumaciaProps) {
  const detalhes = getDadosDetalhes(data);
  const dadosReceitaFederal = getDadosReceitaFederal(data);
  const dadosEndereco = getDadosEndereco(data);

  return (
    <>
      <Panel className="p-panel-header-green" header="Cadastral Cheque Contumácia" style={{ width: '98%' }} toggleable>
        <><Panel className="p-panel-header-green" header="Dados Receita Federal" toggleable>
          {data.DADOS_RECEITA_FEDERAL.STATUS_RETORNO && <><Message severity="info" text={"Status Retorno: " + data.DADOS_RECEITA_FEDERAL.STATUS_RETORNO.CODIGO + " - " + data.DADOS_RECEITA_FEDERAL.STATUS_RETORNO.DESCRICAO} /><br></br><br></br></>}
          {dadosReceitaFederal.map(({ dados }) => (
            <DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
          ))}
        </Panel><br></br></>
        <><Panel className="p-panel-header-green" header="Dados Endereço" toggleable>
          {dadosEndereco.map(({ dados }) => (
            <DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
          ))}
        </Panel><br></br></>
        {detalhes.map(({ categoria, dados, array, statusRetorno }) => (
          <><Panel className="p-panel-header-green" header={categoria} toggleable>
            {statusRetorno && <><Message severity="info" text={statusRetorno} /><br></br><br></br></>}
            <DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          </Panel><br></br></>
        ))}
      </Panel>
    </>
  );
}
