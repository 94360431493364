import {
  ApiResponseCadastralChequeCerto,
  DadosFormatados,
  CadastralChequeCertoProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCpfOrCnpj } from '../../../../utils/ValidationUtils';

const formatarDadosGerais = (dados: any, categoriaNome: string): DadosFormatados => {
  const resultado: JSX.Element[] = [];
  if (dados.OCORRENCIAS) {
    const ocorrenciasComTipoPessoa = dados.OCORRENCIAS.map((ocorrencia: any) => ({
      ...ocorrencia,
      TIPO_PESSOA: dados.TIPO_PESSOA,
    }));
    resultado.push(
      <DataTable size="normal" stripedRows value={ocorrenciasComTipoPessoa}>
        <Column field="TIPO_PESSOA" header="Tipo" />
        <Column field="NUM_BANCO" header="Código Banco" />
        <Column field="DCR_BANCO" header="Banco" />
        <Column field="NUM_AGENCIA" header="Agência" />
        <Column field="MOTIVO_DEVOLUCAO" header="Motivo Devolução" />
        <Column field="QTD_CHEQUES" header="Quantidade" />
        <Column field="DOC_RELACIONADO" header="Documento Relacionado" body={(rowData) => formatCpfOrCnpj(rowData.DOC_RELACIONADO)}/>
        <Column field="DT_ULTIMA_OCORRENCIA" header="Dt. Última Ocorrência" />
        <Column field="DADOS_AGENCIA" header="Dados Agência" />
      </DataTable>
    );
  }

  return {
    categoria: categoriaNome,
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseCadastralChequeCerto
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.CH_SEM_FUNDOS_BACEN)
    resultado.push(formatarDadosGerais(data.CH_SEM_FUNDOS_BACEN, "Cheques sem Fundos Bacen"));

  return resultado;
};

export function CadastralChequeCerto({
  data,
}: CadastralChequeCertoProps) {
  const dados = getDadosGerais(data);

  return (
    <>
      <Panel header="Cadastral Cheque Certo" style={{ width: '98%' }} toggleable>
        {dados.map(({ categoria, array }) => (
          <><Panel header={categoria} toggleable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          </Panel><br></br></>
        ))}
      </Panel>
    </>
  );
}
