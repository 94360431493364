import { http } from '../../config/axios'

export default function getVeiculoTransferenciaTipoPessoa(): Promise<any> {
    return http
        .get('api/veiculotransferencia/tipospessoa/')
        .then((res) => {
            return res.data as any
        })
        .catch((error) => {
            console.error('Erro getVeiculoTransferenciaTipoPessoa', error)
            throw Error(error.response.data)
        })
}
