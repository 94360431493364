import { http } from "../../config/axios";

export default function getVeiculoTransferenciaMotivoCancelamento(): Promise<any> {
  return http
    .get("api/veiculotransferencia/motivoscancelamento/")
    .then((res) => {
      return res.data as any;
    })
    .catch((error) => {
      console.error("Erro getVeiculoTransferenciaMotivoCancelamento", error);
      throw Error(error.response.data);
    });
}
