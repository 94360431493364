import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message';
import './index.css';

export function AutomotivaAlertaDeIndicio({
  data,
}: any) {
  return (
    <>
      <Panel className="p-panel-header-green" header="Automotiva Alerta de Indício" style={{ width: '98%' }} toggleable>
        {data.ALERTA_INDICIO.EXISTE_OCORRENCIA == "1" && 
        <>
          <Message severity="warn" text={data.ALERTA_INDICIO.DESCRICAO_OCORRENCIA} />

          <br></br>
          <h1>Possíveis Causas</h1>
          <ul>
              <li>Veículos com avarias, possibilidade de lataria restaurada ou avarias onde não foi possível estimar com precisão quais os danos o veículo sofreu e a mecânica poderá apresentar defeito;</li>
              <li>Veículos com o acionamento de franquia parcial ou integral de seguro;</li>
              <li>Veículos com registros depreciação do bem na sua lataria e/ou estrutura;</li>
              <li>Veículos que tenham passagem por oficinas credenciadas pelas seguradoras para reparos;</li>
              <li>Veículos que faziam parte de frota empresarial, lojas de salvados ou locadoras;</li>
              <li>Veículos que tiveram alterações das características originais de fábrica;</li>
              <li>Veículo com informações cadastrais alteradas e possivelmente regularizadas dentro dos órgãos oficiais que podem impactar sua originalidade desde fabricação;</li>
              <li>Veículos com registros de Indício de Sinistro, Parecer Técnico, Securitário e Vistoria, podem ter dificuldade na contratação de seguros ou impacto no valor do seguro, cobertura e/ou prêmio.</li>
          </ul>
        </>
        }
        {data.ALERTA_INDICIO.EXISTE_OCORRENCIA != "1" && 
        <>
          <Message severity="success" text={data.ALERTA_INDICIO.DESCRICAO_OCORRENCIA == null 
            || data.ALERTA_INDICIO.DESCRICAO_OCORRENCIA == "" ? "NENHUM REGISTRO ENCONTRADO. NADA CONSTA." : data.ALERTA_INDICIO.DESCRICAO_OCORRENCIA} />
          <br></br>
        </>
        }
      </Panel>
    </>
  );
}
