import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Column } from 'primereact/column';
import getPermissao from '../../service/getPermissao';
import { TreeTable } from 'primereact/treetable';
import { Button } from 'primereact/button';

export default function PermissaoList({ onEdit, onSelect, date, listInactivePermissions }:{ onEdit:(permission:any)=>void, onSelect:(permission:any)=>void, date:Date, listInactivePermissions: boolean }) {
  const { t } = useTranslation();
  const [nodes, setNodes] = useState<any[]>([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>(null);

  const createPermissoes = () => {
    const option: any[] = [];
    getPermissao().then(data => {
      data.sort((s1:{ label: string}, s2:{label: string}) => {
        const nameA = s1.label.toUpperCase(); // ignore upper and lowercase
        const nameB = s2.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      }).forEach((element: any) => {
        if (listInactivePermissions || !element.inativa) {
          option.push({ data: element, key: element.id, Collapse: true, children: createPermissoesFilhos(element.permissoes) });
        }
      });
      setNodes(option);
    });
    return option;
  };

  const createPermissoesFilhos = (permissoes: any[]) => {
    const option: any[] = [];
    if (permissoes) {
      permissoes.sort((s1:{ label: string}, s2:{label: string}) => {
        const nameA = s1.label.toUpperCase(); // ignore upper and lowercase
        const nameB = s2.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      }).forEach((element: any) => {
        if (listInactivePermissions || !element.inativa) {
          option.push({ data: element, key: element.id, Collapse: true, children: createPermissoesFilhos(element.permissoes) });
        }
      });
    }
    return option;
  };

  const onSelected = (event: any) => {
    onSelect(event.node.data);
  };

  const onUnselect = (event: any) => {
    onSelect(null);
  };

  const actionTemplate = (event: any) => {
    return (
      <div className="flex flex-wrap gap-2" style={{ display: 'flex', justifyContent: 'end' }}>
        <Button icon="pi pi-pencil" onClick={(e) => {e.stopPropagation(); onEdit(event.data);}} style={{ background: 'transparent', color: 'gray', border: 'none' }} />
      </div>
    );
  };

  useEffect(() => {
    createPermissoes(); 
  }, [date, listInactivePermissions]); 

  return (
    <div>
      <TreeTable value={nodes} selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={(e) => setSelectedNodeKey(e.value)} metaKeySelection={false} onSelect={onSelected} onUnselect={onUnselect} tableStyle={{ minWidth: '50rem' }}>
        <Column field="label" header={t('permissoes')} expander></Column>
        <Column body={actionTemplate} />
      </TreeTable>
    </div>
  );
}
