import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

interface ToolbarCustomProps {
  onClose: () => void;
  onPrint: () => void;
}

export const ToolbarCustom = ({ onClose, onPrint }: ToolbarCustomProps) => {
  return (
    <Toolbar
      style={{ width: "98%" }}
      start={
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            label="Imprimir"
            icon="pi pi-print"
            style={{ backgroundColor: "#183462" }}
            onClick={onPrint}
          />

          <Button
            label="Nova consulta"
            style={{ backgroundColor: "#183462" }}
            onClick={onClose}
          />
        </div>
      }
      end={
        <Button
          aria-label="Cancel"
          icon="pi pi-times"
          severity="danger"
          onClick={onClose}
          rounded
          outlined
        />
      }
    />
  );
};
