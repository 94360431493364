import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ScoreProps {
  score: number;
}

export const ScoreCustom = ({ score }: ScoreProps) => {
  const options: Highcharts.Options = {
    chart: {
      width: 400,
    },
    title: {
      text: score.toString(),
      align: "left",
      verticalAlign: "middle",
      y: 10,
      x: 135,
      style: {
        fontSize: "40px",
        color: statusColor(score),
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["44%", "50%"],
      },
    },

    series: [
      {
        type: "pie",
        name: "",
        innerSize: "60%",
        data: [
          {
            name: "",
            y: score,
            color: statusColor(score),
            selected: true,
          },
          {
            name: "",
            y: 1000 - score,
            color: "#ccc",
            selected: true,
          },
        ],
      },
    ],
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

const statusColor = (value: number = 0): string => {
  const status = [
    { min: 0, max: 300, color: "#DE1616" },
    { min: 301, max: 750, color: "#FFCA39" },
    { min: 751, max: 1000, color: "#0DAB01" },
  ];

  const currentStatus = status.find((s) => value >= s.min && value <= s.max);

  return currentStatus?.color || "#DE1616";
};
