import {
  ApiResponseAutomotivaCertidaoNacionalDeDebitosTrab,
  DadosFormatados,
  AutomotivaCertidaoNacionalDeDebitosTrabProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';
import { formatDefaultValuesToString, openBase64Data } from '../../../../utils/ValidationUtils';

const labelDadosGerais = (key: string): string => {
  const labelDadosGerais: { [key: string]: string } = {
    DOCUMENTO: "Documento:",
    VALIDADE: "Validade:",
    VALOR_TOTAL: "Valor Total:",
    EXPEDICAO: "Expedição:",
    COD_CERTIDAO: "Código da Certidão:",
    EMITIU_CERTIDAO_NEGATIVA: "Emitiu Certidão Negativa:",
    EMISSAO: "Emissão:",
    QUANTIDADE_OCORRENCIAS: "Quantidade de Ocorrências:",
    MENSAGEM: "Mensagem:",
    NOME: "Nome:",
    CERTIDAO: "Certidão:"
  };

  return labelDadosGerais[key] || key;
};

const formatarDadosGerais = (dados: any): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosGerais(key) !== key).map(([key, value]) => ({
    label: labelDadosGerais(key),
    value: formatDefaultValuesToString(value as string),
  }));

  const resultado: JSX.Element[] = [];
  if (dados.OCORRENCIAS) {
    const renderizarDinamico = (rowData: any, field: string) => {
      const value = rowData[field];
      return formatDefaultValuesToString(value);
    };

    resultado.push(
      <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
        <Column field="UF" header="UF" />
        <Column field="SEGREDO_JUSTICA" header="Segredo Justica" />
        <Column field="GRAU_PROCESSO" header="Grau Processo" />
        <Column field="CLASSE_PROCESSUAL" header="Classe Processual" />
        <Column field="JULGAMENTOS" header="Julgamentos" body={(rowData) => renderizarDinamico(rowData.STATUS_PREDICTUS, 'JULGAMENTOS')}/>
        <Column field="STATUS_PROCESSO" header="Status Processo" body={(rowData) => renderizarDinamico(rowData.STATUS_PREDICTUS, 'STATUS_PROCESSO')}/>
        <Column field="DATA_DISTRIBUICAO" header="Data Distribuição" />
        <Column field="TRIBUNAL" header="Tribunal" />
        <Column field="NUMERO_PROCESSO" header="Número Processo" />
        <Column field="VALOR" header="Valor" />
        <Column field="DETALHE" header="Detalhe" />
        <Column field="PARTES" header="Partes" body={(rowData) => renderizarDinamico(rowData, 'PARTES')}/>
      </DataTable>
    );
  }

  return {
    dados: dadosFormatados,
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseAutomotivaCertidaoNacionalDeDebitosTrab
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.ACOES_TRABALHISTAS)
    resultado.push(formatarDadosGerais(data.ACOES_TRABALHISTAS));

  return resultado;
};

export function AutomotivaCertidaoNacionalDeDebitosTrab({
  data,
}: AutomotivaCertidaoNacionalDeDebitosTrabProps) {
  const dados = getDadosGerais(data);
console.log(dados)
  const renderValueColumn = (rowData: any) => {
    if (rowData.label === "Certidão:") {
      return data.ACOES_TRABALHISTAS.CERTIDAO.IMAGE_FILE.FILE_BASE64 || data.ACOES_TRABALHISTAS.CERTIDAO.PDF_FILE.FILE_BASE64 ? (
        <><button
          style={{
            background: "transparent",
            border: "none",
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
          }}
          onClick={() => openBase64Data(data.ACOES_TRABALHISTAS.CERTIDAO.IMAGE_FILE.FILE_BASE64, data.ACOES_TRABALHISTAS.CERTIDAO.IMAGE_FILE.MIME_TYPE)}
        >
          Abrir Imagem
        </button>
        <button
          style={{
            background: "transparent",
            border: "none",
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
          }}
          onClick={() => openBase64Data(data.ACOES_TRABALHISTAS.CERTIDAO.PDF_FILE.FILE_BASE64, data.ACOES_TRABALHISTAS.CERTIDAO.PDF_FILE.MIME_TYPE)}
        >
          Abrir PDF
        </button></>        
      ) : (
        "Não disponível"
      );
    }
    return <span>{rowData.value}</span>;
  };

  return (
    <>
      <Panel className="p-panel-header-green" header="Automotiva Certidão Nascional De Debitos Trabalhistas" style={{ width: '98%' }} toggleable>
        {dados.map(({ dados, array }) => (
          <><DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column body={renderValueColumn} header="Valor" />
            </DataTable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          <br></br></>
        ))}
      </Panel>
    </>
  );
}
