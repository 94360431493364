import { Panel } from "primereact/panel";
import { Card } from "../../../../components/Card";
import { getValue } from "../../../../utils/field_validator";
import { ISenatranCondutor } from "../../../../interface/SenatranCondutor";

export const SenatranCondutorPage = (data: ISenatranCondutor) => {
  return (
    <Panel header="Senatran Condutor" toggleable style={{ width: "98%" }}>
      {!data?.MENSAGEM && (
        <>
          <Panel header="Informações Pessoais" toggleable>
            <Card.Container title="Informações Pessoais">
              <Card.Item label="Nome Completo" value={getValue(data?.nome)} />
              <Card.Item label="CPF" value={getValue(data?.cpf)} />
              <Card.Item
                label="Data de Nascimento"
                value={data?.dataNascimento?.formatDate()}
              />
              <Card.Item label="Sexo" value={getValue(data?.descricaoSexo)} />
              <Card.Item label="Nome da Mãe" value={getValue(data?.nomeMae)} />
              <Card.Item label="Nome do Pai" value={getValue(data?.nomePai)} />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Documento" toggleable>
            <Card.Container>
              <Card.Item
                label="Tipo de Documento"
                value={getValue(data?.descricaoDocumento)}
              />
              <Card.Item
                label="Número do Documento"
                value={getValue(data?.numeroDocumento)}
              />
              <Card.Item
                label="Órgão Expedidor"
                value={getValue(data?.orgaoExpedidorDocumento)}
              />
              <Card.Item
                label="UF do Expedidor"
                value={getValue(data?.ufExpedidorDocumento)}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Dados da CNH" toggleable>
            <Card.Container>
              <Card.Item
                label="Número da CNH"
                value={getValue(data?.numeroRegistro)}
              />
              <Card.Item
                label="UF da Habilitação"
                value={getValue(data?.ufHabilitacaoAtual)}
              />
              <Card.Item
                label="Categoria Atual"
                value={getValue(data?.categoriaAtual)}
              />
              <Card.Item
                label="Data da 1ª Habilitação"
                value={data?.dataPrimeiraHabilitacao?.formatDate()}
              />
              <Card.Item
                label="Data de Validade"
                value={data?.dataValidadeCnh?.formatDate()}
              />
              <Card.Item
                label="Situação da CNH"
                value={getValue(data?.descricaoSituacaoCnh)}
              />
              <Card.Item
                label="UF da 1ª Habilitação"
                value={getValue(data?.ufPrimeiraHabilitacao)}
              />
              <Card.Item
                label="Quadro de Observações"
                value={getValue(data?.quadroObservacoesCnh)}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Últimas Atualizações" toggleable>
            <Card.Container>
              <Card.Item
                label="Data da Última Emissão"
                value={data?.dataUltimaEmissaoHistorico?.formatDate()}
              />
              <Card.Item
                label="Código da Transação de Atualização"
                value={getValue(String(data?.codigoTransacaoUltimaAtualizacao))}
              />
              <Card.Item
                label="Data da Atualização"
                value={data?.dataTransacaoUltimaAtualizacao?.formatDate()}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Renovação e Cursos" toggleable>
            <Card.Container>
              <Card.Item
                label="Última Renovação"
                value={data?.dataCursoAtualizacaoRenovacaoCnh?.formatDate()}
              />
              <Card.Item
                label="Modalidade da Renovação"
                value={getValue(
                  data?.descricaoModalidadeCursoAtualizacaoRenovacaoCnh
                )}
              />
              <Card.Item
                label="Curso de Reciclagem"
                value={getValue(
                  data?.descricaoModalidadeCursoReciclagemInfrator
                )}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Outros Dados" toggleable>
            <Card.Container>
              <Card.Item
                label="Restrição Médica"
                value={getValue(data?.restricoesMedicas)}
              />
              <Card.Item
                label="Quantidade de Impedimentos"
                value={getValue(
                  String(data?.quantidadeOcorrenciasImpedimentos)
                )}
              />
              <Card.Item
                label="Descrição da Nacionalidade"
                value={getValue(data?.descricaoNacionalidade)}
              />
              <Card.Item
                label="Serviço Consultado"
                value={getValue(data?.servicoConsultado)}
              />
              <Card.Item
                label="Permissionário"
                value={getValue(data?.permissionario === 1.0 ? "Sim" : "Não")}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Dados Complementares" toggleable>
            <Card.Container>
              <Card.Item
                label="Código RENACH"
                value={getValue(data?.numeroFormularioRenach)}
              />
              <Card.Item label="Número PGU" value={getValue(data?.numeroPgu)} />
              <Card.Item
                label="Motivo do Requerimento 1"
                value={getValue(data?.descricaoMotivoRequerimento1)}
              />
              <Card.Item
                label="Motivo do Requerimento 2"
                value={getValue(data?.descricaoMotivoRequerimento2)}
              />
              <Card.Item
                label="Motivo do Requerimento 3"
                value={getValue(data?.descricaoMotivoRequerimento3)}
              />
              <Card.Item
                label="Motivo do Requerimento 4"
                value={getValue(data?.descricaoMotivoRequerimento4)}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Cursos Complementares" toggleable>
            <Card.Container>
              <Card.Item
                label="Curso TVE"
                value={getValue(data?.descricaoClassificacaoCursoTve)}
              />
              <Card.Item
                label="Curso TMT"
                value={getValue(data?.descricaoClassificacaoCursoTmt)}
              />
              <Card.Item
                label="Curso TCP"
                value={getValue(data?.descricaoClassificacaoCursoTcp)}
              />
              <Card.Item
                label="Curso TCI"
                value={getValue(data?.descricaoClassificacaoCursoTci)}
              />
              <Card.Item
                label="Curso TE"
                value={getValue(data?.descricaoClassificacaoCursoTe)}
              />
              <Card.Item
                label="Curso TMF"
                value={getValue(data?.descricaoClassificacaoCursoTmf)}
              />
            </Card.Container>
          </Panel>

          {/* <Panel header="Ocorrências e Impedimentos" toggleable>
            <Card.Container>
              <Card.Item
                label="Quantidade de Ocorrências de Impedimentos"
                value={getValue(String(data?.quantidadeOcorrenciasImpedimentos))}
              />
              <Card.Item
                label="Ocorrências"
                value={
                  data?.ocorrencias?.length
                    ? data?.ocorrencias
                        .map((occ) => JSON.stringify(occ))
                        .join(", ")
                    : "Sem Ocorrências"
                }
              />
            </Card.Container>
          </Panel> */}
        </>
      )}

      {data?.MENSAGEM && <p>Mensagem: {data?.MENSAGEM}</p>}
    </Panel>
  );
};
