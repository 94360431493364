import { Panel } from "primereact/panel";
import { ILocalizacaoCCFPJ } from "../../../../interface/LocalizacaoCCFPJ";
import { Card } from "../../../../components/Card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const LocalizacaoCCFPJPage = (data: ILocalizacaoCCFPJ) => {
  const getValue = (value?: string): string => value || "Não informado";

  return (
    <Panel
      header="Dados Localização CCF PJ"
      toggleable
      style={{ width: "98%" }}
    >
      <Panel header="Informações da Empresa" toggleable>
        {data?.INFORMACOES_DA_EMPRESA && (
          <Card.Container>
            <Card.Item
              label="Razão Social"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.RAZAO_SOCIAL)}
            />
            <Card.Item
              label="CNAE Principal"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.CNAE_PRIMARIO)}
            />
            <Card.Item
              label="Nome Fantasia"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.NOME_FANTASIA)}
            />
            <Card.Item
              label="Situação"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.SITUACAO)}
            />
            <Card.Item
              label="Fundação"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.DATA_FUNDACAO)}
            />
            <Card.Item
              label="Natureza Jurídica"
              value={getValue(data?.INFORMACOES_DA_EMPRESA?.NATUREZA_JURIDICA)}
            />
          </Card.Container>
        )}

        {!data?.INFORMACOES_DA_EMPRESA && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Informações, Alertas e Restrições" toggleable>
        <DataTable
          value={data?.INFORMACOES_ALERTAS_RESTRICOES?.OCORRENCIAS}
          emptyMessage="Nada consta"
        >
          <Column field="TITULO" header="Título" />
          <Column
            field="CODIGO_TIPO_INFORMACAO"
            header="Código de Informação"
          />
          <Column
            field="DESCRICAO_TIPO_INFORMACAO"
            header="Tipo de Informação"
          />
          <Column field="OBSERVACOES" header="Observações" />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Quadro Societário" toggleable>
        <DataTable
          value={data?.QUADRO_SOCIETARIO?.OCORRENCIAS}
          emptyMessage="Nada consta"
        >
          <Column header="Nome" body={(rowData) => getValue(rowData?.NOME)} />
          <Column
            header="Tipo de Documento"
            body={(rowData) => getValue(rowData?.TIPO_DOCUMENTO)}
          />
          <Column
            header="CPF/CNPJ"
            body={(rowData) => getValue(rowData?.CPF_CNPJ?.maskCpfCnpj())}
          />
          <Column header="Cargo" body={(rowData) => getValue(rowData?.CARGO)} />
          <Column
            header="Data de Entrada na Sociedade"
            body={(rowData) => getValue(rowData?.DATA_ENTRADA_SOCIEDADE)}
          />
          <Column
            header="Percentual de Participação"
            body={(rowData) => getValue(rowData?.PERCENTUAL_PARTICIPACAO)}
          />
          <Column
            header="Autorizado a Assinar pela Empresa"
            body={(rowData) => getValue(rowData?.ASSINA_EMPRESA)}
          />
          <Column
            header="Indicador de Débito"
            body={(rowData) => getValue(rowData?.INDICADOR_DEBITO)}
          />
          <Column
            header="Indicador de Fraude"
            body={(rowData) => getValue(rowData?.INDICADOR_FRAUDE)}
          />
          <Column
            header="Situação do Documento"
            body={(rowData) => getValue(rowData?.SITUACAO_DOCUMENTO)}
          />
        </DataTable>
      </Panel>

      <br />
      <br />

      {/* <Panel header="Veículos por CPF/CNPJ">
      
      </Panel> */}

      <br />
      <br />

      <Panel header="Faturamento Presumido" toggleable>
        {data?.FATURAMENTO_PRESUMIDO && (
          <Card.Container>
            <Card.Item
              label="Faixa"
              value={getValue(data?.FATURAMENTO_PRESUMIDO?.FAIXA)}
            />
            <Card.Item
              label="Observações"
              value={getValue(data?.FATURAMENTO_PRESUMIDO?.MENSAGEM)}
            />
          </Card.Container>
        )}

        {!data?.FATURAMENTO_PRESUMIDO && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="E-mail(s) de Contato" toggleable>
        {data?.EMAILS?.INFOEMAILS?.length === 0 && <p>Nada consta</p>}
        {data?.EMAILS?.INFOEMAILS && (
          <Card.Container>
            {data?.EMAILS?.INFOEMAILS?.map((item) => (
              <Card.Item label="E-mail" value={getValue(item?.ENDERECO)} />
            ))}
          </Card.Container>
        )}
      </Panel>

      <br />
      <br />

      <Panel header="Endereço(s)" toggleable>
        <DataTable
          value={data?.SOMENTE_ENDERECO?.DADOS}
          emptyMessage="Nada consta"
        >
          <Column
            header="CPF/CNPJ"
            body={(rowData) => getValue(rowData?.DOCUMENTO?.maskCpfCnpj())}
          />
          <Column header="UF" body={(rowData) => getValue(rowData?.UF)} />
          <Column
            header="Bairro"
            body={(rowData) => getValue(rowData?.BAIRRO)}
          />
          <Column
            header="Complemento"
            body={(rowData) => getValue(rowData?.COMPLEMENTO)}
          />
          <Column
            header="Número"
            body={(rowData) => getValue(rowData?.NUMERO)}
          />
          <Column
            header="Pontuação"
            body={(rowData) => getValue(rowData?.PONTUACAO)}
          />
          <Column
            header="Endereço"
            body={(rowData) => getValue(rowData?.ENDERECO)}
          />
          <Column
            header="Cidade"
            body={(rowData) => getValue(rowData?.CIDADE)}
          />
          <Column header="CEP" body={(rowData) => getValue(rowData?.CEP)} />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Telefone(s) Celular(es)" toggleable>
        <DataTable
          value={data?.TELEFONE_CELULAR?.TELEFONES}
          emptyMessage="Nada consta"
        >
          <Column header="Nome" body={(rowData) => getValue(rowData?.NOME)} />
          <Column
            header="CPF/CNPJ"
            body={(rowData) => getValue(rowData?.DOCUMENTO?.maskCpfCnpj())}
          />
          <Column
            header="Telefone"
            body={(rowData) =>
              getValue(`(${rowData?.DDD}) ${rowData?.NUM_TELEFONE}`)
            }
          />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Telefone(s) Fixo(s)" toggleable>
        <DataTable
          value={data?.TELEFONE_FIXO?.TELEFONES}
          emptyMessage="Nada consta"
        >
          <Column header="Nome" body={(rowData) => getValue(rowData?.NOME)} />
          <Column
            header="CPF/CNPJ"
            body={(rowData) => getValue(rowData?.DOCUMENTO?.maskCpfCnpj())}
          />
          <Column
            header="Telefone"
            body={(rowData) =>
              getValue(`(${rowData?.DDD}) ${rowData?.NUM_TELEFONE}`)
            }
          />
        </DataTable>
      </Panel>
    </Panel>
  );
};
