import { ScrollTop } from "primereact/scrolltop";
import { useTranslation } from "react-i18next";
import { AlertaStatus } from "../../../components/AlertaStatus";
import Acoes from "./Acoes";
import AcoesCompleta from "./AcoesCompleta";
import Analise from "./Analise";
import Apontamentos from "./Apontamentos";
import { AutocorpLeilao } from "./AutoCorpLeilao";
import AutomotivaAgregado from "./AutomotivaAgregado";
import { AutomotivaAlertaDeIndicio } from "./AutomotivaAlertaDeIndicio";
import { AutomotivaAlertaVeicular } from "./AutomotivaAlertaVeicular";
import { AutomotivaBaseEstadual } from "./AutomotivaBaseEstadual";
import { AutomotivaCertidaoNacionalDeDebitosTrab } from "./AutomotivaCertidaoNacionalDeDebitosTrab";
import { AutomotivaCRLVPage } from "./AutomotivaCRLV";
import { AutomotivaCSVPage } from "./AutomotivaCSV";
import { AutomotivaFipePage } from "./AutomotivaFipe";
import AutomotivaGravame from "./AutomotivaGravame";
import AutomotivaHistoricoProprietario from "./AutomotivaHistoricoProprietario";
import { AutomotivaIndicioSinistro01 } from "./AutomotivaIndicioSinistro01";
import AutomotivaLeilao from "./AutomotivaLeilao";
import { AutomotivaLeilaoLocalPage } from "./AutomotivaLeilaoLocal";
import AutomotivaMotor from "./AutomotivaMotor";
import AutomotivaOnline from "./AutomotivaOnline";
import { AutomotivaPerdaTotal } from "./AutomotivaPerdaTotal/AutomotivaPerdaTotal";
import AutomotivaPrecificador from "./AutomotivaPrecificador";
import AutomotivaRenainf from "./AutomotivaRenainf";
import AutomotivaRenavam from "./AutomotivaRenavam";
import { AutomotivaSinistroPage } from "./AutomotivaSinistro";
import { AutomotivaVeiculoProprietario } from "./AutomotivaVeiculoProprietario";
import { AutoRenainf } from "./AutoRenainf";
import Aviso from "./Aviso";
import { BacenScoreSCRPage } from "./BacenScoreSCR";
import Cadastral from "./Cadastral";
import { CadastralChequeCerto } from "./CadastralChequeCerto";
import { CadastralChequeContumacia } from "./CadastralChequeContumacia";
import { CadastralEmailPJ } from "./CadastralEmailPJ";
import CadastralNomePF from "./CadastralNomePF";
import CadastralPesquisaPJ from "./CadastralPesquisaPJ";
import { CadastralProtestoCenprot } from "./CadastralProtestoCenprot";
import { CadastralTelefonePJ } from "./CadastralTelefonePJ";
import { CadastralPFEmail } from "./CadastraPFEmail";
import CCFS from "./CCFS";
import { CNDT } from "./CNDT";
import CompletaPF from "./CompletaPF";
import CompletaPJ from "./CompletaPJ";
import ComportamentoFinanceiro from "./ComportamentoFinanceiro";
import { ConsultaBaseLeilao } from "./ConsultaBaseLeilao";
import { Credify } from "./Credify";
import { CredifyConsultaCPF } from "./CredifyConsultaCPF";
import { CredifyPfTelefone } from "./CredifyPfTelefone";
import { CredifyPrecificadorFipe } from "./CredifyPrecificadorFipe";
import { CreditoSimples } from "./CreditoSimples";
import DadosConsulta from "./DadosConsulta";
import DadosConsultaPJ from "./DadosConsultaPJ";
import Empresas from "./Empresas";
import EnderecoHist from "./EnderecoHist";
import Endividamento from "./Endividamento";
import FaturamentoPresumido from "./FaturamentoPresumido";

import { Gps391 } from "./Gps391";
import HistoricoPagamento from "./HistoricoPagamento";
import HistoricoInfo from "./HistorioInfo";
import IndicadorNegocio from "./IndicadorNegocio";
import IndicadorPJ from "./IndicadorPJ";
import { KarfexVeiculo } from "./KarfexVeiculo";
import LimiteCreditoPJ from "./LimiteCreditoPJ";
import LinhaCredito from "./LinhaCredito";
import ListaConsulta from "./ListaConsulta";
import MaisNegocio from "./MaisNegocio";
import Negativacoes from "./Negativacoes";
import ParticipacaoEmpresa from "./ParticipacaoEmpresa";
import PendenciaCompany from "./PedenciaCompany";
import Pendencias from "./Pendencias";
import PontualidadePagamento from "./PontualidadePagamento";
import { ProdutoDocumentoCNH } from "./ProdutoDocumentoCNH";
import { ProprietarioTelefoneOperadora } from "./ProprietarioTelefoneOperadora";
import ProtestoQuod from "./ProtestoQuod";
import Protestos from "./Protestos";
import AutomotivaRenajud from "./Renajud";
import RendaPresumida from "./RendaPresumida";
import { HistoricoRouboFurto } from "./HistoricoRouboFurto";
import Score from "./Score";
import ScoreGrauAtvidadePJ from "./ScoreGrauAtividadePJ";
import Socios from "./Socios";
import CRLVSimples from "./CRLVSimles";
import { AutomotivaFichaTecnica } from "../resposta/AutomotivaFichaTecnica";
import { CadastralLocalizacaoSimples } from "../resposta/CadastralLocalizacaoSimples";
import { AutomotivaPrecificacaoPage } from "./AutomotivaPrecificacao";
import { CnhRetrato } from "./CnhRetrato";
import { SenatranVeicular } from "./SenatranVeicular";
import { ScoreTurboPage } from "./ScoreTurbo";
import { CodigoCnh } from "./CodigoCnh";
import { AutomotivaRecall } from "./AutomotivaRecall";
import { ScoreVeicularPage } from "./ScoreVeicular";
import { Gps353 } from "./Gps353";
import { NexoImagensLeilaoPage } from "./NexoImagensLeilao";
import { LocalizacaoCCFPF } from "./LocalizacaoCCFPF";
import { AutomotivaProprietarioAtual } from "./AutomotivaProprietarioAtual";
import { AutomotivaConsultaAgregadaPage } from "./AutomotivaConsultaAgregada";
import { PlacaPdfCrvNexoPage } from "./PlacaPdfCrvNexo";
import { AutomotivaProprietarioVeicularPage } from "./AutomotivaProprietarioVeicular";
import { SenatranCondutorPage } from "./SenatranCondutor";
import { ConsultaRenajudPage } from "./ConsultaRenajud";
import { AutomotivaCsvCompletoPage } from "./AutomotivaCsvCompleto";
import { LocalizacaoCCFPJPage } from "./LocalizacaoCCFPJ";

interface RespostaInsumoPageProps {
  resposta: any;
}

export default function RespostaInsumoPage({
  resposta,
}: RespostaInsumoPageProps) {
  const { t } = useTranslation();

  return (
    <>
      {resposta.avisos.length > 0 && <Aviso avisos={resposta.avisos}></Aviso>}
      <br></br>
      {resposta?.resumosAutomotiva && (
        <AlertaStatus
          date={resposta.informativo.data}
          data={resposta?.resumosAutomotiva}
        />
      )}

      {resposta.automotivaOnline && (
        <>
          <br></br>
          <AutomotivaOnline
            automotivaOnline={resposta.automotivaOnline}
          ></AutomotivaOnline>
        </>
      )}

      {resposta.automotivaBaseEstadual && (
        <>
          <br></br>
          <AutomotivaBaseEstadual data={resposta.automotivaBaseEstadual} />
        </>
      )}

      {resposta.automotivaAgregado && (
        <>
          <br></br>
          <AutomotivaAgregado
            automotivaAgregado={resposta.automotivaAgregado}
          />
        </>
      )}

      {resposta.automotivaHistoricoProprietario && (
        <>
          <br></br>
          <AutomotivaHistoricoProprietario
            automotivaHistoricoProprietario={
              resposta.automotivaHistoricoProprietario
            }
          ></AutomotivaHistoricoProprietario>
        </>
      )}

      {resposta.automotivaMotor && (
        <>
          <br></br>
          <AutomotivaMotor
            automotivaMotor={resposta.automotivaMotor}
          ></AutomotivaMotor>
        </>
      )}

      {resposta.automotivaBaseRenavam && (
        <>
          <br></br>
          <AutomotivaRenavam
            automotivaRenavam={resposta.automotivaBaseRenavam}
          ></AutomotivaRenavam>
        </>
      )}

      {resposta.autoVeiculoProprietario && (
        <>
          {" "}
          <br></br>
          <AutomotivaVeiculoProprietario
            {...resposta.autoVeiculoProprietario}
          />
        </>
      )}

      {resposta.cadastralDocumentoCNH && (
        <>
          {" "}
          <br></br>
          <ProdutoDocumentoCNH {...resposta.cadastralDocumentoCNH} />
        </>
      )}

      {resposta.automotivaFichaTecnica && (
        <>
          <br></br>
          <AutomotivaFichaTecnica data={resposta.automotivaFichaTecnica} />
        </>
      )}

      {resposta.cadastralLocalizacaoSimples && (
        <>
          <br></br>
          <CadastralLocalizacaoSimples
            data={resposta.cadastralLocalizacaoSimples}
          />
        </>
      )}

      {resposta.cnhRetrato && (
        <>
          <br></br>
          <CnhRetrato data={resposta.cnhRetrato} />
        </>
      )}

      {resposta.senatranVeicular && (
        <>
          <br></br>
          <SenatranVeicular data={resposta.senatranVeicular} />
        </>
      )}

      {resposta.codigoCnh && (
        <>
          <br></br>
          <CodigoCnh data={resposta.codigoCnh} />
        </>
      )}

      {resposta.automotivaRecall && (
        <>
          <br></br>
          <AutomotivaRecall data={resposta.automotivaRecall} />
        </>
      )}

      {resposta.placaFipe && (
        <>
          {" "}
          <br></br>
          <AutomotivaFipePage {...resposta.placaFipe} />
        </>
      )}

      {resposta.automotivaPrecificadorFipe && (
        <>
          <br></br>
          <CredifyPrecificadorFipe data={resposta.automotivaPrecificadorFipe} />
        </>
      )}

      {resposta.automotivaGravameSimples && (
        <>
          <br></br>
          <AutomotivaGravame
            automotivaGravame={resposta.automotivaGravameSimples}
          ></AutomotivaGravame>
        </>
      )}

      {resposta.automotivaPrecificador && (
        <>
          <br></br>
          <AutomotivaPrecificador
            automotivaPrecificador={resposta.automotivaPrecificador}
          ></AutomotivaPrecificador>
        </>
      )}

      {resposta?.crlvSimples && (
        <>
          <br></br>
          <CRLVSimples crlvSimples={resposta?.crlvSimples} />
        </>
      )}

      {resposta.autoCrlv && (
        <>
          {" "}
          <br></br>
          <AutomotivaCRLVPage {...resposta.autoCrlv} />
        </>
      )}

      {resposta.automotivaRenainf && (
        <>
          <br></br>
          <AutomotivaRenainf {...resposta.automotivaRenainf} />
        </>
      )}

      {resposta.autoRenainf && (
        <>
          <br></br>
          <AutoRenainf data={resposta.autoRenainf} />
        </>
      )}

      {resposta.automotivaRenajud && (
        <>
          <br></br>
          <AutomotivaRenajud
            automotivaRenajud={resposta.automotivaRenajud}
          ></AutomotivaRenajud>
        </>
      )}

      {resposta.automotivaAlertaDeIndicio && (
        <>
          <br></br>
          <AutomotivaAlertaDeIndicio
            data={resposta.automotivaAlertaDeIndicio}
          />
        </>
      )}

      {resposta.baseLeilao && (
        <>
          {" "}
          <br></br>
          <ConsultaBaseLeilao {...resposta.baseLeilao} />
        </>
      )}

      {resposta.automotivaLeilaoLocal && (
        <>
          {" "}
          <br></br>
          <AutomotivaLeilaoLocalPage {...resposta.automotivaLeilaoLocal} />
        </>
      )}

      {resposta.automotivaLeilao && (
        <>
          <br></br>
          <AutomotivaLeilao
            automotivaLeilao={resposta.automotivaLeilao}
          ></AutomotivaLeilao>
        </>
      )}

      {resposta.leilao006 && (
        <>
          {" "}
          <br></br>
          <KarfexVeiculo {...resposta.leilao006} />
        </>
      )}

      {resposta.leilao_007 && (
        <>
          <br></br>
          <AutocorpLeilao data={resposta.leilao_007} />
        </>
      )}

      {resposta.automotivaPerdaTotal && (
        <>
          {" "}
          <br></br>
          <AutomotivaPerdaTotal data={resposta.automotivaPerdaTotal} />
        </>
      )}

      {resposta.historicoRouboFurto && (
        <>
          {" "}
          <br></br>
          <HistoricoRouboFurto {...resposta.historicoRouboFurto} />
        </>
      )}

      {resposta.automotivaIndicioDeSinistro01 && (
        <>
          <br></br>
          <AutomotivaIndicioSinistro01
            data={resposta.automotivaIndicioDeSinistro01}
          />
        </>
      )}

      {resposta.sinistroLocal && (
        <>
          {" "}
          <br></br>
          <AutomotivaSinistroPage {...resposta.sinistroLocal} />
        </>
      )}

      {resposta.alertaVeicular && (
        <>
          {" "}
          <br></br>
          <AutomotivaAlertaVeicular {...resposta.alertaVeicular} />
        </>
      )}

      {resposta.autoCsv && (
        <>
          {" "}
          <br></br>
          <AutomotivaCSVPage {...resposta.autoCsv} />
        </>
      )}

      {resposta.dadosConsulta && (
        <DadosConsulta dadosConsulta={resposta.dadosConsulta}></DadosConsulta>
      )}
      {resposta.dadosConsultaPJ && (
        <DadosConsultaPJ
          capitalSocial={resposta.capitalSocial}
          dadosConsultaPJ={resposta.dadosConsultaPJ}
        ></DadosConsultaPJ>
      )}
      {resposta.pessoa && (
        <>
          {" "}
          <br></br>
          <Cadastral pessoa={resposta.pessoa}></Cadastral>
        </>
      )}
      {resposta.completaPF && (
        <CompletaPF completaPF={resposta.completaPF}></CompletaPF>
      )}
      {resposta.completaPJ && (
        <CompletaPJ completaPJ={resposta.completaPJ}></CompletaPJ>
      )}
      {resposta.cadastralPesquisaPJ && (
        <CadastralPesquisaPJ
          cadastralPesquisaPJ={resposta.cadastralPesquisaPJ}
        ></CadastralPesquisaPJ>
      )}
      {resposta.score && (
        <>
          <br></br>
          <Score
            scoreBoleto={false}
            label="Score"
            score={resposta.score}
          ></Score>
        </>
      )}
      {resposta.scoreBoletoPJ && (
        <>
          <br></br>
          <Score
            scoreBoleto={true}
            label="Score Boleto PJ"
            score={resposta.scoreBoletoPJ}
          ></Score>
        </>
      )}
      {resposta.acoes && resposta.acoes.length > 0 && (
        <>
          <br></br>
          <Acoes acoes={resposta.acoes}></Acoes>
        </>
      )}
      {resposta.acoesCompleta && resposta.acoesCompleta.length > 0 && (
        <>
          <br></br>
          <AcoesCompleta acoes={resposta.acoesCompleta}></AcoesCompleta>
        </>
      )}
      {resposta.showProtesto && (
        <>
          <br></br>
          <Protestos cartorios={resposta.cartorios}></Protestos>
        </>
      )}
      {resposta.showApontamento && (
        <>
          <br></br>
          <Apontamentos apontamentos={resposta.apontamentos}></Apontamentos>
        </>
      )}
      {resposta.protestosQuod && resposta.protestosQuod.length > 0 && (
        <>
          <br></br>
          <ProtestoQuod protestos={resposta.protestosQuod}></ProtestoQuod>
        </>
      )}
      {resposta.pendencias && resposta.pendencias.length > 0 && (
        <>
          <br></br>
          <Pendencias pendencias={resposta.pendencias}></Pendencias>
        </>
      )}
      {resposta.showPendencia > 0 && (
        <>
          <br></br>
          <Negativacoes negativacoes={resposta.negativacoes}></Negativacoes>
        </>
      )}
      {resposta.creditoSimples && (
        <CreditoSimples data={resposta.creditoSimples} />
      )}

      {resposta.cadastralChequeCerto && (
        <CadastralChequeCerto data={resposta.cadastralChequeCerto} />
      )}

      {resposta.cndt && <CNDT data={resposta.cndt} />}

      {resposta.certidaoNacionalDeDebitosTrab && (
        <AutomotivaCertidaoNacionalDeDebitosTrab
          data={resposta.certidaoNacionalDeDebitosTrab}
        />
      )}

      {resposta.cadastralProtestoCenprot && (
        <CadastralProtestoCenprot data={resposta.cadastralProtestoCenprot} />
      )}
      {resposta.cadastralChequeContumacia && (
        <CadastralChequeContumacia data={resposta.cadastralChequeContumacia} />
      )}

      {resposta.ccfs && resposta.ccfs.length > 0 && (
        <>
          <br></br>
          <CCFS ccfs={resposta.ccfs}></CCFS>
        </>
      )}
      {resposta.rendaPresumida && (
        <>
          <br></br>
          <RendaPresumida
            rendaPresumida={resposta.rendaPresumida}
          ></RendaPresumida>
        </>
      )}
      {resposta.scoreGrauAtividadePJ && (
        <>
          <br></br>
          <ScoreGrauAtvidadePJ
            scoreGrauAtividadePJ={resposta.scoreGrauAtividadePJ}
          ></ScoreGrauAtvidadePJ>
        </>
      )}
      {resposta.comportamentoFinanceiro && (
        <>
          <br></br>
          <ComportamentoFinanceiro
            comportamento={resposta.comportamentoFinanceiro}
          ></ComportamentoFinanceiro>
        </>
      )}
      {resposta.linhaCredito && (
        <>
          <br></br>
          <LinhaCredito linhaCredito={resposta.linhaCredito}></LinhaCredito>
        </>
      )}
      {resposta.endividamentos && resposta.endividamentos.length > 0 && (
        <>
          <br></br>
          <Endividamento
            endividamentos={resposta.endividamentos}
          ></Endividamento>
        </>
      )}
      {resposta.pontualidadePagamento && (
        <>
          <br></br>
          <PontualidadePagamento
            pontualidadePagamento={resposta.pontualidadePagamento}
          ></PontualidadePagamento>
        </>
      )}
      {resposta.historicoPagamento && (
        <>
          <br></br>
          <HistoricoPagamento
            historicoPagamento={resposta.historicoPagamento}
          ></HistoricoPagamento>
        </>
      )}
      {resposta.limiteCreditoPJ && (
        <>
          <br></br>
          <LimiteCreditoPJ
            limiteCreditoPJ={resposta.limiteCreditoPJ}
          ></LimiteCreditoPJ>
        </>
      )}
      {resposta.maisNegocio && (
        <>
          <br></br>
          <MaisNegocio maisNegocio={resposta.maisNegocio}></MaisNegocio>
        </>
      )}
      {resposta.faturamentosPresumido && (
        <>
          <br></br>
          <FaturamentoPresumido
            faturamentos={resposta.faturamentosPresumido}
          ></FaturamentoPresumido>
        </>
      )}
      {resposta.consulta && (
        <>
          <br></br>
          <ListaConsulta consulta={resposta.consulta}></ListaConsulta>
        </>
      )}

      {resposta.cadastralNomePF && (
        <>
          <br></br>
          <CadastralNomePF
            cadastralNomePF={resposta.cadastralNomePF}
          ></CadastralNomePF>
        </>
      )}
      {resposta.empresas && resposta.empresas.length > 0 && (
        <>
          <br></br>
          <Empresas empresas={resposta.empresas}></Empresas>
        </>
      )}
      {resposta.socios && resposta.socios.length > 0 && (
        <>
          <br></br>
          <Socios socios={resposta.socios}></Socios>
        </>
      )}
      {resposta.participacoesEmpresa &&
        resposta.participacoesEmpresa.length > 0 && (
          <>
            <br></br>
            <ParticipacaoEmpresa
              participacoes={resposta.participacoesEmpresa}
            ></ParticipacaoEmpresa>
          </>
        )}
      {resposta.enderecosHist && resposta.enderecosHist.length > 0 && (
        <>
          <br></br>
          <EnderecoHist enderecos={resposta.enderecosHist}></EnderecoHist>
        </>
      )}
      {resposta.emailsHist && resposta.emailsHist.length > 0 && (
        <>
          <br></br>
          <HistoricoInfo
            showHist={true}
            label={t("email")}
            historicos={resposta.emailsHist}
          ></HistoricoInfo>
        </>
      )}
      {resposta.conglomerados && resposta.conglomerados.length > 0 && (
        <>
          <br></br>
          <HistoricoInfo
            showHist={false}
            label={t("conglomerados")}
            historicos={resposta.conglomerados}
          ></HistoricoInfo>
        </>
      )}
      {resposta.calularesHist && resposta.calularesHist.length > 0 && (
        <>
          <br></br>
          <HistoricoInfo
            showHist={true}
            label={t("celular")}
            historicos={resposta.calularesHist}
          ></HistoricoInfo>
        </>
      )}
      {resposta.telefonesHist && resposta.telefonesHist.length > 0 && (
        <>
          <br></br>
          <HistoricoInfo
            showHist={true}
            label={t("fone")}
            historicos={resposta.telefonesHist}
          ></HistoricoInfo>
        </>
      )}
      {resposta.analises && resposta.analises.length > 0 && (
        <>
          <br></br>
          <Analise analises={resposta.analises}></Analise>
        </>
      )}
      {resposta.indicadoresPJ && resposta.indicadoresPJ.length > 0 && (
        <>
          <br></br>
          <IndicadorPJ indicadores={resposta.indicadoresPJ}></IndicadorPJ>{" "}
        </>
      )}
      {resposta.indicadores && resposta.indicadores.length > 0 && (
        <>
          <br></br>
          <IndicadorNegocio
            indicadores={resposta.indicadores}
          ></IndicadorNegocio>{" "}
        </>
      )}
      {resposta.cpfCnpjTotalizadorProcessos && (
        <Credify data={resposta.cpfCnpjTotalizadorProcessos} />
      )}

      {resposta.gps353 && <Gps353 {...resposta.gps353} />}
      {resposta.gps391 && <Gps391 data={resposta.gps391} />}

      {resposta.cadastralTelefonePF && (
        <>
          {" "}
          <br></br>
          <CredifyPfTelefone data={resposta.cadastralTelefonePF} />
        </>
      )}
      {resposta.cadastralPesquisaPF && (
        <>
          {" "}
          <br></br>
          <CredifyConsultaCPF data={resposta.cadastralPesquisaPF} />
        </>
      )}
      {resposta.cadastralProprietarioTelefoneOperadora && (
        <>
          {" "}
          <br></br>
          <ProprietarioTelefoneOperadora
            data={resposta.cadastralProprietarioTelefoneOperadora}
          />
        </>
      )}
      {resposta.cadastralTelefonePJ && (
        <>
          {" "}
          <br></br>
          <CadastralTelefonePJ data={resposta.cadastralTelefonePJ} />
        </>
      )}
      {resposta.cadastralEmailPF && (
        <>
          {" "}
          <br></br>
          <CadastralPFEmail data={resposta.cadastralEmailPF} />
        </>
      )}
      {resposta.cadastralEmailPJ && (
        <>
          {" "}
          <br></br>
          <CadastralEmailPJ data={resposta.cadastralEmailPJ} />
        </>
      )}

      {resposta.pendenciaCompany && (
        <>
          <br></br>
          <PendenciaCompany
            pendenciaCompany={resposta.pendenciaCompany}
          ></PendenciaCompany>
        </>
      )}

      {resposta.cadastralSrcBacenScorePositivo && (
        <>
          {" "}
          <br></br>
          <BacenScoreSCRPage {...resposta.cadastralSrcBacenScorePositivo} />
        </>
      )}

      {resposta.automotivaConsultaPrecificacao && (
        <>
          {" "}
          <br></br>
          <AutomotivaPrecificacaoPage
            {...resposta.automotivaConsultaPrecificacao}
          />
        </>
      )}

      {resposta.scoreTurbo && (
        <>
          <ScoreTurboPage {...resposta.scoreTurbo} />
        </>
      )}

      {resposta.automotivaLeilaoBase01ScoreVeicular && (
        <>
          {" "}
          <br></br>
          <ScoreVeicularPage
            {...resposta.automotivaLeilaoBase01ScoreVeicular}
          />
        </>
      )}

      {resposta.imagensLeilao && (
        <>
          {" "}
          <br></br>
          <NexoImagensLeilaoPage {...resposta.imagensLeilao} />
        </>
      )}

      {resposta.cadastralLocalizacaoCcfPf && (
        <>
          {" "}
          <br></br>
          <LocalizacaoCCFPF {...resposta.cadastralLocalizacaoCcfPf} />
        </>
      )}

      {resposta.autoProprietarioAtual && (
        <>
          {" "}
          <br></br>
          <AutomotivaProprietarioAtual {...resposta.autoProprietarioAtual} />
        </>
      )}

      {resposta.consultaAgregado && (
        <>
          {" "}
          <br></br>
          <AutomotivaConsultaAgregadaPage {...resposta.consultaAgregado} />
        </>
      )}

      {resposta.placaCrv && (
        <>
          {" "}
          <br></br>
          <PlacaPdfCrvNexoPage {...resposta.placaCrv} />
        </>
      )}

      {resposta.proprietarioVeicular && (
        <>
          {" "}
          <br></br>
          <AutomotivaProprietarioVeicularPage
            {...resposta.proprietarioVeicular}
          />
        </>
      )}


      {resposta.senatranCondutor && (
        <>
          {" "}
          <br></br>
          <SenatranCondutorPage {...resposta.senatranCondutor} />
        </>
      )}

      {resposta.autoRenajudApi && (
        <>
          {" "}
          <br></br>
          <ConsultaRenajudPage {...resposta.autoRenajudApi} />
          </>
      )}

      {resposta.csvCompleto && (
        <>
          {" "}
          <br></br>
          <AutomotivaCsvCompletoPage {...resposta.csvCompleto} />
          </>
      )}

      {resposta.cadastralLocalizacaoCcfPj && (
        <>
          {" "}
          <br></br>
          <LocalizacaoCCFPJPage {...resposta.cadastralLocalizacaoCcfPj} />
        </>
      )}

      <ScrollTop
        style={{ marginBottom: "5rem" }}
        className="w-2rem h-2rem border-round bg-primary"
        icon="pi pi-arrow-up text-base"
      />
      <br></br>
    </>
  );
}
