import { Panel } from "primereact/panel";
import { IScoreTurbo } from "../../../../interface/ScoreTurbo";
import { ScoreCustom } from "../../../../components/ScoreCustom";

import "./styles.css";
import { TextField } from "../../../../components/TextField";
import { Divider } from "primereact/divider";

export const ScoreTurboPage = (data: IScoreTurbo) => {
  const { CONSULTA, SCORE } = data?.consulta?.resposta?.resultado;
  const PF = CONSULTA?.dados?.informacoes;
  const PJ = CONSULTA?.dados_empresa?.informacoes;
  return (
    <>
      <Panel className="panel-header" header="Dados Score Turbo" toggleable>
        <div className="score-container">
          <div className="score-content">
            <ScoreCustom score={SCORE?.valor} />
          </div>

          <div className="score-info">
            <p style={{ textAlign: "center", color: "gray" }}>
              SCORE <b>{SCORE?.valor}</b> DE 1000
            </p>
            <Divider />
            <h3 style={{ margin: "0", color: "orange" }}>
              PROBABILIDADE DE INADIMPLÊNCIA ={" "}
              {SCORE?.probabilidadeInadimplencia
                ? ` ${SCORE?.probabilidadeInadimplencia}%`
                : "VALOR NÃO INFORMADO"}
            </h3>
            <Divider />
            <h3 style={{ margin: "0" }}>
              A CHANCE DE UM CONSUMIDOR, COM SCORE ENTRE 601 E 700, PAGAR SEUS
              COMPROMISSOS FINANCEIROS EM 06 MESES É DE ={" "}
              {SCORE?.capacidadePagamento
                ? `${SCORE?.capacidadePagamento}%`
                : "VALOR NÃO INFORMADO"}
            </h3>
          </div>
        </div>

        <br />

        <p>
          <b>IMPORTANTE:</b>{" "}
          <i>
            a decisão de aprovação ou não do crédito é de exclusiva
            responsabilidade do concedente. as informações prestadas pelo
            provedor tem como objevo subsidiar essas decisões e, em hipótese
            alguma devem ser ulizadas como juscava, pelo concedente do crédito,
            para a tomada da referida decisão.
          </i>
        </p>
      </Panel>

      <br />
      <br />

      <Panel
        className="panel-header"
        header="INFORMAÇÕES, ALERTAS OU RESTRIÇÕES"
        toggleable
      >
        {PF && (
          <div className="listaWrapper">
            <TextField label="Nome Completo" value={PF?.nome} />
            <TextField label="CPF" value={PF?.cpf} />
            <TextField label="Identidade" value={PF?.identidade} />
            <TextField label="Data de Nascimento" value={PF?.nascimento} />
            <TextField label="Idade" value={PF?.idade} />
            <TextField label="Signo" value={PF?.signo} />
            <TextField label="Nome da Mãe" value={PF?.mae} />
            <TextField label="Nome do Pai" value={PF?.pai} />
            <TextField label="Filiação" value={PF?.filiacao} />
            <TextField label="Escolaridade" value={PF?.escolaridade} />
            <TextField label="Restrições" value={PF?.restricao} />
            <TextField label="Status" value={PF?.status} />
          </div>
        )}

        {PJ && (
          <div className="listaWrapper">
            <TextField label="Razão Social" value={PJ?.razaosocial} />
            <TextField
              label="Nome Fantasia"
              value={PJ?.nome_fantasia || "Não informado"}
            />
            <TextField label="CNPJ" value={PJ?.cnpj} />
            <TextField
              label="Natureza Jurídica"
              value={`${PJ?.descnatureza} (${PJ?.natureza})`}
            />
            <TextField
              label="Porte da Empresa"
              value={
                PJ?.porte_empresa === "01"
                  ? "Microempresa (ME)"
                  : PJ?.porte_empresa
              }
            />
            <TextField label="Data de Abertura" value={PJ?.abertura} />
            <TextField label="Situação" value={PJ?.situacao} />
            <TextField
              label="Capital Social"
              value={`R$ ${Number(PJ?.capitalsocial).toLocaleString()}`}
            />
            <TextField
              label="Ramo de Atividade"
              value={`${PJ?.descramoatividade} (${PJ?.ramoatividade})`}
            />
            <TextField
              label="CNAE Secundário"
              value={PJ?.lista_cnae_secundarios.descricao}
            />
            <TextField label="Tipo (Matriz/Filial)" value={PJ?.matrizfilial} />
            <TextField
              label="Endereço"
              value={`${PJ?.endereco}, ${PJ?.numero}`}
            />
            <TextField
              label="Complemento"
              value={PJ?.complemento || "Não informado"}
            />
            <TextField label="Bairro" value={PJ?.bairro} />
            <TextField label="CEP" value={PJ?.cep} />
            <TextField label="Estado (UF)" value={PJ?.uf} />
            <TextField label="E-mail" value={PJ?.email || "Não informado"} />
          </div>
        )}
      </Panel>
    </>
  );
};
