import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";
import Container from "../../components/menu/Container";
import { FilterWeb } from "../../interface/FilterWeb";
import getCache from "../../service/getCache";
import getUserCheck from "../../service/getUserCheck";
import postConsultaWebService from "../../service/postConsultaWeb/postConsultaWebService";
import "./Consulta.css";
import FiltroAutomotiva from "./filtro/FiltroAutomotiva";
import FiltroPF from "./filtro/FiltroPF";
import FiltroPJ from "./filtro/FiltroPJ";
import RespostaPage from "./Resposta";
import FiltroClassificacao from "./filtro/FiltroClassificacao";

export default function ConsultaPage() {
  const { state } = useGlobalState();
  const { t } = useTranslation();
  const [resposta, setResposta] = useState<any | null>(null);
  const location = useLocation();
  const [consultaCredito, setConsultaCredito] = useState(
    t("consultasCadastrais")
  );
  const navigate = useNavigate();
  const initialized = useRef(false);
  const msgs = useRef<Messages>(null);

  const handleCloseFilter = () => {
    
    navigate("/home");
  };

  const validIndicadores = (filterWeb: FilterWeb): boolean => {
    let retorno = true;
    if (filterWeb.indicadores.length != 0) {
      filterWeb.indicadores.map((item) => {
        if (item.target.length === 0) {
          retorno = false;
        }
      });
    }
    return retorno;
  };

  const handleProcessFilter = (filterWeb: FilterWeb) => {
    msgs.current?.clear();
    if (
      filterWeb.consultas.length > 0 &&
      (filterWeb.consultas.includes("QUODVERIFIQPJ_MAISNEGOCIO") ||
        filterWeb.consultas.includes("QUODMAISNEGOCIOPJ")) &&
      (!filterWeb.valor ||
        filterWeb.valor < 1 ||
        (!filterWeb.arrojado && !filterWeb.usual && !filterWeb.conservador))
    ) {
      window.scrollTo(0, 0);
      msgs.current?.clear();
      msgs.current?.show([
        {
          sticky: false,
          life: 100000,
          severity: "error",
          summary: "Erro",
          detail: "O valor e o perfil de risco são obrigatórios",
          closable: false,
        },
      ]);
    } else {
      if (!validIndicadores(filterWeb)) {
        window.scrollTo(0, 0);
        msgs.current?.clear();
        msgs.current?.show([
          {
            sticky: false,
            life: 100000,
            severity: "error",
            summary: "Erro",
            detail: "Indicadores não Selecionados",
            closable: false,
          },
        ]);
      } else {
        if (
          (selectedTipoConsulta === "auto" || filterWeb.documento) &&
          filterWeb.consultas &&
          filterWeb.consultas.length > 0
        ) {
          if (filterWeb.indicadores) {
            const indicadores: any[] = [];
            filterWeb.indicadores.map((item: any) => {
              item.target.map((i: any) => indicadores.push(i.value));
            });
            filterWeb.indicadores = indicadores;
          }
          const findLabel = (consultas: Array<any>, value: string): string => {
            for (const consulta of consultas) {
              if (consulta.value === value) {
                return consulta.label;
              }
              if (consulta.permissoes && consulta.permissoes.length > 0) {
                const foundLabel = findLabel(consulta.permissoes, value);
                if (foundLabel !== value) {
                  return foundLabel;
                }
              }
            }
            return value;
          };
          const consultasLabels = filterWeb.consultas.map((consultaKey) =>
            findLabel(state.user.consultas, consultaKey)
          );
          setConsultaCredito(
            `${t("consultasCadastrais")} - ${consultasLabels.join(", ")}`
          );
          postConsultaWebService(filterWeb)
            .then((data: any) => {
              if (data.resumosAutomotiva) {
                window.scrollTo(0, 450);
              } else {
                window.scrollTo(0, 0);
              }
              setResposta(data);
              setselectedTipoConsulta("pf");
            })
            .catch((error) => {
              window.scrollTo(0, 0);
              msgs.current?.clear();
              msgs.current?.show([
                {
                  sticky: false,
                  life: 100000,
                  severity: "error",
                  summary: "Erro",
                  detail: "Erro ao realizar consulta",
                  closable: false,
                },
              ]);
            });
        } else {
          window.scrollTo(0, 0);
          msgs.current?.clear();
          msgs.current?.show([
            {
              sticky: false,
              life: 100000,
              severity: "error",
              summary: "Erro",
              detail:
                (selectedTipoConsulta === "auto" ? "" : "Documento e") +
                " opção de consulta são obrigatórios",
              closable: false,
            },
          ]);
        }
      }
    }
  };
  const [selectedTipoConsulta, setselectedTipoConsulta] = useState("pf");
  const tiposConsulta = [
    { name: "Selecione", code: null },
    { name: "Consulta Pessoa Física", code: "pf" },
    { name: "Consulta Pessoa Jurídica", code: "pj" },
    { name: "Consulta Automotiva", code: "auto" },
  ];

  const findCacheById = (id: string) => {
    if (id && !id.includes("consultar")) {
      getCache(id).then((data: any) => {
        if (data.resumosAutomotiva) {
          window.scrollTo(0, 450);
        } else {
          window.scrollTo(0, 0);
        }

        setResposta(data);
      });
    }
  };

  useEffect(() => {
    if(location.state?.selectedTipoConsulta){
     setselectedTipoConsulta(location.state.selectedTipoConsulta)
    }
    getUserCheck().then((response) => console.info(response));
    if (!initialized.current) {
      initialized.current = true;
      const resp = localStorage.getItem("resposta");
      if (resp) {
        localStorage.removeItem("resposta");
        setResposta(JSON.parse(resp));
      }
      findCacheById(
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ]
      );
    }
  }, [location]);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-search" style={{ fontSize: '2.0rem' }}></i> {consultaCredito}</h1>
            </div>     
          </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3" style={{ width: "60%" }}>
          <Messages ref={msgs} />
          {(selectedTipoConsulta === "CADASTRAL" || selectedTipoConsulta === "CREDITO") && resposta == null && (
            <FiltroClassificacao
              msgs={msgs}
              classificacao={selectedTipoConsulta}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroClassificacao>
          )}
          {selectedTipoConsulta === "pf" && resposta == null && (
            <FiltroPF
              msgs={msgs}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroPF>
          )}

          {selectedTipoConsulta === "pj" && resposta == null && (
            <FiltroPJ
              msgs={msgs}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroPJ>
          )}

          {selectedTipoConsulta === "auto" && resposta == null && (
            <FiltroAutomotiva
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroAutomotiva>
          )}
        </div>
      </div>

      <div>
        {resposta != null && (
          <RespostaPage resposta={resposta} onClose={handleCloseFilter} />
        )}
      </div>
    </Container>
  );
}
