import { Card } from "primereact/card";
import CarImage from "../assets/new-logo-car.png";

export const BannerCustom = (resposta: any) => {
  return (
    <Card style={{ width: "98%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "40px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <img src={CarImage} style={{ width: "250px" }} alt="Logo" />
        </div>

        <div
          style={{ textAlign: "center" }}
          className="col p-fluid flex flex-wrap"
        >
          <h1>{resposta.informativo.opcoesPai.join(" / ")}</h1>
          <h5>{resposta.informativo.opcoes.join(" / ")}</h5>
        </div>

        <div style={{ width: "250px" }} />
      </div>
    </Card>
  );
};
