import { http } from '../../config/axios'

export default function putVeiculoTransferencia(veiculoTransferencia: any): Promise<string> {
    return http
        .put('api/veiculotransferencia', veiculoTransferencia)
        .then((res) => {
            return res.data as string
        })
        .catch((error) => {
            console.error('Erro put veiculo transferencia', error)
            throw Error(error.response.data)
        })
}
