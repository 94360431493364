import { ScrollTop } from "primereact/scrolltop";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { BannerCustom } from "../../components/BannerCustom";
import { FooterCustom } from "../../components/FooterCustom";
import { PrintCustom } from "../../components/PrintCustom";
import { ToolbarCustom } from "../../components/ToolbarCustom";
import Informativo from "./resposta/informativo/Informativo";

import RespostaInsumoPage from "./resposta/RespostaInsumo";

interface RespostaPageProps {
  resposta: any;
  onClose: () => void;
}

export default function RespostaPage({ resposta, onClose }: RespostaPageProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({ content: () => contentRef.current });

  return (
    <>
      <ToolbarCustom onPrint={handlePrint} onClose={onClose} />
      <br></br>
      <br></br>
      <PrintCustom ref={contentRef}>
        <BannerCustom {...resposta} />
        <br></br>
        <br></br>
        <Informativo informative={resposta.informativo} />
        <RespostaInsumoPage resposta={resposta} />

        <ScrollTop
          style={{ marginBottom: "5rem" }}
          className="w-2rem h-2rem border-round bg-primary"
          icon="pi pi-arrow-up text-base"
        />
        <br></br>
        <FooterCustom />
      </PrintCustom>
      <br></br>
      <br></br>
      <ToolbarCustom onPrint={handlePrint} onClose={onClose} />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
