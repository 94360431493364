
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";





export default function AutomotivaLeilao({
  automotivaLeilao
}: {
  automotivaLeilao: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('Automotiva Leilão')} style={{width:'98%'}}>
       <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span className="p-tag" style={{position: 'relative', backgroundColor : '#183462', fontSize:'1.1rem', height: '2rem', marginTop: '0.5rem'}}>Resumo do Veículo</span> 
        </div>
       </Divider>
       <br></br>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('motor')}</label>
                 <Chip style={{width:"100%"}} label={automotivaLeilao.RETORNO.NRMOTOR} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('segmento')}</label>
                 <Chip style={{width:"100%"}} label={automotivaLeilao.RETORNO.SEGMENTO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('subSegmento')}</label>
              <Chip style={{width:"100%"}} label={automotivaLeilao.RETORNO.SUBSEGMENTO} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('carroceria')}</label>
              <Chip style={{width:"100%"}} label={automotivaLeilao.RETORNO.NRCARROCERIA} />
            </div>
          </div>
        </div>
        {automotivaLeilao.LEILAOXML?.map((leilao:any) => {
          return (<>
            <br></br>
            <br></br>
            <Divider align="left">
              <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Leilão</span>
            </Divider>
            <br></br>
            <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Chassi" value={leilao.CONDICAO_CHASSI}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Leiloeiro" value={leilao.LEILOEIRO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Marca" value={leilao.MARCA}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Chassi" value={leilao.CHASSI}></OutPutCard>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Modelo" value={leilao.ANO_MODELO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Mecânica" value={leilao.CONDICAO_MECANICA}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Câmbio" value={leilao.CONDICAO_CAMBIO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Veículo" value={leilao.CONDICAO_VEICULO}></OutPutCard>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="UF" value={leilao.UF}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Comitente" value={leilao.COMITENTE}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Combustível" value={leilao.COMBUSTIVEL}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Lote" value={leilao.LOTE}></OutPutCard>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Direção Hidráulica" value={leilao.DIRECAO_HIDRAULICA}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Cor" value={leilao.COR}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Ar Condicionado" value={leilao.AR_CONDICIONADO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Placa" value={leilao.PLACA}></OutPutCard>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="KM" value={leilao.KILOMETRAGEM}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Carroceria" value={leilao.CONDICAO_CARROCERIA}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Data Leilão" value={leilao.DATA_LEILAO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Condição Motor" value={leilao.CONDICAO_MOTOR}></OutPutCard>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Importado" value={leilao.IMPORTADO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Modelo" value={leilao.MODELO}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <div >
                      <OutPutCard label="Categoria" value={leilao.CATEGORIA}></OutPutCard>
                  </div>
                </div>
                <div className="col p-fluid flex flex-wrap">
                  
                </div>
              </div>
          </>

          )
        })}
        
        
    </Panel>
  )
}
