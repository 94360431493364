import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { classNames } from "primereact/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import getEstado from "../../service/getEstado";
import getMunicipio from "../../service/getMunicipio";
import getVeiculoTransferenciaTipoPessoa from "../../service/getVeiculoTransferenciaTipoPessoa";
import getVeiculoTransferenciaTipoSolcitante from "../../service/getVeiculoTransferenciaTipoSolcitante/getVeiculoTransferenciaTipoSolcitante";
import getViaCep from "../../service/getViaCep";
import {
  isValidCNPJ,
  isValidCPF,
  isValidRenavam,
} from "../../utils/ValidationUtils";
import postAgregados from "../../service/postAgregados";

export default function VeiculoTransferenciaCadastrar({
  veiculoTransferencia,
  onClose,
  onSave,
  msgs,
}: {
  veiculoTransferencia: any;
  onClose: () => void;
  onSave: (veiculoTransferencia: any, enviar: boolean) => void;
  msgs: any;
}) {
  const { t } = useTranslation();
  const stepperRef = useRef<any>(null);
  const [tiposSolicitante, setTiposSolicitante] = useState<any>([]);
  const [tiposPessoa, setTiposPessoa] = useState<any>([]);
  const [estados, setEstados] = useState<any>([]);
  const [municipios, setMunicipios] = useState<any>([]);
  const [estadoFilter, setEstadoFilter] = useState<string | null>(null);
  const [tipoPessoaProprietario, setTipoPessoaProprietario] = useState<
    string | null
  >(null);

  const [tipoPessoaComprador, setTipoPessoaComprador] = useState<string | null>(
    null
  );
  const [achouCep, setAchouCep] = useState<boolean>(false);
  const [passo1, setPasso1] = useState<any>();
  const [passo2, setPasso2] = useState<any>();
  const [passo3, setPasso3] = useState<any>();
  const [passo4, setPasso4] = useState<any>();
  const [transferencia, setTransferencia] = useState<any>({});
  const [conferencia, setConferencia] = useState<boolean | undefined>(false);
  const [conferenciaData, setConferenciaData] = useState<boolean | undefined>(
    false
  );
  const [conferenciaDocumento, setConferenciaDocumento] = useState<
    boolean | undefined
  >(false);

  const defaultValues = {
    placa: "",
    renavam: "",
    chassi: "",
    hodometro: "",
    dataHodometro: null,
    crv: "",
    tipoSolicitante: null,
    dataTransferencia: null,
    valorVenda: null,
    rgComprador: "",
    numeroIdentidadeComprador: "",
    numeroIdentidadeProprietario: "",
    estado: null,
    municipio: null,
    nomeProprietario: "",
    cepComprador: "",
    bairroComprador: "",
    logradouroComprador: "",
    numeroComprador: "",
    ufComprador: "",
    cidadeComprador: "",
    complementoComprador: "",
    numeroProprietario: "",
    emailProprietario: "",
    documentoProprietario: "",
    tipoPessoaProprietario: null,
    nomeComprador: "",
    emailComprador: "",
    documentoComprador: "",
    tipoPessoaComprador: null,

    renvam: "",
  };

  const sexos = [
    { name: "Selecione", code: null },
    { name: "Masculino", code: "M" },
    { name: "Feminino", code: "F" },
  ];

  const formatDate = (value: {
    toLocaleDateString: (
      arg0: string,
      arg1: { day: string; month: string; year: string }
    ) => any;
  }) => {
    return value.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formatDateTime = (value: Date | null) => {
    if (!value) return "Não Informado";

    return (
      value.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
      " " +
      value.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = (data: any) => {
    setPasso1(data);
    if (stepperRef && stepperRef.current) {
      stepperRef.current.nextCallback();
    }
  };

  const validRenavam = (renavam: string) => {
    if (isValidRenavam(renavam)) {
      msgs.current?.clear();
    } else {
      msgs.current?.clear();
      msgs.current?.show([
        {
          sticky: false,
          life: 10000,
          severity: "error",
          summary: "Erro",
          detail: "Renavam inválido",
          closable: false,
        },
      ]);
    }
  };

  const validDocument = (document: string) => {
    document = document
      .replaceAll(".", "")
      .replaceAll("-", "")
      .replaceAll("/", "");
    let valid = true;
    if (document.length == 11) {
      valid = isValidCPF(document);
    } else {
      valid = isValidCNPJ(document);
    }
    if (valid) {
      msgs.current?.clear();
    } else {
      msgs.current?.clear();
      msgs.current?.show([
        {
          sticky: false,
          life: 10000,
          severity: "error",
          summary: "Erro",
          detail: "Documento inválido",
          closable: false,
        },
      ]);
    }
  };

  const onSubmit2 = (data: any) => {
    setPasso2(data);
    if (stepperRef && stepperRef.current) {
      stepperRef.current.nextCallback();
    }
  };

  const onSubmit3 = (data: any) => {
    setPasso3(data);
    if (stepperRef && stepperRef.current) {
      stepperRef.current.nextCallback();
    }
  };

  const onSubmit4 = (data: any) => {
    setPasso4(data);
    if (stepperRef && stepperRef.current) {
      setTimeout(() => populateTransferencia(data), 200);
    }
  };

  const populateTransferencia = (passo4: any) => {
    // console.log("Populando Transferência - Dados Recebidos:", {
    //   passo1,
    //   passo2,
    //   passo3,
    //   passo4,
    // });
    setTransferencia({
      id: veiculoTransferencia.id ? veiculoTransferencia.id : undefined,
      tipoSolicitante: passo1.tipoSolicitante,
      dataTransferencia: passo1.dataTransferencia,
      municipio: passo1.municipio.id,
      municipioNome: passo1.municipio.nome,

      placa: passo2.placa,
      chassi: passo2.chassi,
      renavam: passo2.renavam,
      crv: passo2.crv,
      valorVenda: passo2.valorVenda,
      hodometro: passo2.hodometro,
      dataHodometro: passo2.dataHodometro,

      tipoPessoaProprietario: passo3.tipoPessoaProprietario,
      documentoProprietario: passo3.documentoProprietario
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", ""),
      numeroIdentidadeProprietario: passo3.numeroIdentidadeProprietario,
      emailProprietario: passo3.emailProprietario,

      tipoPessoaComprador: passo4.tipoPessoaComprador,
      documentoComprador: passo4.documentoComprador
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", ""),
      numeroIdentidadeComprador: passo4.numeroIdentidadeComprador,
      nomeComprador: passo4.nomeComprador,
      emailComprador: passo4.emailComprador,
      cepComprador: passo4.cepComprador,

      logradouroComprador: passo4.logradouroComprador,
      numeroEnderecoComprador: passo4.numeroComprador,
      bairroComprador: passo4.bairroComprador,
      complementoComprador: passo4.complementoComprador,
      cidadeComprador: passo4.cidadeComprador,
      ufComprador: passo4.ufComprador,
    });
    stepperRef.current.nextCallback();
  };

  const buscarCep = (cep: string, comprador: boolean) => {
    if (cep.length == 8) {
      getViaCep(cep)
        .then((data) => {
          if (data && !data.erro) {
            if (comprador) {
              setValue("bairroComprador", data.bairro);
              setValue("cidadeComprador", data.localidade);
              setValue("ufComprador", data.uf);
              setValue("logradouroComprador", data.logradouro);
              setAchouCep(true);
            }
          } else {
            setAchouCep(false);
          }
        })
        .catch((erro) => setAchouCep(false));
    }
  };

  const save = (enviar: boolean) => {
    // console.log("Dados enviados ao backend:", transferencia);
    window.scrollTo(0, 0);
    onSave(transferencia, enviar);
  };

  const getFormErrorMessage = (name: string) => {
    type ObjectKey = keyof typeof errors;
    const error =
      errors[Object.keys(errors).find((k) => k === name) as ObjectKey];
    return error ? (
      <small className="p-error">{error.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  //Setar placa inicial postAgregados
  const [data, setData] = useState({
    placa: "",
  });

  //Callback postAgregados
  const handleClick = useCallback(async () => {
    if (!data.placa.trim()) return;

    try {
      const response: any = await postAgregados(data.placa, null);
      if (response) {
        setValue("placa", response.PLACA || "");
        setValue("chassi", response.CHASSI || "");
        setValue("renavam", response.RENAVAM || "");
      } else {
        console.warn("Nenhum dado encontrado para a placa:", data.placa);
      }
    } catch (error) {
      console.error("Erro ao buscar agregados:", error);
    }
  }, [data.placa, setValue]);

  useEffect(() => {
    getEstado().then((data) => setEstados(data));
    getVeiculoTransferenciaTipoSolcitante().then((data) =>
      setTiposSolicitante(data)
    );

    getVeiculoTransferenciaTipoPessoa().then((data) => setTiposPessoa(data));
    if (veiculoTransferencia.id) {
      setValue(
        "tipoSolicitante",
        veiculoTransferencia.tipoSolicitante.toUpperCase()
      );
      setValue("dataTransferencia", veiculoTransferencia.dataTransferencia);
      setValue("estado", veiculoTransferencia.estado);
      setEstadoFilter(veiculoTransferencia.estado);
      getMunicipio({ uf: veiculoTransferencia.estado }).then((data) => {
        setMunicipios(data);
        setValue(
          "municipio",
          data.filter((m: any) => m.id == veiculoTransferencia.municipio)[0]
        );
      });

      setValue("placa", veiculoTransferencia.placa.replace("-", ""));
      setValue("chassi", veiculoTransferencia.chassi);
      setValue("renavam", veiculoTransferencia.renavam);
      setValue("crv", veiculoTransferencia.crv);
      setValue("hodometro", veiculoTransferencia.hodometro);
      setValue("dataHodometro", veiculoTransferencia.dataHodometro);
      setValue("valorVenda", veiculoTransferencia.valorVenda);

      setValue(
        "tipoPessoaProprietario",
        veiculoTransferencia.tipoPessoaProprietario
      );
      setTipoPessoaProprietario(veiculoTransferencia.tipoPessoaProprietario);
      setValue(
        "documentoProprietario",
        veiculoTransferencia.documentoProprietario
      );
      setValue(
        "numeroIdentidadeProprietario",
        veiculoTransferencia.numeroIdentidadeProprietario
      );
      setValue("emailProprietario", veiculoTransferencia.emailProprietario);

      setValue("tipoPessoaComprador", veiculoTransferencia.tipoPessoaComprador);
      setTipoPessoaComprador(veiculoTransferencia.tipoPessoaComprador);

      setValue(
        "documentoComprador",
        veiculoTransferencia.documentoComprador
          .replaceAll(".", "")
          .replaceAll("-", "")
      );
      setValue(
        "numeroIdentidadeComprador",
        veiculoTransferencia.numeroIdentidadeComprador
      );
      setValue("nomeComprador", veiculoTransferencia.nomeComprador);
      setValue("emailComprador", veiculoTransferencia.emailComprador);
      setValue("cepComprador", veiculoTransferencia.cepComprador);
      setValue("logradouroComprador", veiculoTransferencia.logradouroComprador);
      setValue("numeroComprador", veiculoTransferencia.numeroEnderecoComprador);
      setValue("bairroComprador", veiculoTransferencia.bairroComprador);
      setValue("cidadeComprador", veiculoTransferencia.cidadeComprador);
      setValue(
        "complementoComprador",
        veiculoTransferencia.complementoComprador
      );
      setValue("ufComprador", veiculoTransferencia.ufComprador);
    }
  }, []);

  return (
    <>
      <Stepper ref={stepperRef} linear headerPosition="bottom">
        <StepperPanel header="Local/Data">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-column gap-2"
          >
            <div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="tipoSolicitante"
                    control={control}
                    rules={{ required: "Solicitante é Obrigatório" }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.tipoSolicitante,
                          })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="tipoSolicitante"
                              className="font-bold block mb-2 required"
                            >
                              {t("solicitante")}{" "}
                            </label>
                            <Dropdown
                              style={{ width: "50%" }}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={tiposSolicitante}
                              optionLabel="label"
                              placeholder="Selecione Solicitante"
                              filter
                              className="w-full md:w-14rem"
                            />
                          </div>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="dataTransferencia"
                    control={control}
                    rules={{ required: "Data Transferência é Obrigatório" }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.dataTransferencia,
                          })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="dataTransferencia"
                              className="font-bold block mb-2 required"
                            >
                              {t("dataTransferencia")}{" "}
                            </label>
                            <Calendar
                              maxDate={new Date()}
                              id="dataTransferencia"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              showIcon
                              dateFormat="dd/mm/yy"
                              locale="pt"
                            />
                          </div>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="estado"
                    control={control}
                    rules={{ required: "Estado é Obrigatório" }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({ "p-error": errors.estado })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="estado"
                              className="font-bold block mb-2 required"
                            >
                              {t("uf")}{" "}
                            </label>
                            <Dropdown
                              style={{ width: "50%" }}
                              value={field.value}
                              onChange={(e) => {
                                getMunicipio({ uf: e.value }).then((data) =>
                                  setMunicipios(data)
                                );
                                setEstadoFilter(e.value);
                                field.onChange(e.value);
                              }}
                              // Filtra apenas os estados desejados pelo nome
                              options={estados.filter(
                                (estado: { nome: string; sigla: string }) =>
                                  [
                                    "Minas Gerais",
                                    "São Paulo",
                                    "Paraná",
                                  ].includes(estado.nome)
                              )}
                              optionLabel="nome"
                              optionValue="sigla"
                              placeholder="Selecione Estado"
                              filter
                              className="w-full md:w-14rem"
                            />
                          </div>
                        </span>
                        <span
                          style={{
                            fontSize: "10px",
                            color: "#A0A0A0",
                            fontStyle: "italic",
                            marginTop: "2px",
                            display: "block",
                          }}
                        >
                          Disponível apenas para MG, PR e SP
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
                <div className="col">
                  <Controller
                    name="municipio"
                    control={control}
                    rules={{ required: "Municípipio é Obrigatório" }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.municipio,
                          })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="municipio"
                              className="font-bold block mb-2 required"
                            >
                              {t("cidade")}{" "}
                            </label>
                            <Dropdown
                              disabled={!estadoFilter}
                              style={{ width: "50%" }}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={municipios}
                              optionLabel="nome"
                              placeholder="Selecione Cidade"
                              filter
                              className="w-full md:w-14rem"
                            />
                          </div>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <br></br>
            <br></br>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
              className=" flex"
            >
              <Button
                icon="pi pi-arrow-right"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                label="Avançar"
                type="submit"
              />
            </div>
          </form>
        </StepperPanel>
        <StepperPanel header="Dados do Veículo">
          <form
            onSubmit={handleSubmit(onSubmit2)}
            className="flex flex-column gap-2"
          >
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="placa"
                  control={control}
                  rules={{ required: "Placa é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.placa })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="placa"
                            className="font-bold block mb-2 required"
                          >
                            {t("placa")}
                          </label>
                          <InputMask
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            mask="aaa-9*99"
                            onChange={(e) =>
                              setData({ ...data, placa: e.target.value || "" })
                            }
                            onBlur={handleClick}
                          ></InputMask>
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              {estadoFilter === "MG" && (
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="chassi"
                    control={control}
                    rules={
                      estadoFilter === "MG"
                        ? { required: "Chassi é Obrigatório" }
                        : {}
                    }
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({ "p-error": errors.chassi })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="chassi"
                              className="font-bold block mb-2 required"
                            >
                              Chassi
                            </label>
                            <InputText
                              id={field.name}
                              value={field.value}
                              maxLength={17}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                          </div>
                        </span>
                        <span
                          style={{
                            fontSize: "10px",
                            color: "#A0A0A0",
                            fontStyle: "italic",
                            marginTop: "2px",
                            display: "block",
                          }}
                        >
                          Campo obrigatório apenas para o Estado de MG
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              )}
              <div className="col">
                <Controller
                  name="renavam"
                  control={control}
                  rules={{ required: "Renavam é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.renavam })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="renavam"
                            className="font-bold block mb-2 required"
                          >
                            {t("renavam")}
                          </label>
                          <InputText
                            onBlur={(e) => validRenavam(e.target.value)}
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="crv"
                  control={control}
                  rules={{ required: "CRV é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.crv })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="crv"
                            className="font-bold block mb-2 required"
                          >
                            {t("crv")}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="valorVenda"
                  control={control}
                  rules={{ required: "Valor da Venda é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.valorVenda })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="valor"
                            className="font-bold block mb-2 required"
                          >
                            {t("valorVenda")}
                          </label>
                          <InputNumber
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.value)}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="hodometro"
                  control={control}
                  rules={{
                    required: "Hodômetro é obrigatório",
                    maxLength: {
                      value: 8,
                      message: "O hodômetro deve ter no máximo 8 caracteres",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.hodometro })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="hodometro"
                            className="font-bold block mb-2 required"
                          >
                            Hodômetro
                          </label>
                          <InputText
                            id={field.name}
                            value={field.value}
                            maxLength={8}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); // Remove qualquer caractere não numérico
                              field.onChange(numericValue);
                            }}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              {estadoFilter === "MG" && (
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="dataHodometro"
                    control={control}
                    rules={
                      estadoFilter === "MG"
                        ? { required: "Data/Hora Hodômetro é Obrigatório" }
                        : {}
                    }
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.dataHodometro,
                          })}
                        ></label>
                        <span className="">
                          <div style={{ position: "relative" }}>
                            <label
                              htmlFor="hodometro"
                              className="font-bold block mb-2 required"
                            >
                              {t("dataHodometro")}
                            </label>
                            <Calendar
                              id={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              showIcon
                              dateFormat="dd/mm/yy"
                              hourFormat="24"
                              showTime
                              locale="pt"
                              placeholder="Selecione ou digite a data/hora"
                              inputStyle={{ width: "100%" }}
                              maxDate={new Date()}
                              panelStyle={{
                                position: "absolute",
                                top: "40px", // Ajusta a posição do calendário
                                zIndex: 1000, // Garante que fique acima de outros elementos
                              }}
                            />
                          </div>
                        </span>
                        <span
                          style={{
                            fontSize: "10px",
                            color: "#A0A0A0",
                            fontStyle: "italic",
                            marginTop: "2px",
                            display: "block",
                          }}
                        >
                          Campo obrigatório apenas para o Estado de MG
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
              className=" flex"
            >
              <Button
                label="Voltar"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                icon="pi pi-arrow-right"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                label="Avançar"
                type="submit"
              />
            </div>
          </form>
        </StepperPanel>
        <StepperPanel header="Proprietário Atual">
          <form
            onSubmit={handleSubmit(onSubmit3)}
            className="flex flex-column gap-2"
          >
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="tipoPessoaProprietario"
                  control={control}
                  rules={{ required: "Tipo Pessoa é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.tipoPessoaProprietario,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="tipoPessoaProprietario"
                            className="font-bold block mb-2 required"
                          >
                            {t("tipoPessoa")}{" "}
                          </label>
                          <Dropdown
                            style={{ width: "50%" }}
                            value={field.value}
                            onChange={(e) => {
                              setTipoPessoaProprietario(e.value);
                              field.onChange(e.value);
                              if (
                                e.value === "FISICA" ||
                                e.value === "JURIDICA"
                              ) {
                                setValue("numeroIdentidadeProprietario", "");
                                setValue("documentoProprietario", "");
                              }
                            }}
                            options={tiposPessoa}
                            optionLabel="label"
                            placeholder="Selecione Tipo Pessoa"
                            filter
                            className="w-full md:w-14rem"
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>

              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="documentoProprietario"
                  control={control}
                  rules={{ required: "Documento é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.documentoProprietario,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="documentoProprietario"
                            className="font-bold block mb-2 required"
                          >
                            {t("documento")}{" "}
                          </label>
                          {tipoPessoaProprietario === "FISICA" ? (
                            <InputMask
                              id={field.name}
                              onBlur={(e) => validDocument(e.target.value)}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              mask="999.999.999-99"
                              onChange={(e) => field.onChange(e.target.value)}
                            ></InputMask>
                          ) : (
                            <InputMask
                              id={field.name}
                              onBlur={(e) => validDocument(e.target.value)}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              mask="99.999.999/9999-99"
                              onChange={(e) => field.onChange(e.target.value)}
                            ></InputMask>
                          )}
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="grid">
              {tipoPessoaProprietario === "FISICA" && estadoFilter === "MG" && (
                <div className="col p-fluid flex flex-wrap">
                  <Controller
                    name="numeroIdentidadeProprietario"
                    control={control}
                    rules={{ required: "RG é Obrigatório para o Estado de MG" }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.numeroIdentidadeProprietario,
                          })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="numeroIdentidadeProprietario"
                              className="font-bold block mb-2 required"
                            >
                              {t("rgProprietario")}{" "}
                            </label>
                            <InputText
                              id={field.name}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) =>
                                field.onChange(e.target.value.slice(0, 14))
                              }
                            />
                            <span
                              style={{
                                fontSize: "10px",
                                color: "#A0A0A0",
                                fontStyle: "italic",
                                marginTop: "2px",
                                display: "block",
                              }}
                            >
                              Campo obrigatório apenas para o Estado de MG. Ex:
                              MG1122334455
                            </span>
                          </div>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              )}

              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="emailProprietario"
                  control={control}
                  rules={{ required: "Email é obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.emailProprietario,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="emailProprietario"
                            className="font-bold block mb-2"
                          >
                            {t("email")}{" "}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
              className=" flex"
            >
              <Button
                label="Voltar"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                icon="pi pi-arrow-right"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                label="Avançar"
                type="submit"
              />
            </div>
          </form>
        </StepperPanel>

        <StepperPanel header="Comprador">
          <form
            onSubmit={handleSubmit(onSubmit4)}
            className="flex flex-column gap-2"
          >
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="tipoPessoaComprador"
                  control={control}
                  rules={{ required: "Tipo Pessoa é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.tipoPessoaComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="tipoPessoaComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("tipoPessoa")}{" "}
                          </label>
                          <Dropdown
                            style={{ width: "50%" }}
                            value={field.value}
                            onChange={(e) => {
                              setTipoPessoaComprador(e.value);
                              field.onChange(e.value);
                              if (e.value === "FISICA" || "JURIDICA") {
                                setValue("numeroIdentidadeComprador", "");
                                setValue("documentoComprador", "");
                              }
                            }}
                            options={tiposPessoa}
                            optionLabel="label"
                            placeholder="Selecione Tipo Pessoa"
                            filter
                            className="w-full md:w-14rem"
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="documentoComprador"
                  control={control}
                  rules={{ required: "Documento é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.documentoComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="documentoComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("documento")}{" "}
                          </label>
                          {tipoPessoaComprador === "FISICA" ? (
                            <>
                              <InputMask
                                onBlur={(e) => validDocument(e.target.value)}
                                id={field.name}
                                value={field.value}
                                className={classNames({
                                  "p-invalid": fieldState.error,
                                })}
                                mask="999.999.999-99"
                                onChange={(e) => field.onChange(e.target.value)}
                              ></InputMask>
                            </>
                          ) : (
                            <InputMask
                              id={field.name}
                              onBlur={(e) => validDocument(e.target.value)}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              mask="99.999.999/9999-99"
                              onChange={(e) => field.onChange(e.target.value)}
                            ></InputMask>
                          )}
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="grid">
              <div className="col">
                <Controller
                  name="nomeComprador"
                  control={control}
                  rules={{ required: "Nome é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.nomeComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="nomeComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("nome")}{" "}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              {tipoPessoaComprador === "FISICA" && estadoFilter === "MG" && (
                <div className="col">
                  <Controller
                    name="numeroIdentidadeComprador"
                    control={control}
                    rules={{
                      required: "RG é Obrigatório para pessoas físicas em MG",
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.numeroIdentidadeComprador,
                          })}
                        ></label>
                        <span className="">
                          <div>
                            <label
                              htmlFor="numeroIdentidadeComprador"
                              className="font-bold block mb-2 required"
                            >
                              {t("rgComprador")}
                            </label>
                            <InputText
                              id={field.name}
                              style={{ width: "100%" }}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) =>
                                field.onChange(e.target.value.slice(0, 14))
                              }
                            />
                            <span
                              style={{
                                fontSize: "10px",
                                color: "#A0A0A0",
                                fontStyle: "italic",
                                marginTop: "2px",
                                display: "block",
                              }}
                            >
                              Campo obrigatório apenas para o Estado de MG. Ex:
                              MG1122334455
                            </span>
                          </div>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              )}

              <div className="col">
                <Controller
                  name="emailComprador"
                  control={control}
                  rules={{ required: "E-mail é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.emailComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="emailComprador"
                            className="font-bold block mb-2"
                          >
                            {t("email")}{" "}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="cepComprador"
                  control={control}
                  rules={{ required: "CEP é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.cepComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="cepComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("cep")}{" "}
                          </label>
                          <InputText
                            maxLength={8}
                            max={8}
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              buscarCep(e.target.value, true);
                            }}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="logradouroComprador"
                  control={control}
                  rules={{ required: "Logradouro é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.logradouroComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="logradouroComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("logradouro")}{" "}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="numeroComprador"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.numeroComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label htmlFor="numeroComprador">{t("numero")}</label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value === "0000" ? "" : field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) =>
                              field.onChange(e.target.value || "0000")
                            }
                          />
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#A0A0A0",
                              fontStyle: "italic",
                              marginTop: "2px",
                              display: "block",
                            }}
                          >
                            Se o endereço não possuir número, deixei-o vazio.
                          </span>
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="bairroComprador"
                  control={control}
                  rules={{ required: "Bairro é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.bairroComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="bairroComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("bairro")}{" "}
                          </label>
                          <InputText
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                  name="complementoComprador"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.complementoComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="complementoComprador"
                            className="font-bold block mb-2"
                          >
                            {t("complemento")}{" "}
                          </label>
                          <InputText
                            maxLength={8}
                            max={8}
                            style={{ width: "100%" }}
                            id={field.name}
                            value={field.value}
                            className={classNames({
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="cidadeComprador"
                  control={control}
                  rules={{ required: "Cidade é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.cidadeComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="cidadeComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("cidade")}{" "}
                          </label>
                          {achouCep ? (
                            <InputText
                              disabled
                              style={{ width: "100%" }}
                              id={field.name}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                          ) : (
                            <Dropdown
                              disabled={!estadoFilter}
                              style={{ width: "50%" }}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={municipios}
                              optionValue="nome"
                              optionLabel="nome"
                              placeholder="Selecione Cidade"
                              filter
                              className="w-full md:w-14rem"
                            />
                          )}
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="ufComprador"
                  control={control}
                  rules={{ required: "UF é Obrigatório" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label
                        htmlFor={field.name}
                        className={classNames({
                          "p-error": errors.ufComprador,
                        })}
                      ></label>
                      <span className="">
                        <div>
                          <label
                            htmlFor="ufComprador"
                            className="font-bold block mb-2 required"
                          >
                            {t("uf")}{" "}
                          </label>
                          {achouCep ? (
                            <InputText
                              disabled
                              style={{ width: "100%" }}
                              id={field.name}
                              value={field.value}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                          ) : (
                            <Dropdown
                              style={{ width: "50%" }}
                              value={field.value}
                              onChange={(e) => {
                                getMunicipio({ uf: e.value }).then((data) =>
                                  setMunicipios(data)
                                );
                                setEstadoFilter(e.value);
                                field.onChange(e.value);
                              }}
                              options={estados}
                              optionLabel="nome"
                              optionValue="sigla"
                              placeholder="Selecione Estado"
                              filter
                              className="w-full md:w-14rem"
                            />
                          )}
                        </div>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
              className=" flex"
            >
              <Button
                label="Voltar"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                icon="pi pi-arrow-right"
                style={{ marginRight: "1rem", backgroundColor: "#183462" }}
                label="Avançar"
                type="submit"
              />
            </div>
          </form>
        </StepperPanel>
        <StepperPanel header="Finalização">
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Divider align="left">
                <span
                  className="p-tag"
                  style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
                >
                  Dados Veículo
                </span>
              </Divider>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <Divider align="left">
                <span
                  className="p-tag"
                  style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
                >
                  Proprietário Atual
                </span>
              </Divider>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("placa")}</label>
                <Chip style={{ width: "100%" }} label={transferencia.placa} />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">
                  {t("documentoProprietario")}
                </label>
                <Chip
                  style={{ width: "100%" }}
                  label={transferencia.documentoProprietario}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("chassi")}</label>
                <Chip style={{ width: "100%" }} label={transferencia.chassi} />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("rgProprietario")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.numeroIdentidadeProprietario &&
                    transferencia.numeroIdentidadeProprietario.trim() !== ""
                      ? transferencia.numeroIdentidadeProprietario
                      : "Não Informado"
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("renavam")}</label>
                <Chip style={{ width: "100%" }} label={transferencia.renavam} />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("email")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.emailProprietario
                      ? transferencia.emailProprietario
                      : "Não Informado"
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("crv")}</label>
                <Chip style={{ width: "100%" }} label={transferencia.crv} />
              </div>
            </div>
            <div className="col">
              <Divider align="left">
                <span
                  className="p-tag"
                  style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
                >
                  Comprador
                </span>
              </Divider>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <label className="label-dados">{t("valorVenda")}</label>
              <Chip
                style={{ width: "100%" }}
                label={
                  transferencia.valorVenda
                    ? new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(transferencia.valorVenda)
                    : "Não Informado"
                }
              />
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("nomeComprador")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={transferencia.nomeComprador}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("hodometro")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={transferencia.hodometro}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("documentoComprador")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={transferencia.documentoComprador}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("dataHodometro")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.dataHodometro
                      ? formatDateTime(transferencia.dataHodometro)
                      : "Não Informado"
                  }
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("rgComprador")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.numeroIdentidadeComprador &&
                    transferencia.numeroIdentidadeComprador.trim() !== ""
                      ? transferencia.numeroIdentidadeComprador
                      : "Não Informado"
                  }
                />
              </div>
            </div>
          </div>

          <div className="grid">
            {/* Trecho adaptativo para preenchimento em tela vazio */}
            <div className="col p-fluid flex flex-wrap">
              <div></div>
            </div>
            {/* Fim */}
            <div className="col">
              <div>
                <label className="label-dados">{t("email")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.emailComprador
                      ? transferencia.emailComprador
                      : "Não Informado"
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            {/* Trecho adaptativo para preenchimento em tela vazio */}
            <div className="col"></div>
            {/* Fim */}

            <div className="col">
              <div>
                <label className="label-dados">{t("endereco")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    transferencia.logradouroComprador +
                    ", " +
                    (transferencia.numeroEnderecoComprador &&
                    transferencia.numeroEnderecoComprador.trim() !== ""
                      ? transferencia.numeroEnderecoComprador
                      : "s/n") + // Verifica se é string vazia ou null e insere "0000"
                    " - " +
                    transferencia.bairroComprador +
                    " - " +
                    transferencia.cidadeComprador + // Mantido como comentário
                    "/" +
                    transferencia.ufComprador +
                    " - " +
                    transferencia.cepComprador
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Divider align="left">
                <span
                  className="p-tag"
                  style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
                >
                  Transferência
                </span>
              </Divider>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("Data")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    (transferencia.dataTransferencia
                      ? formatDate(transferencia.dataTransferencia)
                      : "") +
                    " " +
                    transferencia.municipioNome +
                    "/" +
                    estadoFilter
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="conferencia"
                  name="conferencia"
                  onChange={(e) => {
                    setConferencia(e.checked);
                  }}
                  checked={conferencia ? conferencia : false}
                />
                <b>
                  <label htmlFor="conferencia" className="ml-2">
                    {t("conferenciaComunicadoTransferencia")}
                  </label>
                </b>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="conferenciaData"
                  name="conferenciaData"
                  onChange={(e) => {
                    setConferenciaData(e.checked);
                  }}
                  checked={conferenciaData ? conferenciaData : false}
                />
                <label htmlFor="conferenciaData" className="ml-2">
                  CONFIRMO QUE A DATA DE TRANSFERÊNCIA{" "}
                  {transferencia.dataTransferencia
                    ? formatDate(transferencia.dataTransferencia)
                    : ""}{" "}
                  ESTÁ CORRETA (PODENDO SER ANTERIOR A DATA DE AUTENTICAÇÃO)
                </label>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="conferenciaDocumento"
                  name="conferenciaDocumento"
                  onChange={(e) => {
                    setConferenciaDocumento(e.checked);
                  }}
                  checked={conferenciaDocumento ? conferenciaDocumento : false}
                />
                <label htmlFor="conferenciaDocumento" className="ml-2">
                  {t("conferenciaDocumentoComunicadoTransferencia")}
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "1rem",
            }}
            className=" flex"
          >
            <Button
              label="Voltar"
              style={{ marginRight: "1rem", backgroundColor: "#183462" }}
              severity="secondary"
              icon="pi pi-arrow-left"
              onClick={() => stepperRef.current.prevCallback()}
            />
            <Button
              disabled={
                !conferencia || !conferenciaData || !conferenciaDocumento
              }
              icon="pi pi-save"
              style={{ marginRight: "1rem", backgroundColor: "#183462" }}
              label="Salvar"
              onClick={() => {
                save(false);
              }}
            />
            <Button
              disabled={
                !conferencia || !conferenciaData || !conferenciaDocumento
              }
              icon="pi pi-cloud-upload"
              style={{ marginRight: "1rem", backgroundColor: "#183462" }}
              label="Salvar e Enviar"
              onClick={() => {
                save(true);
              }}
            />
          </div>
        </StepperPanel>
      </Stepper>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "1rem",
        }}
        className=" flex"
      >
        <Button
          icon="pi pi-times"
          onClick={onClose}
          rounded
          outlined
          severity="danger"
          aria-label="Cancel"
        />
      </div>
    </>
  );
}
