import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { ToolbarCustom } from "../../../../components/ToolbarCustom";
import { PrintCustom } from "../../../../components/PrintCustom";
import { BannerCustom } from "../../../../components/BannerCustom";
import { FooterCustom } from "../../../../components/FooterCustom";
import Informativo from "../../../consulta/resposta/informativo/Informativo";
import Container from "../../../../components/menu/Container";

import RespostaInsumoPage from "../../../consulta/resposta/RespostaInsumo";

export const ResultView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({ content: () => contentRef.current });

  const handleClose = () => navigate("/consultarcrlv", { replace: true });

  return (
    <Container>
      <PrintCustom ref={contentRef}>
        <h1 style={{ marginTop: "4.5rem" }}>Consultar CRLV</h1>
        <br />
        <br />
        <ToolbarCustom onPrint={handlePrint} onClose={handleClose} />
        <br />
        <br />
        <BannerCustom {...state?.response} />
        <br />
        <br />
        <Informativo informative={state?.response?.informativo}></Informativo>

        {state?.response != null && (
          <RespostaInsumoPage resposta={state?.response} />
        )}
        <FooterCustom />
        <br />
        <br />
        <ToolbarCustom onPrint={handlePrint} onClose={handleClose} />
        <br />
        <br />
        <br />
      </PrintCustom>
    </Container>
  );
};
