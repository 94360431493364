import {
  ApiResponseAutomotivaFichaTecnica,
  DadosFormatados,
  AutomotivaFichaTecnicaProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';
import { formatDefaultValuesToString } from '../../../../utils/ValidationUtils';
import OutPutCard from "../../../../components/outputCard";
import { Divider } from "primereact/divider";

const formatarDadosGerais = (dados: any): DadosFormatados => {
  const renderizarDinamico = (rowData: any, field: string) => {
    const value = rowData[field];
    return formatDefaultValuesToString(value);
  };

  const resultado: JSX.Element[] = [];
  if (dados.EQUIPAMENTOS) {
    resultado.push(
      <>
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Equipamentos
          </span>
        </Divider>
        <div className="grid">
          {dados.EQUIPAMENTOS.map((equipamento: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard
                label={equipamento.DESCRICAO}
                value={equipamento.DISPONIBILIDADE}
              ></OutPutCard>
            </div>
          ))}
        </div>
      </>
    );
  } else if (dados.MEDICAO_COMBUSTIVEL) {
    const fields = Object.keys(dados.MEDICAO_COMBUSTIVEL[0] || {});
    resultado.push(
      <>
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Medição Combustível
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.MEDICAO_COMBUSTIVEL}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </>
    );
  } else if (dados.FOTOS_VEICULO) {
    const fields = Object.keys(dados.FOTOS_VEICULO[0] || {});
    resultado.push(
      <>
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Fotos Veículos
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.FOTOS_VEICULO}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </>
    );
  }

  return {
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseAutomotivaFichaTecnica
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.FICHA_TECNICA_VEICULAR)
    resultado.push(formatarDadosGerais(data.FICHA_TECNICA_VEICULAR));

  return resultado;
};

export function AutomotivaFichaTecnica({
  data,
}: AutomotivaFichaTecnicaProps) {
  const dadosGerais = getDadosGerais(data);
  
  return (
      <Panel className="p-panel-header-green" header="Automotiva Consulta Ficha Técnica" style={{ width: '98%' }} toggleable>
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Ficha Técnica
          </span>
        </Divider>
        <br></br>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Suspensão Traseira"
                value={data.FICHA_TECNICA_VEICULAR.SUSPENSAO_TRASEIRA}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Câmbio"
                value={data.FICHA_TECNICA_VEICULAR.CAMBIO}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Quantidade de Passageiros"
                value={data.FICHA_TECNICA_VEICULAR.QUANTIDADE_PASSAGEIROS}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Direção"
                value={data.FICHA_TECNICA_VEICULAR.DIRECAO}
              ></OutPutCard>
            </div>
          </div>
        </div>  
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Suspensão Dianteira"
                value={data.FICHA_TECNICA_VEICULAR.SUSPENSAO_DIANTEIRA}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Freio Dianteiro"
                value={data.FICHA_TECNICA_VEICULAR.FREIO_DIANTEIRO}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Motor"
                value={data.FICHA_TECNICA_VEICULAR.MOTOR}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap"></div>
        </div>   
        <br></br>
        <br></br> 
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Medidas
          </span>
        </Divider>
        <br></br>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Largura (mm)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.LARGURA}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Peso (kg)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.PESO}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Distância entre eixos (mm)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.ENTRE_EIXOS}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Capacidade do porta-malas (litros)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.PORTA_MALAS}
              ></OutPutCard>
            </div>
          </div>
        </div>  
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Capacidade do tanque de combustível (litros)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.TANQUE_COMBUSTIVEL}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Altura (mm)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.ALTURA}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div>
              <OutPutCard
                label="Comprimento (mm)"
                value={data.FICHA_TECNICA_VEICULAR.MEDIDAS.COMPRIMENTO}
              ></OutPutCard>
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap"></div>
        </div>   
        <br></br>
        <br></br>                            
        {dadosGerais.map(({ array }) => (
          <>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
            <br></br>
          </>
        ))}
      </Panel>
  );
}
