import { http } from '../../config/axios'

export default function getPermissaoTipoFiltro(tipoConsulta:string): Promise<any> {
  return http
    .get('api/permissao/tiposfiltro/' + tipoConsulta)
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro getPermissaoTipoFiltro', error)
      throw Error(error.response.data)
    })
}
