import { Panel } from "primereact/panel";
import { IProprietarioVeicular } from "../../../../interface/AutomotivaProprietarioVeicular";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const AutomotivaProprietarioVeicularPage = (
  data: IProprietarioVeicular
) => {
  return (
    <Panel header="Automotiva Proprietário Veicular" toggleable>
      <DataTable value={data?.RESPOSTA} emptyMessage="Nada consta">
        <Column header="Placa" field="placa" />
        <Column header="UF" field="ufVeiculo" />
        <Column header="Chassi" field="chassi" />
        <Column header="Marca" field="marca" />
        <Column header="Cor" field="cor" />
        <Column header="Categoria" field="categoria" />
      </DataTable>
    </Panel>
  );
};
