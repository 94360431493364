import { ReactNode } from "react";
import "./styles.css";

interface GridProps extends React.ComponentPropsWithoutRef<"div"> {}

const Container: React.FC<GridProps> = ({ className, ...props }) => {
  return <div className={`grid-container ${className}`} {...props} />;
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value?: string | ReactNode;
}

const Item: React.FC<ItemProps> = ({
  className,
  label = "",
  value,
  ...props
}) => {
  return (
    <div className={`grid-item ${className}`} {...props}>
      <label>{label}:</label>
      <p>{value ? value : "Não informado"}</p>
    </div>
  );
};

export const Card = { Container, Item };
