import { Panel } from "primereact/panel";
import { IAutomotivaProprietarioAtual } from "../../../../interface/AutomotivaProprietarioAtual";
import { Card } from "../../../../components/Card";

export const AutomotivaProprietarioAtual = (
  data: IAutomotivaProprietarioAtual
) => {
  const response = data?.PROPRIETARIO_ATUAL_VEICULO;

  const getValue = (value?: string): string => value || "Não informado";

  return (
    <Panel header="Dados Veículo Proprietário">
      {response && (
        <Card.Container>
          <Card.Item
            label="Marca e Modelo"
            value={getValue(response?.MARCA_MODELO)}
          />
          <Card.Item
            label="Data de Atualização"
            value={getValue(response?.DATA_ATUALIZACAO?.formatDate())}
          />
          <Card.Item label="CRLV" value={getValue(response?.CRLV)} />
          <Card.Item label="Chassi" value={getValue(response?.CHASSI)} />
          <Card.Item label="Placa" value={getValue(response?.PLACA)} />
          <Card.Item
            label="Ano do Modelo"
            value={getValue(response?.ANO_MODELO)}
          />
          <Card.Item
            label="Cor do Veículo"
            value={getValue(response?.COR_VEICULO)}
          />
          <Card.Item label="Município" value={getValue(response?.MUNICIPIO)} />
          <Card.Item label="UF" value={getValue(response?.UF)} />
          <Card.Item
            label="Combustível"
            value={getValue(response?.COMBUSTIVEL)}
          />
          <Card.Item label="Motor" value={getValue(response?.MOTOR)} />
          <Card.Item
            label="Documento do Proprietário"
            value={getValue(response?.PROPRIETARIO_DOCUMENTO)}
          />
          <Card.Item label="RENAVAM" value={getValue(response?.RENAVAM)} />
          <Card.Item
            label="Ano de Fabricação"
            value={getValue(response?.ANO_FABRICACAO)}
          />
          <Card.Item
            label="Nome do Proprietário"
            value={getValue(response?.PROPRIETARIO_NOME)}
          />
        </Card.Container>
      )}

      {!response && <p>Nada consta</p>}
    </Panel>
  );
};
