// utils/validationUtils.ts

export const formatCpfOrCnpj = (value: string): string => {
    // Remove qualquer caractere que não seja dígito
    const cleanValue = value.replace(/\D/g, '');

    // Verifica se o valor é um CPF (11 dígitos) ou CNPJ (14 dígitos)
    if (cleanValue.length === 11) {
        // Formata CPF: 000.000.000-00
        return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleanValue.length === 14) {
        // Formata CNPJ: 00.000.000/0000-00
        return cleanValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
        // Retorna erro ou mensagem caso o valor não tenha o tamanho de CPF ou CNPJ
        return 'Valor inválido para CPF ou CNPJ';
    }
}

export const formatCep = (cep: string): string => {
    // Remove qualquer caractere que não seja dígito
    const cleanCep = cep.replace(/\D/g, '');

    // Verifica se o CEP tem o tamanho correto (8 dígitos)
    if (cleanCep.length !== 8) {
        return 'CEP inválido';
    }

    // Formata o CEP no formato 00000-000
    return cleanCep.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    // Verifica se o número tem o formato válido de 10 ou 11 dígitos
    if (cleanedPhoneNumber.length === 10) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); // Formato (XX) XXXX-XXXX
    } else if (cleanedPhoneNumber.length === 11) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formato (XX) XXXXX-XXXX
    }

    return phoneNumber;
};

export const formatDefaultValuesToString = (value: any): string => {
    if (value === null || value === undefined) return "-";

    if (typeof value === "boolean") return value ? "Sim" : "Não";

    if (Array.isArray(value)) return renderArray(value);

    if (typeof value === "object" && !Array.isArray(value))
        return Object.values(value).join(" - ");

    if (isDateValue(value))
        return formatDate(value);

    return value.toString();
};

export const formatSpecificValuesToString = (key: any, value: any): string => {
    if (value === null || value === undefined) return "-";

    if (typeof value === "boolean") return value ? "Sim" : "Não";

    if (Array.isArray(value)) return renderArray(value);

    if (typeof value === "object" && !Array.isArray(value))
        return formatObjectValues(key, value);

    if (isDateValue(value)) {
        if (key === "idade") return calculateAgeToString(value);
        return formatDate(value);
    }

    return formatNonObjectValues(key, value);
};

const formatNonObjectValues = (key: any, value: any): string => {
    switch (key) {
        case "valor":
        case "valorPendencia":
        case "valorTotal":
        case "valorUltimaOcorrencia":
            return formatValueReais(value);

        case "CEP":
            return formatCep(value.toString());

        case "DOCUMENTO":
        case "CPFCNPJ":
            return formatCpfOrCnpj(value);

        default:
            return value.toString();
    }
};

const formatObjectValues = (key: any, value: any): string => {
    switch (key) {
        case "protocolo":
            return [
                `Número: ${value.numero || "N/A"}`,
                `Digito: ${value.digito || "N/A"}`,
            ].join(" - ");

        case "operador":
            return [
                `Código: ${value.codigo || "N/A"}`,
                `Nome: ${value.nome || "N/A"}`,
            ].join(" - ");

        case "endereco":
            const addressParts = [
                value.logradouro,
                value.numero,
                value.bairro,
                value.complemento,
                `${value.cidade?.estado?.siglaUf || ""} ${value.cidade?.nome || ""}`,
                formatCep(value.cep.toString()),
            ];
            return addressParts
                .filter((part) => part?.toString().trim())
                .join(" - ");

        case "telefoneCelular":
        case "telefoneResidencial":
        case "telefoneAssociado":
            return formatPhoneNumber(`${value.numeroDdd || ""}${value.numero || ""}`.trim());

        case "situacaoCpf":
            return [
                `Descrição Situação: ${value.descricaoSituacao || "N/A"}`,
                `UF: ${value.uf || "N/A"}`,
                `Data: ${value.dataSituacao ? formatDate(value.dataSituacao) : "N/A"}`,
            ].join(" - ");

        case "cpf":
            return [
                `CPF: ${value.numero ? formatCpfOrCnpj(value.numero) : "N/A"}`,
                `Região Origem: ${value.regiaoOrigem || "N/A"}`,
            ].join(" - ");

        case "origemAssociado":
        case "cidadeAssociado":
        case "cidade":
            return [
                `${value.estado?.siglaUf || "N/A"}`,
                `${value.nome || "N/A"}`,
            ].join(" - ");

        case "moeda":
            return value.simbolo || "-";

        default:
            return JSON.stringify(value);
    }
};

export const isDateValue = (value: any): boolean => {
    if (value instanceof Date)
        return !isNaN(value.getTime());

    if (typeof value === "string") {
        const parsedDate = new Date(value);
        return !isNaN(parsedDate.getTime()) && /^\d{4}-\d{2}-\d{2}/.test(value);
    }

    return false;
};

export const formatValueReais = (valor: number | string | null | undefined): string => {
    if (valor === null || valor === undefined || (typeof valor === "string" && !valor.trim())) 
        return "R$ 0,00";

    let numericValue: number;

    if (typeof valor === "string") {
        const valorSemVirgula = valor.replace(",", ".");
        numericValue = parseFloat(valorSemVirgula);

        if (isNaN(numericValue)) 
            return "R$ 0,00";
    } else 
        numericValue = valor;

    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(numericValue);
};

export const formatDate = (data: any): string => {
    const date = new Date(data);
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    return date.toLocaleDateString("pt-BR", options);
};

export const calculateAgeToString = (dateOfBirth: any): string => {
    const birth = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - birth.getFullYear();
    const month = today.getMonth() - birth.getMonth();

    // Se o mês atual for anterior ao mês de nascimento, ou for o mês de nascimento, mas o dia atual ainda não passou, diminui um ano
    if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
        age--;
    }

    return age.toString();
};

export const renderArray = (array: any[]): string => {
    return array
        .filter((item) => item !== null && item.toString().trim() !== "")
        .map((item) => (typeof item === "object" ? JSON.stringify(item) : item.toString()))
        .join(", ");
};

export const isValidRenavam = (renavam: string) => {

    let d: any = renavam.split("");
    let soma: number = 0,
        valor = 0,
        digito = 0,
        x = 0;

    for (var i = 5; i >= 2; i--) {
        soma += d[x] * i;
        x++;
    }

    valor = soma % 11;

    if (valor == 11 || valor == 0 || valor >= 10) {
        digito = 0;
    } else {
        digito = valor;
    }

    if (digito == d[4]) {
        return true;
    } else {
        return false;
    }
}

// Validação de CPF
export const isValidCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false; // CPF inválido se não tiver 11 dígitos ou todos os números forem iguais
    }

    let sum: number;
    let rest: number;
    sum = 0;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

// Validação de CNPJ
export const isValidCNPJ = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14) return false;

    let length: number = cnpj.length - 2;
    let numbers: string = cnpj.substring(0, length);
    let digits: string = cnpj.substring(length);
    let sum: number = 0;
    let pos: number = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
};

// Validação de e-mail
export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Validação de campo vazio
export const isEmpty = (value: string | null | undefined): boolean => {
    return value === null || value === undefined || value.trim() === '';
};

export const openBase64Data = (base64Data: string, dataType: string) => {
    if (!base64Data) return; // Evita erro se o campo estiver vazio
  
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type: dataType });
  
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank", "noopener,noreferrer");
};
  