import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const ptBrMessages = {
  examplePhrase: "frase de descrição da seção. breve e focada nas ações.",
  user: "Usuário",
  logout: "Sair",
  home: "Início",
  consultaCredito: "Consulta de Crédito",
  consultasCadastrais: "Consultas Cadastrais, Crédito e Automotivas",
  opcaoConsulta: "Opção Consulta",
  consultaPF: "Consultar Pessoa Física",
  consultaPJ: "Consultar Pessoa Jurídica",
  fone: "Telefone",
  celular: "Celular",
  data: "Data",
  dadosConsulta: "Dados da Consulta",
  nome: "Nome",
  nomeMae: "Nome da Mãe",
  documento: "Documento",
  situacao: "Situação",
  dataNascimento: "Nascimento",
  endereco: "Endereço",
  sexo: "Sexo",
  email: "E-mail",
  logradouro: "Logradouro",
  cidade: "Cidade",
  uf: "UF",
  complemento: "Complemento",
  bairro: "Bairro",
  numero: "Número",
  historicoEndereco: "Histórico Endereço",
  historico: "Histórico",
  login: "Login",
  senha: "Senha",
  ultimos30Dias: "Últimos 30 Dias:",
  de31a60Dias: "De 31 a 60 Dias:",
  de61a90Dias: "De 61 a 90 Dias:",
  acima90Dias: "Acima de 90 Dias:",
  quantidade: "Quantidade",
  segmento: "Segmento",
  listaConsulta: "Consultas",
  participacaoEmpresa: "Participações em empresas",
  cnpj: "CNPJ",
  razaoSocial: "Razão Social",
  tipo: "Tipo",
  participacao: "Participação",
  dataEntrada: "Data Entrada",
  dataAtualizacao: "Data Atualização",
  status: "Status",
  riscoCredito: "Risco de crédito:",
  probabilidadePagamento: "Probabilidade de pagamento:",
  score: "Score",
  usuario: "Usuário",
  usuarios: "Usuários",
  cadastrar: "Cadastrar",
  inativo: "Inativo",
  cache: "Cache",
  historicoConsulta: "Histórico de Consultas",
  autor: "Autor",
  comarca: "Comarca",
  origem: "Origem",
  dataDistribuicao: "Dt. Distribuição",
  dataProcesso: "Dt. Processo",
  vara: "Vara",
  forum: "Fórum",
  cidadeUF: "Cidade/UF",
  acoes: "Ações",
  ccfs: "Cheques sem Fundos",
  codigoBanco: "Código Banco",
  banco: "Banco",
  agencia: "Agência",
  motivo: "Motivo",
  dataUltimaOcorrencia: "Dt. Última Ocorrência",
  dataRegistro: "Dt. Registro",
  dataVencimento: "Dt. Vencimento",
  natureza: "Natureza",
  credor: "Credor",
  cnpjCredor: "CNPJ Credor",
  contrato: "Contrato",
  valor: "Valor",
  apontamentos: "Apontamentos",
  dataUltimoLogin: "Dt. Último Login",
  ipUltimoLogin: "Ip Último Login",
  cadastralCOPQ: "Cadastral COPQ",
  reu: "Réu",
  acoesCompleta: "Ações Completa",
  rendaPresumida: "Renda Presumida",
  enderecos: "Endereços",
  telefones: "Telefones",
  faixa: "Faixa",
  nomePai: "Nome do Pai",
  escolaridade: "Escolaridade",
  emails: "E-mails",
  completaPF: "Completa Pessoa Física",
  empresas: "Empresas",
  avalista: "Avalista",
  modalidade: "Modalidade",
  negativacoes: "Negativações",
  pendencias: "Pendências",
  cartorio: "Cartório",
  protestos: "Protestos",
  renda: "Renda",
  capitalSocial: "Capital Social",
  naturezaJuridica: "Natureza Jurídica",
  dataAbertura: "Data Abertura",
  cnae: "CNAE",
  nomeFantasia: "Nome Fantasia",
  cnaes: "CNAEs",
  codigo: "Código",
  descricao: "Descrição",
  completaPJ: "Completa Pessoa Jurídica",
  cadastralPJ: "Cadastral Pessoa Jurídica",
  cargo: "Cargo",
  socios: "Sócios",
  alterarSenha: "Alterar Senha",
  novaSenha: "Nova Senha",
  repetirNovaSenha: "Repetir Nova Senha",
  processo: "Processo",
  assunto: "Assunto",
  dataInicial: "Data Inicial",
  dataFinal: "Data Final",
  relatorioConsultaSintetica: "Relatório Consulta Sintética",
  tamanho: "Tamanho",
  downloads: "Downloads",
  tipoArquivo: "Extensão",
  arquivo: "Arquivo",
  filtroConsulta: "Filtro Consulta",
  protocolo: "Protocolo",
  opcoesConsulta: "Opções Consulta",
  clientes: "Clientes",
  cliente: "Cliente",
  responsavel: "Responsável",
  whatsapp: "WhatsApp",
  observacoes: "Observações",
  inserirEmail: "Insira seu E-mail",
  inserirSenha: "Insira sua nova senha",
  repetirSenha: "Repita sua nova senha",
  avisos: "Avisos",
  faixaFaturamento: "Faixa Faturamento",
  subFaixaFaturamento: "Sub Faixa",
  porte: "Porte",
  faturamentoPresumido: "Faturamento Presumido",
  indicadores: "Indicadores",
  erro: "Erro",
  dashboard: "Dashboard",
  conglomerados: "Conglomerados",
  permissoes: "Permissões",
  permissaoPai: "Permissão Pai",
  identificador: "Identificador",
  tipoConsulta: "Tipo Consulta",
  permissao: "Permissão",
  agruparConsulta: "Agrupar Consulta",
  qsaAtualizado: "QSA Atualizado",
  dataSaida: "Dt. Saída",
  motivos: "Motivos Score",
  dataSolicitacao: "Dt. Solicitação",
  partes: "Partes",
  movimentacoes: "Movimentações",
  polo: "Polo",
  advogados: "Advogados",
  urlRedirect: "URL Redirect",
  limiteCredito: "Limite Crédito",
  percentualCapacidadePagamento: "Percentual Capacidade Pagamento",
  capacidadePagamento: "Capacidade Pagamento",
  maisNegocio: "Mais Negócio",
  operacao: "Operação",
  recomendacao: "Recomendação",
  risco: "Risco",
  motivoScore: "Motivo Score",
  registro: "Registro",
  dtOcorrencia: "Dt. Ocorrência",
  dtInclusao: "Dt. Inclusão",
  registros: "Registros",
  dt_nascimento: "Dt. Nascimento",
  dt_ocorrencia: "Dt. Ocorrência",
  cep: "CEP",
  notificacao_email: "Notificação E-mail",
  notificacao_sms: "Notificação SMS",
  ddd: "DDD",
  motivoCancelamento: "Motivo Cancelamento",
  cancelarRegistro: "Cancelar Registro",
  grauAtividadePJ: "Grau Atividade PJ",
  participante: "Participante",
  documentoParticipante: "Doc. Participante",
  documentoEmpresa: "Doc. Empresa",
  comportamentoFinanceiro:
    "Relacionamento de Negócios - Quantidade de instituições por tempo de relacionamento",
  linhaCredito: "Linhas de Crédito - Contratos ativos",
  endividamento:
    "Endividamento - Compromissos assumidos considerando vencimento final dos contratos",
  pontualidadePagamento:
    "Pontualidade de pagamento - Operações Liquidadas nos últimos 12 meses",
  historicoPagamento:
    "Histórico Pagamentos - Pontualidade nos últimos 6 meses (em Reais)",
  codigoCliente: "Código Cliente",
  diaVencimentoFatura: "Dia Vencimento Fatura",
  dataContratacao: "Data Contratação",
  dataCadastro: "Data Cadastro",
  vcm: "VCM",
  usual: "Usual",
  arrojado: "Arrojado",
  conservador: "Conservador",
  perfilRisco: "Perfil de Risco",
  analises:
    "Análise Setorial (considera empresas do mesmo setor de atividade e porte)",
  nire: "Nire",
  colaboradores: "Colaboradores",
  filiais: "Filiais",
  filiaisRegiao: "Filiais/Região",
  restricao: "Restrição",
  relatorioSinteticoDeConsultas: "Relatório Sintético de Consultas",
  ordenacao: "Ordenação",
  restricoes: "Restrições",
  gastoEstimado: "Gasto Estimado",
  relatorioApuracaoBilhete: "Relatório Apuração Bilhete",
  whatsappDoSuporte: "WhatsApp do Suporte",
  loginQuod: "Login Quod",
  senhaQuod: "Senha Quod",
  loginSPC: "Login SPC",
  senhaSPC: "Senha SPC",
  loginBVS: "Login BVS",
  senhaBVS: "Senha BVS",
  loginSerasa: "Login Serasa",
  senhaSerasa: "Senha Serasa",
  loginInfoSimples: "Login Info Simples",
  senhaInfoSimples: "Senha Info Simples",
  loginProtesto: "Login Protesto",
  senhaProtesto: "Senha Protesto",
  tokenScorePositivo: "Token Score Positivo",
  observacao: "Observação",
  logoEmpresa: "Logo da Empresa",
  logoRevendaQuod: "Logo Revenda Quod",
  parametrosAplicacao: "Parâmetros da Aplicação",
  restricoesMercado: "Restrições de Mercado",
  editar: "Editar",
  franquias: "Franquias",
  franquia: "Franquia",
  leilao: "Leilão",
  leiloes: "Leilões",
  placa: "Placa",
  chassi: "Chassi",
  dataLeilao: "Dt. Leilão",
  lote: "Lote",
  marca: "Marca",
  modelo: "Modelo",
  anoModelo: "Ano Modelo",
  anoFabricacao: "Ano Fabricação",
  condicao: "Condição",
  comitente: "Comitente",
  cor: "Cor",
  combustivel: "Combustível",
  leiloeiro: "Leiloeiro",
  capturaLeilao: "Captura de Leilão",
  veiculos: "Veículos",
  veiculo: "Veículo",
  capturaKM: "Captura KM",
  consultaAutomotiva: "Consulta Automotiva",
  forncedorConsulta: "Fornecedor da Consulta",
  marcaModelo: "Marca/Modelo",
  anoFabricaoModelo: "Ano Fabricação/Modelo",
  motor: "Motor",
  renavam: "Renavam",
  filtro: "Filtro",
  anoExercicio: "Ano Exercício",
  ufDut: "UF DUT",
  municipio: "Município",
  correcaoChassi: "Correção Chassi",
  subSegmento: "Sub Segmento",
  carroceria: "Carroceria",
  cadastralNomePF: "Cadastral Nome PF",
  comunicadoVendaVeiculo: "Comunicado Venda Veículo",
  dataVenda: "Data Venda",
  solicitante: "Solicitante",
  valorVeiculo: "Valor Veículo",
  tipoDocumentoVeiculo: "Tipo Documento Veículo",
  crv: "CRV",
  codigoSegurancaCrv: "Código Segurança CRV",
  viaCrv: "Via CRV",
  dataEmissaoCrv: "Data Emissão CRV",
  tipoPessoa: "Tipo Pessoa",
  nomeComprador: "Nome Comprador",
  documentoProprietario: "Documento Proprietário",
  documentoComprador: "Documento Comprador",
  nomeProprietario: "Nome Proprietário",
  localData: "Local e Data",
  ufEmissaoCrv: "UF Emissão CRV",
  conferenciaComunicadoVenda:
    "Atesto que conferi os dados acima preenchidos com o documento original do cliente, validando o comunicado de venda",
  conferenciaDocumentoComunicadoVenda:
    "Confirmo que no documento ou cópia autenticada constam ambas as assinaturas (Vendedor e Comprador) reconhecidas por autenticidade",
  orgaoIdentidade: "Órgão Identidade",
  identidade: "Identidade",
  ufIdentidade: "UF Identidade",
  dt_firma: "Dt. Firma",
  cancelarComunicadoVenda: "Cancelar Comunicado de Venda",
  tempoCacheConsulta: "Tempo Cache Consulta Minutos",
  faturamentos: "Faturamentos",
  faturamento: "Faturamento",
  fatura: "Fatura",
  dataReferencia: "Dt. Referência",
  dataInicio: "Dt. Início",
  dataFim: "Dt. Fim",
  faturas: "Faturas",
  planos: "Planos",
  plano: "Plano",
  servico: "Serviço",
  servicos: "Serviços",
  tipoBilhete: "Tipo Bilhete",
  nomeComercial: "Nome Comercial",
  comunicadoTransferenciaVeiculo: "ATPV-e",
  dataTransferencia: "Data Transferência",
  conferenciaComunicadoTransferencia:
    "Atesto que conferi os dados acima preenchidos com o documento original do cliente, validando o comunicado de transferência",
  conferenciaDocumentoComunicadoTransferencia:
    "Confirmo que no documento ou cópia autenticada constam ambas as assinaturas (Vendedor e Comprador) reconhecidas por autenticidade",
  cancelarComunicadoTransferencia: "Cancelar Comunicado de Transferência",
  valorVenda: "Valor da Venda",
  hodometro: "Hodômetro",
  dataHodometro: "Data/Hora Hodômetro",
  rgProprietario: "RG",
  rgComprador: "RG",
  consultarCrlv: "CRLV-e",
  parametroAplicacao: 'Parâmetro Aplicação',
  consultas: 'Consultas',
  classificacao: 'Classificação',
  veicular: 'Veicular',
  credito: 'Crédito',
  cadastral: 'Cadastral',
  consultaCadastral: 'Consulta Cadastral'
};

i18n.use(initReactI18next).init({
  lng: "pt-BR",
  resources: {
    "pt-BR": {
      translation: {
        ...ptBrMessages,
      },
    },
  },
});
