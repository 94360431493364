import { ApiResponseCodigoCnh, CodigoCnhProps } from "./types";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./index.css";
import { formatDefaultValuesToString, formatCpfOrCnpj, formatCep } from "../../../../utils/ValidationUtils";
import OutPutCard from "../../../../components/outputCard";
import { Divider } from "primereact/divider";

const labelIndividuo = (key: string): string => {
  const labels: { [key: string]: string } = {
    nome: "Nome",
    dataNascimento: "Data de Nascimento",
    ufNaturalidade: "UF de Naturalidade",
    municipioNaturalidade: "Município de Naturalidade",
    descricaoLocalidadeNascimento: "Descrição da Localidade de Nascimento",
    sexo: "Sexo",
    nomeMae: "Nome da Mãe",
    nomePai: "Nome do Pai",
    ufEndereco: "UF do Endereço",
    municipioEndereco: "Município do Endereço",
    logradouro: "Logradouro",
    numero: "Número",
    complemento: "Complemento",
    bairro: "Bairro",
    cep: "CEP",
    nacionalidade: "Nacionalidade",
    latitudeAproximadaEndereco: "Latitude Aproximada do Endereço",
    longitudeAproximadaEndereco: "Longitude Aproximada do Endereço",
  };

  return labels[key] || key;
};

const labelHistoricoEmissao = (key: string): string => {
  const labels: { [key: string]: string } = {
    categoriaAtual: "Categoria Atual",
    categoriaRebaixada: "Categoria Rebaixada",
    categoriaAutorizada: "Categoria Autorizada",
    dataValidadeCnh: "Data de Validade da CNH",
    dataPrimeiraHabilitacao: "Data da Primeira Habilitação",
    ufPrimeiraHabilitacao: "UF da Primeira Habilitação",
    numeroSeguranca: "Número de Segurança",
    obervacoesCnh: "Observações da CNH",
    ufEmissao: "UF de Emissão",
    codigoLocalEmissao: "Código do Local de Emissão",
    localEmissao: "Local de Emissão",
    dataEmissaoCnh: "Data de Emissão da CNH",
    situacaoCnh: "Situação da CNH",
    situacaoAnteriorCnh: "Situação Anterior da CNH",
    dataSituacaoCnh: "Data da Situação da CNH",
  };

  return labels[key] || key;
};

const labelDadosGerais = (key: string): string => {
  const labels: { [key: string]: string } = {
    numeroRegistro: "Número de Registro",
    cpf: "CPF",
    numeroControleCondutor: "Número de Controle do Condutor",
    ufDominio: "UF do Domínio",
    numeroFormularioRenach: "Número do Formulário RENACH",
    numeroFormularioCnh: "Número do Formulário CNH",
    numeroPgu: "Número do PGU",
    dataCadastramento: "Data de Cadastramento",
    numeroListaImpedimento: "Número da Lista de Impedimentos",
    permissionario: "Permissionário",
    ufSolicitanteTransferencia: "UF Solicitante de Transferência",
    dataUltimaAtualizacao: "Data da Última Atualização",
    codigoUltimaAtualizacao: "Código da Última Atualização",
  };

  return labels[key] || key;
};

const getDadosGerais = (dados: any): JSX.Element[] => {
  let dadosFormatados: { label: string; value: any }[] = [];

  const renderizarDinamico = (rowData: any, field: string) => {
    const value = rowData[field];
    return formatDefaultValuesToString(value);
  };

  const resultado: JSX.Element[] = [];

  if (dados) {
    dadosFormatados = Object.entries(dados)
    .filter(
      ([key, value]) =>
        labelDadosGerais(key) !== key &&
        value !== null &&
        value !== undefined &&
        value !== ""
    )
    .map(([key, value]) => {
      if (key === "cpf" && value) 
        value = formatCpfOrCnpj(value.toString());

      return {
        label: labelDadosGerais(key),
        value: formatDefaultValuesToString(value),
      };
    });
    resultado.push(
      <div key="dadosGerais">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Dados Gerais
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard label={item.label} value={item.value}></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br>
      </div>
    );
  }

  if (dados.individuo) {
    dadosFormatados = Object.entries(dados.individuo)
      .filter(
        ([key, value]) =>
          labelIndividuo(key) !== key &&
          value !== null &&
          value !== undefined &&
          value !== ""
      )
      .map(([key, value]) => {
        if (key === "cep" && value) 
          value = formatCep(value.toString());

        return {
          label: labelIndividuo(key),
          value: formatDefaultValuesToString(value),
        };
      });
    resultado.push(
      <div key="individuo">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Indivíduo
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard label={item.label} value={item.value}></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br>
      </div>
    );
  }

  if (dados.historicoEmissao) {
    dadosFormatados = Object.entries(dados.historicoEmissao)
      .filter(
        ([key, value]) =>
          labelHistoricoEmissao(key) !== key &&
          value !== null &&
          value !== undefined &&
          value !== ""
      )
      .map(([key, value]) => {
        return {
          label: labelHistoricoEmissao(key),
          value: formatDefaultValuesToString(value),
        };
      });
    resultado.push(
      <div key="historicoEmissao">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Histórico de Emissão
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard label={item.label} value={item.value}></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br>
      </div>
    );
  }  

  if (dados.documento && dados.documento.length > 0) {
    resultado.push(
      <div key="documento">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Documento
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.documento}
        >
          <Column field="tipoDocumento" header="Tipo Documento" />
          <Column field="numeroDocumento" header="Número Documento" />
          <Column field="orgaoEmissao" header="Órgão Emissão" />
          <Column field="ufOrgaoEmissor" header="UF Órgão Emissor" />
        </DataTable>
        <br />
        <br />
      </div>
    );
  }

  if (dados.historicoRequerimentos && dados.historicoRequerimentos.length > 0) {
    resultado.push(
      <div key="historicoRequerimentos">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Histórico de Requerimentos
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.historicoRequerimentos}
        >
          <Column field="sequenciaRequerimento" header="Sequência Requerimento" />
          <Column field="motivoRequerimento" header="Motivo Requerimento" />
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.pid) {
    resultado.push(
      <div key="pid">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Pid
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.pid}
        >
          <Column field="numeroFormularioPid" header="Número Formulário Pid" />
          <Column field="dataValidadePid" header="Data Validade Pid"/>
          <Column field="ufExpedicaoPid" header="UF Expedição Pid"/>
          <Column field="numeroFormularioCnhBasePid" header="Número Formulário CNH Base Pid" />
          <Column field="situacaoPid" header="Situação Pid" />
        </DataTable>
        <br />
        <br />
      </div>
    );
  }  

  if (dados.pidRequerimentos) {
    const fields = Object.keys(dados.pidRequerimentos[0] || {});
    resultado.push(
      <div key="pidRequerimentos">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Pid Requerimentos
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.pidRequerimentos}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.habilitacaoEstrangeira) {
    const fields = Object.keys(dados.habilitacaoEstrangeira[0] || {});
    resultado.push(
      <div key="habilitacaoEstrangeira">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Habilitação Estrangeira
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.habilitacaoEstrangeira}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.restricoesMedicas) {
    const fields = Object.keys(dados.restricoesMedicas[0] || {});
    resultado.push(
      <div key="restricoesMedicas">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Restrições Médicas
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.restricoesMedicas}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.cursos) {
    const fields = Object.keys(dados.cursos[0] || {});
    resultado.push(
      <div key="cursos">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Cursos
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.cursos}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.cursoReciclagemInfrator) {
    const fields = Object.keys(dados.cursoReciclagemInfrator[0] || {});
    resultado.push(
      <div key="cursoReciclagemInfrator">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Curso Reciclagem Infrator
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.cursoReciclagemInfrator}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.cursoAtualizacaoRenovacaoCnh) {
    const fields = Object.keys(dados.cursoAtualizacaoRenovacaoCnh[0] || {});
    resultado.push(
      <div key="cursoAtualizacaoRenovacaoCnh">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Curso Atualização Renovação CNH
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.cursoAtualizacaoRenovacaoCnh}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  if (dados.bloqueios) {
    const fields = Object.keys(dados.bloqueios[0] || {});
    resultado.push(
      <div key="bloqueios">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Bloqueios
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.bloqueios}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );
  }

  return resultado;
};

const getDadosGeraisArray = (data: ApiResponseCodigoCnh): JSX.Element[] => {
  let resultado: JSX.Element[] = [];

  if (Array.isArray(data.RESPOSTA)) {
    data.RESPOSTA.forEach((item) => {
      resultado = getDadosGerais(item);
    });
  }

  return resultado;
};

export function CodigoCnh({ data }: CodigoCnhProps) {
  const dadosGerais = getDadosGeraisArray(data);

  return (
    <Panel
      className="p-panel-header-green"
      header="Automotiva Código CNH"
      style={{ width: "98%" }}
      toggleable
    >
      <>
        {dadosGerais.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
        <br></br>
      </>
    </Panel>
  );
}
