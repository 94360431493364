import { Panel } from "primereact/panel";
import React from "react";
import { TextField } from "../../../../components/TextField";
import { IScoreVeicular } from "../../../../interface/IScoreVeicular";
import "./styles.css";
import { transformBase64ToPng } from "../../../../utils/open_image_png_base64";
import { Image } from "primereact/image";

export const ScoreVeicularPage = (data: IScoreVeicular) => {
  const { DADOS_VEICULO, CHECK_LIST_VEICULO, OCORRENCIAS } =
    data?.LEILAO_CONJUGADO?.OCORRENCIAS[0];
  const {
    CHECKLIST_INSPECAO,
    DADOS_VEICULO: RE_DADOS_VEICULO,
    SCORE,
  } = data?.REMARKETING;

  const getValue = (value?: string): string => value || "Não informado";

  return (
    <Panel
      className="p-panel-header-green"
      header="Dados Score Veicular"
      style={{ width: "98%" }}
      toggleable
    >
      <Panel header="Dados do Veículo" toggleable>
        <div className="listaWrapper">
          <TextField label="Placa" value={getValue(DADOS_VEICULO?.PLACA)} />
          <TextField label="Renavam" value={getValue(DADOS_VEICULO?.RENAVAM)} />
          <TextField label="Chassi" value={getValue(DADOS_VEICULO?.CHASSI)} />
          <TextField
            label="Marca e Modelo"
            value={getValue(DADOS_VEICULO?.MARCA_MODELO)}
          />
          <TextField
            label="Ano de Fabricação"
            value={getValue(DADOS_VEICULO?.ANO_FABRICACAO)}
          />
          <TextField
            label="Ano do Modelo"
            value={getValue(DADOS_VEICULO?.ANO_MODELO)}
          />
          <TextField
            label="Categoria"
            value={getValue(DADOS_VEICULO?.CATEGORIA)}
          />
          <TextField
            label="Carroceria"
            value={getValue(DADOS_VEICULO?.CARROCERIA)}
          />
          <TextField
            label="Quantidade de Eixos"
            value={getValue(DADOS_VEICULO?.QTD_EIXOS)}
          />
          <TextField
            label="Eixo Traseiro"
            value={getValue(DADOS_VEICULO?.EIXO_TRASEIRO)}
          />
          <TextField
            label="Combustível"
            value={getValue(DADOS_VEICULO?.COMBUSTIVEL)}
          />
          <TextField label="Câmbio" value={getValue(DADOS_VEICULO?.CAMBIO)} />
          <TextField label="Motor" value={getValue(DADOS_VEICULO?.MOTOR)} />
          <TextField label="Cor" value={getValue(DADOS_VEICULO?.COR)} />
          <TextField
            label="Quilometragem"
            value={getValue(DADOS_VEICULO?.KILOMETRAGEM)}
          />
          <div />
        </div>
      </Panel>

      <br />
      <br />

      <Panel header="Checklist Veículo" toggleable>
        <div className="listaWrapper">
          <TextField
            label="Frente"
            value={getValue(CHECK_LIST_VEICULO?.FRENTE.DESCRICAO)}
          />
          <TextField
            label="Traseira"
            value={getValue(CHECK_LIST_VEICULO?.TRASEIRA.DESCRICAO)}
          />
          <TextField
            label="Lateral Esquerda"
            value={getValue(CHECK_LIST_VEICULO?.LATERAL_ESQUERDA.DESCRICAO)}
          />
          <TextField
            label="Lateral Direita"
            value={getValue(CHECK_LIST_VEICULO?.LATERAL_DIREITA.DESCRICAO)}
          />
          <TextField
            label="Teto"
            value={getValue(CHECK_LIST_VEICULO?.TETO.DESCRICAO)}
          />
          <TextField
            label="Interior"
            value={getValue(CHECK_LIST_VEICULO?.INTERIOR.DESCRICAO)}
          />
          <TextField
            label="Observações"
            value={getValue(CHECK_LIST_VEICULO?.OBSERVACOES)}
          />
          <TextField
            label="Rodas Faltantes"
            value={getValue(CHECK_LIST_VEICULO?.RODAS_FALTANTES)}
          />
          <TextField
            label="Local Queimado"
            value={getValue(CHECK_LIST_VEICULO?.LOCAL_QUEIMADO)}
          />
          <TextField
            label="Airbags Rompidos"
            value={getValue(CHECK_LIST_VEICULO?.AIRBAGS_ROMPIDOS)}
          />
          <TextField
            label="Existe Informação"
            value={getValue(CHECK_LIST_VEICULO?.EXISTE_INFORMACAO)}
          />
          <div />
        </div>
      </Panel>

      <br />
      <br />

      <Panel header="Ocorrências" toggleable>
        {OCORRENCIAS?.map((item, index) => (
          <React.Fragment key={index}>
            <div className="listaWrapper">
              <TextField label="Comitente" value={getValue(item?.COMITENTE)} />
              <TextField label="Leiloeiro" value={getValue(item?.LEILOEIRO)} />
              <TextField label="Lote" value={getValue(item?.LOTE)} />
              <TextField label="Pátio" value={getValue(item?.PATIO)} />
              <TextField
                label="Data do Leilão"
                value={getValue(item?.DATA_LEILAO)}
              />
              <TextField
                label="Condição Geral do Veículo"
                value={getValue(item?.CONDICAO_GERAL_VEICULO)}
              />
              <TextField
                label="Condição Mecânica"
                value={getValue(item?.CONDICAO_MECANICA)}
              />
              <TextField
                label="Condição do Motor"
                value={getValue(item?.CONDICAO_MOTOR)}
              />
              <TextField
                label="Condição do Câmbio"
                value={getValue(item?.CONDICAO_CAMBIO)}
              />
              <TextField
                label="Situação do Chassi"
                value={getValue(item?.SITUACAO_CHASSI)}
              />
              <TextField
                label="Observações"
                value={getValue(item?.OBSERVACOES)}
              />
              <div />
            </div>

            <br />
            <br />
          </React.Fragment>
        ))}

        {OCORRENCIAS?.length === 0 && <p>Sem Ocorrências</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Remarketing" toggleable>
        <Panel header="Score" toggleable>
          <div className="listaWrapper">
            <TextField
              label="Descrição da Pontuação"
              value={getValue(SCORE?.DESCRICAO_PONTUACAO)}
            />
            <TextField label="Pontuação" value={getValue(SCORE?.PONTUACAO)} />
            <TextField
              label="Percentual sobre Tabela de Referência"
              value={getValue(SCORE?.PERCENTUAL_SOBRE_TABELA_REFERENCIA)}
            />
            <TextField label="Aceitação" value={getValue(SCORE?.ACEITACAO)} />
            <TextField
              label="Exige Vistoria Especial"
              value={getValue(SCORE?.EXIGE_VISTORIA_ESPECIAL)}
            />
          </div>
        </Panel>

        <br />
        <br />

        <Panel header="Dados do Veículo" toggleable>
          <div className="listaWrapper">
            <TextField
              label="Placa"
              value={getValue(RE_DADOS_VEICULO?.PLACA)}
            />
            <TextField
              label="Chassi"
              value={getValue(RE_DADOS_VEICULO?.CHASSI)}
            />
            <TextField
              label="Renavam"
              value={getValue(RE_DADOS_VEICULO?.RENAVAM)}
            />
            <TextField
              label="Marca e Modelo"
              value={getValue(RE_DADOS_VEICULO?.MARCA_MODELO)}
            />
            <TextField
              label="Carroceria"
              value={getValue(RE_DADOS_VEICULO?.CARROCERIA)}
            />
            <TextField label="Cor" value={getValue(RE_DADOS_VEICULO?.COR)} />
            <TextField
              label="Segmento"
              value={getValue(RE_DADOS_VEICULO?.SEGMENTO)}
            />
            <TextField
              label="Subsegmento"
              value={getValue(RE_DADOS_VEICULO?.SUB_SEGMENTO)}
            />
            <TextField
              label="Ano de Fabricação"
              value={getValue(RE_DADOS_VEICULO?.ANO_FABRICACAO)}
            />
            <TextField
              label="Ano do Modelo"
              value={getValue(RE_DADOS_VEICULO?.ANO_MODELO)}
            />
            <TextField
              label="Quantidade de Eixos"
              value={getValue(RE_DADOS_VEICULO?.QTD_EIXOS)}
            />
            <TextField
              label="Eixo Traseiro"
              value={getValue(RE_DADOS_VEICULO?.EIXO_TRASEIRO)}
            />
            <TextField
              label="Quilometragem"
              value={getValue(RE_DADOS_VEICULO?.KILOMETRAGEM)}
            />
            <TextField
              label="Combustível"
              value={getValue(RE_DADOS_VEICULO?.COMBUSTIVEL)}
            />
            <TextField
              label="Câmbio"
              value={getValue(RE_DADOS_VEICULO?.CAMBIO)}
            />
            <TextField
              label="Motor"
              value={getValue(RE_DADOS_VEICULO?.MOTOR)}
            />
            <TextField
              label="Situação do Chassi"
              value={getValue(RE_DADOS_VEICULO?.SITUACAO_CHASSI)}
            />
          </div>
        </Panel>

        <br />
        <br />

        <Panel header="Checklist Inspeção" toggleable>
          <div className="listaWrapper">
            <TextField
              label="Data da Inspeção"
              value={getValue(CHECKLIST_INSPECAO?.DATA_INSPECAO)}
            />
            <TextField
              label="Observações"
              value={getValue(CHECKLIST_INSPECAO?.OBSERVACOES)}
            />
            <TextField
              label="Link Laudo Técnico 1"
              value={getValue(CHECKLIST_INSPECAO?.LINK_LAUDO_TECNICO_1)}
            />
            <TextField
              label="Link Laudo Técnico 2"
              value={getValue(CHECKLIST_INSPECAO?.LINK_LAUDO_TECNICO_2)}
            />
            <TextField
              label="Frente"
              value={getValue(CHECKLIST_INSPECAO?.FRENTE)}
            />
            <TextField
              label="Frente Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.FRENTE_ESQUERDA)}
            />
            <TextField
              label="Frente Direita"
              value={getValue(CHECKLIST_INSPECAO?.FRENTE_DIREITA)}
            />
            <TextField
              label="Lado Esquerdo"
              value={getValue(CHECKLIST_INSPECAO?.LADO_ESQUERDO)}
            />
            <TextField
              label="Lado Direito"
              value={getValue(CHECKLIST_INSPECAO?.LADO_DIREITO)}
            />
            <TextField
              label="Traseira"
              value={getValue(CHECKLIST_INSPECAO?.TRASEIRA)}
            />
            <TextField
              label="Traseira Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.TRASEIRA_ESQUERDA)}
            />
            <TextField
              label="Traseira Direita"
              value={getValue(CHECKLIST_INSPECAO?.TRASEIRA_DIREITA)}
            />
            <TextField
              label="Bancos Dianteiros"
              value={getValue(CHECKLIST_INSPECAO?.BANCOS_DIANTEIROS)}
            />
            <TextField
              label="Bancos Traseiros"
              value={getValue(CHECKLIST_INSPECAO?.BANCOS_TRASEIROS)}
            />
            <TextField
              label="Forro do Teto"
              value={getValue(CHECKLIST_INSPECAO?.FORRO_TETO)}
            />
            <TextField
              label="Porta Frente Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.PORTA_FRENTE_ESQUERDA)}
            />
            <TextField
              label="Porta Frente Direita"
              value={getValue(CHECKLIST_INSPECAO?.PORTA_FRENTE_DIREITA)}
            />
            <TextField
              label="Porta Traseira Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.PORTA_TRASEIRA_ESQUERDA)}
            />
            <TextField
              label="Porta Traseira Direita"
              value={getValue(CHECKLIST_INSPECAO?.PORTA_TRASEIRA_DIREITA)}
            />
            <TextField
              label="Porta Malas"
              value={getValue(CHECKLIST_INSPECAO?.PORTA_MALAS)}
            />
            <TextField
              label="Caçamba"
              value={getValue(CHECKLIST_INSPECAO?.CACAMBA)}
            />
            <TextField
              label="Tanque de Combustível"
              value={getValue(CHECKLIST_INSPECAO?.TANQUE_COMBUSTIVEL)}
            />
            <TextField
              label="Motor"
              value={getValue(CHECKLIST_INSPECAO?.MOTOR)}
            />
            <TextField
              label="Garantia Câmbio/Motor"
              value={getValue(CHECKLIST_INSPECAO?.TEM_GARANTIA_CAMBIO_MOTOR)}
            />
            <TextField
              label="Pneu Frente Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.PNEU_FRENTE_ESQUERDA)}
            />
            <TextField
              label="Pneu Frente Direita"
              value={getValue(CHECKLIST_INSPECAO?.PNEU_FRENTE_DIREITA)}
            />
            <TextField
              label="Pneu Traseira Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.PNEU_TRASEIRA_ESQUERDA)}
            />
            <TextField
              label="Pneu Traseira Direita"
              value={getValue(CHECKLIST_INSPECAO?.PNEU_TRASEIRA_DIREITA)}
            />
            <TextField
              label="Teto"
              value={getValue(CHECKLIST_INSPECAO?.TETO)}
            />
            <TextField
              label="Carenagem Dianteira"
              value={getValue(CHECKLIST_INSPECAO?.CARENAGEM_DIANTEIRA)}
            />
            <TextField
              label="Carenagem Esquerda"
              value={getValue(CHECKLIST_INSPECAO?.CARENAGEM_ESQUERDA)}
            />
            <TextField
              label="Carenagem Direita"
              value={getValue(CHECKLIST_INSPECAO?.CARENAGEM_DIREITA)}
            />
            <TextField
              label="Carenagem Traseira"
              value={getValue(CHECKLIST_INSPECAO?.CARENAGEM_TRASEIRA)}
            />
          </div>

          <div>
            <TextField label="Fotos da Inspeção" value={""} />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "40px",
              }}
            >
              {CHECKLIST_INSPECAO?.FOTOS_INSPECAO.map((item) => (
                <Image
                  key={item}
                  src={transformBase64ToPng(item.IMG_BASE64)}
                  preview
                  imageStyle={{
                    maxWidth: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ))}
            </div>
          </div>
        </Panel>
      </Panel>
    </Panel>
  );
};
