import { Message } from "primereact/message";

export const FooterCustom = () => {
  return (
    <Message
      style={{ width: "98%" }}
      severity="warn"
      text={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "24px 0px",
          }}
        >
          <div>
            <h3 style={{ margin: 0 }}>INFORMAÇÕES IMPORTANTES:</h3>
            <p style={{ margin: 0 }}>
              <b>Confidencialidade e Uso das Informações:</b> As informações
              contidas neste mensagem são confidenciais e deverão ser utilizadas
              exclusivamente para a orientação das transações comerciais
              relacionadas ao CNPJ: 28.932.263/0001-58. Qualquer uso em
              desacordo com a legislação vigente, incluindo a Lei Geral de
              Proteção de Dados (Lei 13.709/18 - LGPD), poderá acarretar
              responsabilidade civil e criminal pelos danos causados a
              terceiros. A responsabilidade da Carro Positivo se limita à
              transmissão fiel dos dados obtidos de veículos automotores,
              registrados em bases de dados públicas e privadas. As informações
              exibidas nesta consulta refletem o conteúdo disponível na data e
              hora da pesquisa e são de responsabilidade do CNPJ:
              28.932.263/0001-58. Em caso de dúvida ou divergência, recomenda-se
              consultar diretamente o órgão competente. O CNPJ
              28.932.263/0001-58 declara estar ciente de que todas as
              informações fornecidas nesta consulta têm o objetivo de auxiliar
              na verificação da procedência do veículo, servindo apenas como uma
              ferramenta de análise preliminar, e não como a única base para
              tomada de decisão em processos de vistoria ou comercialização do
              veículo. Importante destacar que o levantamento de informações por
              meio de consulta eletrônica não substitui a consulta ao órgão
              oficial competente, especialmente porque as informações podem ser
              alteradas, incluídas ou removidas em tempo real por órgãos
              públicos, instituições financeiras e seguradoras. Veículos
              cadastrados em outros estados podem apresentar vícios ocultos,
              restrições, débitos ou bloqueios que só serão identificados no
              prontuário do veículo no momento da transferência efetiva para o
              novo estado. As informações mencionadas são de uso exclusivo do
              destinatário e estão protegidas por sigilo contratual. Qualquer
              uso por terceiros ou para finalidades distintas daquelas acordadas
              caracteriza ilícito civil, invalidando a prova para efeitos
              processuais.
            </p>
          </div>
          <div>
            <h3 style={{ margin: 0 }}>AVISO:</h3>
            <p style={{ margin: 0 }}>
              Ainda que empregando os melhores esforcos, nossa empresa se exime
              de qualquer responsabilidade pela eventual nao inclusao de algum
              registro em razao de atraso ou falta do encaminhamento dos dados
              pelos diversos provedores de informacoes conveniados. Em caso de
              duvidas entre em contato com nosso suporte.
            </p>
          </div>
          <div>
            <h3 style={{ margin: 0 }}>MINUTA DE DECLARAÇÃO LGPD:</h3>
            <p style={{ margin: 0 }}>
              Declaro que a utilização dos dados respeitará as finalidades e
              procedimentos legalmente admitidos pela Lei Geral de Proteção de
              Dados divulgados em minha página inicial de acesso.
            </p>
          </div>
        </div>
      }
    />
  );
};
