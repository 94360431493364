import Container from "../../components/menu/Container";
import { useTranslation } from "react-i18next";
import VeiculoTransferenciaList from "./VeiculoTransferenciaList";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import VeiculoTransferenciaCadastrar from "./VeiculoTransferenciaCadastrar";
import { Messages } from "primereact/messages";
import postVeiculoTransferencia from "../../service/postVeiculoTransferencia";
import putVeiculoTransferencia from "../../service/putVeiculoTransferencia";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import getVeiculoTransferenciaMotivoCancelamento from "../../service/getVeiculoTransferenciaMotivoCancelamento";
import putVeiculoTrasferenciaCancelarComunicado from "../../service/putVeiculoTrasferenciaCancelarComunicado";
import putVeiculoTransferenciaComunicar from "../../service/putVeiculoTransferenciaComunicar";
import VeiculoTransferenciaImpressao from "./VeiculoTransferenciaImpressao";

export default function VeiculoTransferenciaPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const msgsDialog = useRef<Messages>(null);
  const [veiculoTransferencia, setVeiculoTransferencia] = useState<any | null>(
    null
  );
  const [veiculoTransferenciaImpressao, setVeiculoTransferenciaImpressao] =
    useState<any | null>(null);
  const [veiculoTransferenciaEdit, setVeiculoTransferenciaEdit] = useState<
    any | null
  >(null);
  const [selectedMotivo, setSelectedMotivo] = useState<any>(null);
  const [motivos, setMotivos] = useState<any>([]);
  const labelButton = t("cadastrar");
  const saveVeiculoTransferencia = (
    veiculoTransferencia: any,
    enviar: boolean
  ) => {
    if (veiculoTransferencia.id) {
      putVeiculoTransferencia(veiculoTransferencia).then((data) => {
        if (data.includes("Erro")) {
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
              {
                sticky: false,
                life: 10000,
                severity: "error",
                summary: "Erro",
                detail: data,
                closable: false,
              },
            ]);
          }
        } else {
          if (enviar) {
            putVeiculoTransferenciaComunicar(veiculoTransferencia).then(
              (data) => {
                setVeiculoTransferencia(null);
                if (msgs.current) {
                  msgs.current?.clear();
                  msgs.current?.show([
                    {
                      sticky: false,
                      life: 10000,
                      severity: "success",
                      summary: "Sucesso",
                      detail: data,
                      closable: false,
                    },
                  ]);
                }
              }
            );
          }
        }
      });
    } else {
      postVeiculoTransferencia(veiculoTransferencia).then((data) => {
        if (data.includes("Erro")) {
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
              {
                sticky: false,
                life: 10000,
                severity: "error",
                summary: "Erro",
                detail: data,
                closable: false,
              },
            ]);
          }
        } else {
          if (enviar) {
            putVeiculoTransferenciaComunicar(veiculoTransferencia).then(
              (data) => {
                setVeiculoTransferencia(null);
                if (msgs.current) {
                  msgs.current?.clear();
                  msgs.current?.show([
                    {
                      sticky: false,
                      life: 10000,
                      severity: "success",
                      summary: "Sucesso",
                      detail: data,
                      closable: false,
                    },
                  ]);
                }
              }
            );
          }
          setVeiculoTransferencia(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
              {
                sticky: false,
                life: 10000,
                severity: "success",
                summary: "Sucesso",
                detail: data,
                closable: false,
              },
            ]);
          }
        }
      });
    }
  };

  const resetForm = () => {
    setVeiculoTransferenciaEdit(undefined);
  };

  const cancelarComunicado = () => {
    const cancelamento = {
      id: veiculoTransferenciaEdit.id,
      motivoCancelamento: selectedMotivo,
    };
    putVeiculoTrasferenciaCancelarComunicado(cancelamento).then((data) => {
      if (data.includes("Erro")) {
        if (msgs.current) {
          msgsDialog.current?.clear();
          msgsDialog.current?.show([
            {
              sticky: false,
              life: 10000,
              severity: "error",
              summary: "Erro",
              detail: data,
              closable: false,
            },
          ]);
        }
      } else {
        setVeiculoTransferenciaEdit(null);

        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
            {
              sticky: false,
              life: 10000,
              severity: "success",
              summary: "Sucesso",
              detail: data,
              closable: false,
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    getVeiculoTransferenciaMotivoCancelamento().then((data) =>
      setMotivos(data)
    );
  }, []);

  const footerContent = (
    <React.Fragment>
      <Button
        form="form-permission"
        style={{ marginRight: "1rem", backgroundColor: "#183462" }}
        label="Cancelar"
        onClick={() => cancelarComunicado()}
      />
      <Button
        label="Fechar"
        icon="pi pi-times"
        style={{ color: "#183462" }}
        onClick={() => resetForm()}
        className="p-button-text"
      />
    </React.Fragment>
  );

  const startContent = (
    <React.Fragment>
      <Button
        label={labelButton}
        icon="pi pi-plus"
        style={{ backgroundColor: "#183462" }}
        onClick={() => {
          setVeiculoTransferencia({});
        }}
      />
    </React.Fragment>
  );

  return (
    <Container>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray">
              <i
                className="pi pi-shopping-cart"
                style={{ fontSize: "2.0rem" }}
              ></i>{" "}
              {t("comunicadoTransferenciaVeiculo")}
            </h1>
          </div>
        </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!veiculoTransferencia && !veiculoTransferenciaImpressao && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <VeiculoTransferenciaList
              msgs={msgs}
              onPrint={(veiculoTransferencia: any) => {
                setVeiculoTransferenciaImpressao(veiculoTransferencia);
              }}
              onEdit={(veiculoTransferencia: any) => {
                setVeiculoTransferencia(veiculoTransferencia);
              }}
              onDelete={(veiculoTransferencia: any) => {
                setVeiculoTransferenciaEdit(veiculoTransferencia);
              }}
            ></VeiculoTransferenciaList>
          </>
        )}
        {veiculoTransferencia && (
          <>
            <VeiculoTransferenciaCadastrar
              msgs={msgs}
              veiculoTransferencia={veiculoTransferencia}
              onSave={saveVeiculoTransferencia}
              onClose={() => {
                setVeiculoTransferencia(null);
              }}
            ></VeiculoTransferenciaCadastrar>
          </>
        )}
        {veiculoTransferenciaImpressao && (
          <>
            <VeiculoTransferenciaImpressao
              veiculoTransferencia={veiculoTransferenciaImpressao}
              onClose={() => {
                setVeiculoTransferenciaImpressao(null);
              }}
            ></VeiculoTransferenciaImpressao>
          </>
        )}
      </div>
      <Dialog
        header={t("cancelarComunicadoTransferencia")}
        visible={veiculoTransferenciaEdit}
        style={{ width: "80vw" }}
        onHide={() => resetForm()}
        footer={footerContent}
      >
        <Messages ref={msgsDialog} />
        <div>
          <div className="grid">
            <div
              className="col p-fluid flex flex-wrap"
              style={{ flexGrow: "0.5" }}
            >
              <div>
                <label className="label-dados" style={{ paddingLeft: "0" }}>
                  {t("motivoCancelamento")}
                </label>
                <Dropdown
                  style={{ width: "50%" }}
                  value={selectedMotivo}
                  onChange={(e) => setSelectedMotivo(e.value)}
                  options={motivos}
                  optionLabel="label"
                  placeholder="Selecione Motivo Cancelamento"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}
