import { Divider } from "primereact/divider";

export const TitleDivider = ({ title }: { title: string }) => {
  return (
    <Divider align="left">
      <div className="flex-score justify-content-between justify-content-center">
        <span
          className="p-tag"
          style={{
            backgroundColor: "#183462",
            fontSize: "1.1rem",
            padding: "8px",
          }}
        >
          Resumo do Veículo
        </span>
      </div>
    </Divider>
  );
};
