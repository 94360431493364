import {
  ApiResponseCnhRetrato,
  CnhRetratoProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';
import { formatDefaultValuesToString } from '../../../../utils/ValidationUtils';
import OutPutCard from "../../../../components/outputCard";
import { Divider } from "primereact/divider";
import { openImage } from '../../../../utils/open_image_png_base64';

const labelHabilitacao = (key: string): string => {
  const labels: { [key: string]: string } = {
    formCnh: "Formulário CNH",
    numeroRegistro: "Número de Registro",
    nome: "Nome",
    cpf: "CPF",
    uf: "UF",
    nomeMae: "Nome da Mãe",
    dataEmissao: "Data de Emissão",
    dataValidade: "Data de Validade",
    dataValidadeVencida: "Data de Validade Vencida",
    categoria: "Categoria",
    observacoes: "Observações",
    mensagemCursosEspeciais: "Mensagem de Cursos Especiais",
    possuiRetencaoAdministrativa: "Possui Retenção Administrativa",
    retrato: "Retrato",
    mensagemExameToxicologico: "Mensagem de Exame Toxicológico",
    exameToxicologicoValido: "Exame Toxicológico Válido",
    mostrarAlertaExameToxicologico: "Mostrar Alerta de Exame Toxicológico",
    fraseToxicologicoValido: "Frase sobre Exame Toxicológico Válido",
    cpfDesformatado: "CPF Desformatado",
    ehabilitado: "É Habilitado",
  };

  return labels[key] || key;
};

const getDadosGerais = (dados: any): JSX.Element[] => {
  let dadosFormatados: { label: string; value: any }[] = [];
  
  const renderizarDinamico = (rowData: any, field: string) => {
    const value = rowData[field];
    return formatDefaultValuesToString(value);
  };

  const resultado: JSX.Element[] = [];

  if (dados) {
    dadosFormatados = Object.entries(dados)
    .filter(([key]) => labelHabilitacao(key) !== key)
    .map(([key, value]) => {
      if (key === "retrato") {
        return {
          label: labelHabilitacao(key),
          value: (
            value ? (
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() => openImage(value)}
              >
                Abrir Retrato
              </button>
            ) : (
              "Não disponível"
            )
          ),
        };
      }
      return {
        label: labelHabilitacao(key),
        value: formatDefaultValuesToString(value),
      };
    });
    resultado.push(
      <div key="dadosGerais">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Dados Gerais
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard
                label={item.label}
                value={item.value}
              ></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br> 
      </div>
    );
  }

  if (dados.cursosEspeciais) {
    const fields = Object.keys(dados.cursosEspeciais[0] || {});
    resultado.push(
      <div key="cursosEspeciais">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Cursos Especiais
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.cursosEspeciais}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );  
  }

  if (dados.ocorrencias) {
    const fields = Object.keys(dados.ocorrencias[0] || {});
    resultado.push(
      <div key="ocorrencias">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Ocorrências
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.ocorrencias}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );  
  }

  if (dados.multas) {
    const fields = Object.keys(dados.multas[0] || {});
    resultado.push(
      <div key="multas">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Multas
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.multas}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );  
  }

  if (dados.bloqueios) {
    const fields = Object.keys(dados.bloqueios[0] || {});
    resultado.push(
      <div key="bloqueios">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Bloqueios
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.bloqueios}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );  
  }

  return resultado;
};

const getDadosGeraisArray = (
  data: ApiResponseCnhRetrato
): JSX.Element[] => {

  let resultado: JSX.Element[] = [];

  if (Array.isArray(data.RESPOSTA)) {
    data.RESPOSTA.forEach((item) => {
      resultado = getDadosGerais(item);
    });
  }

  return resultado;
};

export function CnhRetrato({
  data,
}: CnhRetratoProps) {

  const dadosGerais = getDadosGeraisArray(data);

  return (
    <Panel className="p-panel-header-green" header="Automotiva Retrato CNH" style={{ width: '98%' }} toggleable>
      <>
        {dadosGerais.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
        <br></br>
      </>
    </Panel>
  );
}
