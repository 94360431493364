import { Panel } from "primereact/panel";
import { IAutomotivaCSV } from "../../../../interface";
import styles from "./styles.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { openPDF } from "../../../../utils/open_pdf_base64";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const AutomotivaCSVPage = (data: IAutomotivaCSV) => {
  const getValue = (value?: string): string => value || "Não informado";

  const renderPDFLink = (rowData: any) => {
    return rowData.pdf_path ? (
      <button
        style={{
          background: "transparent",
          border: "none",
          textDecoration: "underline",
          cursor: "pointer",
          color: "blue",
        }}
        onClick={() => openPDF(rowData.pdf_path)}
      >
        Abrir PDF
      </button>
    ) : (
      "Não disponível"
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>Dados CSV</p>
        </div>
        <div>
          <div className={styles.listaWrapper}>
            <TextField
              label="Proprietário"
              value={getValue(data?.BIN.Proprietario)}
            />
            <TextField
              label="Tipo de Automóvel"
              value={getValue(data?.BIN.TipoAutomovel)}
            />
            <TextField
              label="Eixo Auxiliar"
              value={getValue(data?.BIN.EixoAuxiliar)}
            />
            <TextField
              label="Nome do Arrendatário"
              value={getValue(data?.BIN.nomeArrendatario)}
            />
            <TextField
              label="Data de Venda"
              value={getValue(data?.BIN.dataVenda)}
            />
            <TextField
              label="Indicador de PIV"
              value={getValue(data?.BIN.indicadorOstentaPIV)}
            />
            <TextField
              label="Data de Emissão CRV"
              value={getValue(data?.BIN.dataEmissaoCrv.formatDate())}
            />
            <TextField
              label="Restrição Judicial"
              value={getValue(data?.BIN.RestricaoJudicial)}
            />
            <TextField
              label="Município/UF"
              value={getValue(data?.BIN.MunicipioUf)}
            />
            <TextField
              label="Tipo de Montagem"
              value={getValue(data?.BIN.TipoMontagem)}
            />
            <TextField
              label="Cilindradas"
              value={getValue(data?.BIN.Cilindradas)}
            />
            <TextField
              label="Nome do Possuidor"
              value={getValue(data?.BIN.PossuidorNome)}
            />
            <TextField
              label="Identificação do Faturado"
              value={getValue(data?.BIN.IdentFaturado)}
            />
            <TextField
              label="Número de Identificação do Arrendatário"
              value={getValue(data?.BIN.numeroIdentificacaoArrendatario)}
            />
            <TextField
              label="Contagem CSV"
              value={getValue(data?.BIN.csv_count.toString())}
            />
            <TextField
              label="Data de Emplacamento"
              value={getValue(data?.BIN.DataEmplacamento)}
            />
            <TextField
              label="Indicador de Alarme"
              value={getValue(data?.BIN.indicadorAlarme)}
            />
            <TextField
              label="Indicador de Leilão"
              value={getValue(data?.BIN.indicadorLeilao)}
            />
            <TextField
              label="Tipo de Documento Faturado"
              value={getValue(data?.BIN.TipoDocFaturado)}
            />
            <TextField
              label="Nome do Comprador"
              value={getValue(data?.BIN.nomeComprador)}
            />
            <TextField
              label="Documento do Possuidor"
              value={getValue(data?.BIN.PossuidorDocumento)}
            />
            <TextField label="PBT" value={getValue(data?.BIN.PBT)} />
            <TextField
              label="Request ID"
              value={getValue(data?.BIN.RequestId)}
            />
            <TextField label="Motor" value={getValue(data?.BIN.Motor)} />
            <TextField
              label="Número de Identificação do Importador"
              value={getValue(data?.BIN.NumIdentImportador)}
            />
            <TextField label="Criado" value={getValue(data?.BIN.created)} />
            <TextField label="Câmbio" value={getValue(data?.BIN.Cambio)} />
            <TextField label="CMT" value={getValue(data?.BIN.CMT)} />
            <TextField
              label="Comunicação de Venda"
              value={getValue(data?.BIN.comunicacao_venda)}
            />
            <TextField label="Chassi" value={getValue(data?.BIN.Chassi)} />
            <TextField
              label="Data de Registro da Venda"
              value={getValue(data?.BIN.dataRegistroVenda)}
            />
            <TextField
              label="Documento do Proprietário"
              value={getValue(data?.BIN.DocProprietario)}
            />
            <TextField label="Espécie" value={getValue(data?.BIN.Especie)} />
            <TextField
              label="Marca/Modelo"
              value={getValue(data?.BIN.MarcaModelo)}
            />
            <TextField label="Potência" value={getValue(data?.BIN.Potencia)} />
            <TextField
              label="Processo Renavam"
              value={getValue(data?.BIN.renavam_process.toString())}
            />
            <TextField
              label="Chassi Remarcado"
              value={getValue(data?.BIN.ChassiRemarcado)}
            />
            <TextField
              label="Capacidade de Carga"
              value={getValue(data?.BIN.CapacidadeDeCarga)}
            />
            <TextField label="Ano" value={getValue(data?.BIN.Ano)} />
            <TextField
              label="Ocorrência"
              value={getValue(data?.BIN.Ocorrencia)}
            />
            <TextField label="Situação" value={getValue(data?.BIN.Situacao)} />
            <TextField label="Renavam" value={getValue(data?.BIN.Renavam)} />
            <TextField
              label="Indicador de Comunicação de Venda"
              value={getValue(data?.BIN.indicadorComunicacaoVenda)}
            />
            <TextField
              label="UF do Faturado"
              value={getValue(data?.BIN.UfFaturado)}
            />
            <TextField label="Órgão RFB" value={getValue(data?.BIN.orgaoRfb)} />
            <TextField
              label="Ano do Último Licenciamento"
              value={getValue(data?.BIN.anoUltimoLicenciamento)}
            />
            <TextField
              label="Tipo de Documento do Importador"
              value={getValue(data?.BIN.TipoDocImportador)}
            />
            <TextField
              label="Número da Carroceria"
              value={getValue(data?.BIN.NumeroCarroceria)}
            />
            <TextField
              label="Restrição 4"
              value={getValue(data?.BIN.Restricao4)}
            />
            <TextField
              label="País de Transferência"
              value={getValue(data?.BIN.paisTransferencia)}
            />
            <TextField
              label="Restrição 2"
              value={getValue(data?.BIN.Restricao2)}
            />
            <TextField
              label="Restrição 3"
              value={getValue(data?.BIN.Restricao3)}
            />
            <TextField
              label="Procedência"
              value={getValue(data?.BIN.Procedencia)}
            />
            <TextField
              label="Categoria"
              value={getValue(data?.BIN.Categoria)}
            />
            <TextField
              label="Restrição 1"
              value={getValue(data?.BIN.Restricao1)}
            />
            <TextField
              label="Quantidade de Passageiros"
              value={getValue(data?.BIN.QtdePassageiros)}
            />
            <TextField
              label="Eixo Traseiro"
              value={getValue(data?.BIN.EixoTraseiro)}
            />
            <TextField
              label="Indicador de Multa Renainf"
              value={getValue(data?.BIN.indicadorMultaRenainf)}
            />
            <TextField
              label="Indicador Recall Montadora"
              value={getValue(data?.BIN.indicadorRecallMontadora)}
            />
            <TextField
              label="Data da Última Atualização"
              value={getValue(data?.BIN.DataUltimaAtualizacao)}
            />
            <TextField label="Eixos" value={getValue(data?.BIN.Eixos)} />
            <TextField label="Cor" value={getValue(data?.BIN.Cor)} />
            <TextField
              label="Carroceria"
              value={getValue(data?.BIN.Carroceria)}
            />
            <TextField label="Número DI" value={getValue(data?.BIN.NumDI)} />
            <TextField
              label="Restrição RFB"
              value={getValue(data?.BIN.restricaoRfb)}
            />
            <TextField
              label="Recall Count"
              value={getValue(data?.BIN.recall_count)}
            />
            <TextField label="Código SRF" value={getValue(data?.BIN.CodSRF)} />
            <TextField
              label="Indicador Recall 1"
              value={getValue(data?.BIN.indicadorRecall1)}
            />
            <TextField
              label="Indicador Recall 2"
              value={getValue(data?.BIN.indicadorRecall2)}
            />
            <TextField
              label="Indicador Recall 3"
              value={getValue(data?.BIN.indicadorRecall3)}
            />
            <TextField
              label="Número de Identificação do Comprador"
              value={getValue(data?.BIN.numeroIdentificacaoComprador)}
            />
            <TextField
              label="Indicador de Pendência de Emissão"
              value={getValue(data?.BIN.indicadorPendenciaEmissao)}
            />
            <TextField
              label="Resultado de Alienação"
              value={getValue(data?.BIN.alienacao_result)}
            />
            <TextField label="Placa" value={getValue(data?.BIN.Placa)} />
            <TextField
              label="Combustível"
              value={getValue(data?.BIN.Combustivel)}
            />
            <TextField
              label="Data de Registro DI"
              value={getValue(data?.BIN.DataRegistroDI)}
            />
            <TextField
              label="Data Limite de Restrição Tributária"
              value={getValue(data?.BIN.DataLimiteRestTrib)}
            />
          </div>
        </div>
      </div>

      <Panel header="CSV">
        <DataTable value={data?.CSV}>
          <Column field="Municipio" header="Município/UF" />
          <Column field="ITL" header="Nome da ITL" />
          <Column field="Chassi" header="Chassi" />
          <Column field="Proprietario" header="Proprietário" />
          <Column field="TipoCsv" header="Tipo de CSV" />
          <Column field="ITL_CNPJ" header="CNPJ da ITL" />
          <Column field="Escopo" header="Escopo" />
          <Column field="IdentificacaoCsv" header="Identificação do CSV" />
          <Column field="DataInspecao" header="Data da Inspeção" />
          <Column header="PDF" body={renderPDFLink} />
        </DataTable>
      </Panel>
    </>
  );
};
