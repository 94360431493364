import { http } from '../../config/axios'

export default function postVeiculoTransferencia(veiculoTransferencia: any): Promise<string> {
    return http
        .post('api/veiculotransferencia', veiculoTransferencia)
        .then((res) => {
            return res.data as string
        })
        .catch((error) => {
            console.error('Erro post veiculo transferencia', error)
            throw Error(error.response.data)
        })
}
