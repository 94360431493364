import { Panel } from "primereact/panel";

import { Indicator } from "./components";
import { Card } from "../../../../components/Card";
import { Identificacao, IGPS353 } from "../../../../interface";
import { transformToArray } from "../../../../utils/transform_to_array";
import "../styles.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const OCORRENCIA_DEBITO: Record<string, string> = {
  TOTALDEVEDOR: "Total de Devedores",
  VALORPRIMEIRODEBITO: "Valor do Primeiro Débito",
  DATAMAIORDEBITO: "Data do Maior Débito",
  TOTALAVALISTA: "Total de Avalistas",
  DATAPRIMEIRODEBITO: "Data do Primeiro Débito",
  VALORACOMULADO: "Valor Acumulado",
  VALORMAIORDEBITO: "Valor do Maior Débito",
};

const IDENTIFICACAO: Identificacao = {
  NOME: "Nome Completo",
  DOCUMENTO: "CPF",
  NOMEMAE: "Nome da Mãe",
  REGIAOCPF: "Região do CPF",
  SITUACAORECEITA: "Situação na Receita Federal",
  DATAEMISSAORG: "Data de Emissão do RG",
  DATANASCIMENTO: "Data de Nascimento",
  ESTADOCIVIL: "Estado Civil",
  NUMERODEPENDENTES: "Número de Dependentes",
  GRAUINSTRUCAO: "Grau de Instrução",
  DATAATUALIZACAO: "Data de Atualização",
  TITULOELEITOR: "Título de Eleitor",
};

export function Gps353(data: IGPS353) {
  const DADOS_OCORRENCIA_DEBITO = transformToArray(
    data?.["RESUMO-OCORRENCIAS-DE-DEBITOS"]
  );

  const DADOS_IDENTIFICACAO = transformToArray(IDENTIFICACAO);

  const PAINEL_CONTROLE_POSITIVO = data?.["PAINEL-CONTROLE-POSITIVO"];
  const DADOS_LOCALIZACAO = data?.["LOCALIZACAO"];

  return (
    <Panel header="Dados GPS-353" style={{ width: "98%" }} toggleable>
      <Panel header="Identificação">
        {DADOS_IDENTIFICACAO && (
          <Card.Container>
            {DADOS_IDENTIFICACAO?.map(({ key }) => {
              if (!IDENTIFICACAO?.[key as keyof Identificacao]) return null;

              return (
                <Card.Item
                  key={key}
                  label={IDENTIFICACAO?.[key as keyof Identificacao]}
                  value={String(
                    data?.["IDENTIFICACAO"]?.[key as keyof Identificacao]
                  ).toUpperCase()}
                />
              );
            })}
          </Card.Container>
        )}

        {!DADOS_IDENTIFICACAO && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Localização">
        {DADOS_LOCALIZACAO && (
          <Card.Container>
            <Card.Item
              label="Nome do Logradouro"
              value={String(
                DADOS_LOCALIZACAO?.TIPOLOGRADOURO +
                  " " +
                  DADOS_LOCALIZACAO?.NOMELOGRADOURO
              )}
            />
            <Card.Item
              label="Complemento"
              value={String(DADOS_LOCALIZACAO?.COMPLEMENTO)}
            />
            <Card.Item
              label="Bairro"
              value={String(DADOS_LOCALIZACAO?.BAIRRO)}
            />
            <Card.Item
              label="Cidade"
              value={String(DADOS_LOCALIZACAO?.CIDADE)}
            />
            <Card.Item
              label="Estado (UF)"
              value={String(DADOS_LOCALIZACAO?.UNIDADEFEDERATIVA)}
            />
            <Card.Item label="CEP" value={String(DADOS_LOCALIZACAO?.CEP)} />
            <Card.Item
              label="Telefone-1"
              value={String(
                DADOS_LOCALIZACAO?.["DDD-1"].replace("0", "") +
                  " " +
                  DADOS_LOCALIZACAO?.["TELEFONE-1"]
              )}
            />
            <Card.Item
              label="Telefone-2"
              value={String(
                DADOS_LOCALIZACAO?.["DDD-2"].replace("0", "") +
                  " " +
                  DADOS_LOCALIZACAO?.["TELEFONE-2"]
              )}
            />
            <Card.Item
              label="Telefone-3"
              value={String(
                DADOS_LOCALIZACAO?.["DDD-3"].replace("0", "") +
                  " " +
                  DADOS_LOCALIZACAO?.["TELEFONE-3"]
              )}
            />
          </Card.Container>
        )}

        {!DADOS_LOCALIZACAO && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel
        header="Indicadores Positivos - Comportamento de Pagamento"
        toggleable
      >
        <h3>Fatura em Atraso</h3>

        <p>
          Indica uma nota para o máximo de dias que <b>1</b> fatura ficou em
          atraso nos últimos <b>6 meses</b>.
        </p>

        <br />

        <Indicator
          value={
            PAINEL_CONTROLE_POSITIVO?.["NOTA-COMPORTAMENTO-FATURA-EM-ATRASO"]
              ?.NOTA
          }
        />

        <br />

        <p>
          Quanto mais próximo o consumidor estiver da faixa <b>A</b>, menos dias
          a fatura está ou ficou em atraso e quanto mais próximo da faixa{" "}
          <b>E</b>, mais dias a fatura ficou em atraso.
        </p>
      </Panel>

      <br />
      <br />

      <Panel
        header="Indicadores Positivos - Comportamento de Consumo"
        toggleable
      >
        <h3>Contratos Recentes</h3>

        <p>
          Indica uma nota para o nível de comprometimento de crédito do
          consumidor, com base na quantidade de contratos fechados nos últimos{" "}
          <b>6 meses</b>.
        </p>

        <br />

        <Indicator
          value={
            PAINEL_CONTROLE_POSITIVO?.["NOTA-COMPORTAMENTO-CONTRATOS-RECENTES"]
              ?.NOTA
          }
        />

        <br />

        <p>
          Quanto mais próximo da faixa <b>A</b>, menos contratos foram fechados
          e quanto mais perto da faixa <b>E</b>, mais contratos foram fechados
          nos últimos 6 meses.
        </p>
      </Panel>

      <br />
      <br />

      <Panel header="Ocorrências de Débitos" toggleable>
        {DADOS_OCORRENCIA_DEBITO ? (
          <Card.Container>
            {DADOS_OCORRENCIA_DEBITO?.map(({ key, value }) => {
              if (!OCORRENCIA_DEBITO[key]) return null;

              return (
                <Card.Item
                  key={key}
                  label={OCORRENCIA_DEBITO[key]}
                  value={String(value).toUpperCase()}
                />
              );
            })}
          </Card.Container>
        ) : (
          <p>Nada consta</p>
        )}
      </Panel>

      <br />
      <br />

      <Panel header="Consultas Anteriores" toggleable>
        {data?.["CONSULTAS-ANTERIORES"] ? (
          <DataTable value={[data?.["CONSULTAS-ANTERIORES"]]}>
            <Column header="Data" field="DATA" />
            <Column header="Empresa" field="INFORMANTE" />
            <Column header="Tipo Ocorrência" field="TIPOOCORRENCIA" />
          </DataTable>
        ) : (
          <p>Nada consta</p>
        )}
      </Panel>

      <br />
      <br />

      <Panel header="Registro de Débitos" toggleable>
        {data?.["DEBITOS"] ? (
          <DataTable value={[data?.["DEBITOS"]]}>
            <Column header="Ocorrência" field="DATAOCORRENCIA" />
            <Column header="Disponibilização" field="DATADISPONIBILIZACAO" />
            <Column header="Informante" field="INFORMANTE" />
            <Column header="Tipo Ocorrência" field="TIPOOCORRENCIA" />
            <Column header="Contrato" field="CONTRATO" />
            <Column header="Cidade" field="CIDADE" />
            <Column header="UF" field="UF" />
            <Column header="Situação" field="SITUACAO" />
            <Column header="Valor" field="VALOR" />
          </DataTable>
        ) : (
          <p>Nada consta</p>
        )}
      </Panel>

      <br />
      <br />

      <Panel header="Participações em Empresas" toggleable>
        {data?.["PARTICIPACOES-DO-DOCUMENTO-CONSULTADO"] ? (
          <DataTable value={[data?.["PARTICIPACOES-DO-DOCUMENTO-CONSULTADO"]]}>
            <Column header="CNPJ" field="NUMERODOCUMENTOB" />
            <Column header="Razão Social" field="RAZAOSOCIAL" />
            <Column header="Tipo" field="TIPOSOCIO" />
            <Column header="% de Participação" field="VALOREMPERCENTUAL" />
            <Column header="Data de Entrada" field="DATADEENTRADA" />
          </DataTable>
        ) : (
          <p>Nada consta</p>
        )}
      </Panel>
    </Panel>
  );
}
