import { http } from '../../config/axios'

export default function getVeiculoTransferenciaTipoSolcitante(): Promise<any> {
    return http
        .get('api/veiculotransferencia/tipossolicitante/')
        .then((res) => {
            return res.data as any
        })
        .catch((error) => {
            console.error('Erro getVeiculoTransferenciaTipoSolcitante', error)
            throw Error(error.response.data)
        })
}
