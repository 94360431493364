import { http } from '../../config/axios'

export default function getFiltroPermissao(id:String): Promise<any> {
  return http
    .get('api/permissao/filtros/' + id)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.error('Erro getFiltroPermissao', error)
      throw Error(error.response.data)
    })
}
