import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { TextField } from "../../../../components/TextField";
import {
  ApiResponseAutomotivaBaseEstadual,
  AutomotivaBaseEstadualProps,
} from "./types";
import styles from "./styles.module.css";

const formatarDadosComArray = (
  data: ApiResponseAutomotivaBaseEstadual
): JSX.Element[] => {
  const resultado: JSX.Element[] = [];
  if (data.DETALHAMENTO_DAS_MULTAS) {
    const renderizarArray = (rowData: any, field: string) => {
      const arrayData = rowData[field];
      return arrayData && Array.isArray(arrayData) && arrayData.length > 0
        ? arrayData
            .filter((item) => item !== null && item.trim() !== "")
            .join(", ")
        : "-";
    };

    resultado.push(
      <DataTable
        size="normal"
        stripedRows
        value={Object.values(data.DETALHAMENTO_DAS_MULTAS)}
      >
        <Column field="AIT" header="AIT" />
        <Column field="DATA_HORA" header="Data" />
        <Column field="DATA_HORA_APRESENTACAO" header="Data Apresentação" />
        <Column field="DEFESA_DATA" header="Defesa Data" />
        <Column field="DEFESA_RESULTADO" header="Defesa Resultado" />
        <Column
          field="GUIA"
          header="Guia"
          body={(rowData) => renderizarArray(rowData, "GUIA")}
        />
        <Column
          field="MUNICIPIO"
          header="Municipio"
          body={(rowData) => renderizarArray(rowData, "MUNICIPIO")}
        />
        <Column field="INFRACAO" header="Infração" />
        <Column field="LOCAL" header="Local" />
        <Column field="ORGAO_AUTUADOR" header="Órgão Autuador" />
        <Column field="RECEITA_LOCAL" header="Receita Local" />
        <Column field="RENAINF" header="Informação Renavam" />
        <Column field="RESURSO" header="Recurso" />
        <Column field="RESURSO_RESULTADO" header="Resultado Recurso" />
        <Column field="SITUACAO" header="Situação" />
        <Column field="TIPIFICACAO" header="Tipificação" />
        <Column field="UF_ORGAO_AUTUADOR" header="UF Órgão Autuador" />
        <Column field="VALOR" header="Valor" />
        <Column field="VALOR_CORRIGIDO" header="Valor Corrigido" />
        <Column field="VALOR_DESCONTO" header="Valor Desconto" />
        <Column field="VALOR_PAGO" header="Valor Pago" />
        <Column field="VENCIMENTO" header="Vencimento" />
        <Column field="PLACA" header="Placa" />
        <Column field="REVANAM" header="Renavam" />
      </DataTable>
    );
  }

  return resultado;
};

export function AutomotivaBaseEstadual({ data }: AutomotivaBaseEstadualProps) {
  const detalhamentoMultas = formatarDadosComArray(data);

  const getValue = (value?: string): string => value || "NADA CONSTA";

  return (
    <Panel
      className="p-panel-header-green"
      header="Automotiva Base Estadual"
      style={{ width: "98%" }}
    >
      <Panel header="Dados Gerais" toggleable>
        {!data?.RETORNO?.MSG_ERRO && (
          <div className={styles.listaWrapper}>
            {Object.entries(data?.RETORNO)?.map((item, index) => (
              <React.Fragment key={item[0] + index}>
                <TextField
                  label={formData[0]?.[item[0]]}
                  value={getValue(item[1]?.toString())}
                />
              </React.Fragment>
            ))}
          </div>
        )}

        {data?.RETORNO?.MSG_ERRO && <p>Mensagem: {data?.RETORNO?.MSG_ERRO}</p>}
      </Panel>

      <br></br>

      <Panel header="Detalhamento das Multas" toggleable>
        {detalhamentoMultas.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
      </Panel>
    </Panel>
  );
}

const formData: Record<string, string>[] = [
  {
    CHASSI: "Chassi",
    CILINDRADA: "Cilindrada (cm³)",
    ESPECIE: "Espécie do Veículo",
    COMBUSTIVEL: "Tipo de Combustível",
    POTENCIA: "Potência (cv)",
    MARCAMODELOCOMPLETO: "Marca e Modelo Completo",
    MARCA: "Marca",
    PLACA: "Placa",
    VEIANOFABR: "Ano de Fabricação",
    VEIANOMODELO: "Ano do Modelo",
    CAPACIDADEPASSAG: "Capacidade de Passageiros",
    TIPO: "Tipo do Veículo",
    MUNICIPIO: "Município de Registro",
    UF: "Estado (UF)",
    VEIPROCEDENCIA: "Procedência do Veículo",
    MOTOR: "Motor",
    COR: "Cor do Veículo",
    RENAVAM: "Renavam",
    CCOMUNICACAOVENDA: "Comunicado de Venda",
    DATAVENDA: "Data da Venda",
    CMT: "Peso Total (CMT) (kg)",
    DATAULTIMAATUALIZACAO: "Data da Última Atualização",
    TIPODOCUMENTOFATURADO: "Tipo do Documento Faturado",
    UFFATURADO: "UF do Documento Faturado",
    CPFCNPJFATURADO: "Documento do Faturado (CPF/CNPJ)",
    CAIXACAMBIO: "Tipo de Caixa de Câmbio",
    CAPACIDADECARGA: "Capacidade de Carga (kg)",
    PBT: "Peso Bruto Total (kg)",
    EIXOS: "Quantidade de Eixos",
    TIPOREMARCACAOCHASSI: "Situação do Chassi",
    RESRENAJUD: "Situação Renajud",
    RESTRICAOFINAN: "Restrição Financeira",
    CPF_CNPJ_PROPRIETARIO: "Documento do Proprietário (CPF/CNPJ)",
    PRONOME: "Proprietário",
    TIPODOCUMENTOPROPRIETARIO: "Tipo de Documento do Proprietário",
    DATAEMISSAOCRV: "Data de Emissão do CRV",
    DPVAT: "Valor DPVAT (R$)",
    RESTRIBUTARIA: "Restrição Tributária",
    EXISTEDEBITODELICENCIAMENTO: "Débito de Licenciamento",
    EXISTEDEBITODELICENCIAMENTOVL: "Valor do Débito de Licenciamento (R$)",
    EXISTEDEBITODEIPVA: "Débito de IPVA",
    DEBIPVA: "Valor do IPVA (R$)",
    EXISTEDEBITOMULTA: "Débito de Multas",
    VALORTOTALDEBITOMULTA: "Valor Total de Multas (R$)",
    TIPODOCCOMPRADOR: "Tipo de Documento do Comprador",
    VEICATEGORIA: "Categoria do Veículo",
    TIPODOCUMENTOIMPORTADORA: "Tipo de Documento da Importadora",
    TEMPOEXECUCAO: "Tempo de Execução (s)",
    SITUACAOVEICULO: "Situação do Veículo",
    RESTRICAONOMEAGENTE: "Nome do Agente Restrição",
    RESTRICAODATAINCLUSAO: "Data de Inclusão da Restrição",
    RESTRICAOCPFCNPJARRENDATAR: "Documento do Arrendatário (CPF/CNPJ)",
    RESTRICAOARRENDATARIO: "Arrendatário",
    RESJUDICIAL: "Situação Judicial",
    RESGUINCHO: "Situação de Guincho",
    RESFURTO: "Situação de Furto",
    RESAMBIENTAL: "Situação Ambiental",
    RESADMINISTRATIVA: "Situação Administrativa",
    REDUNDANCIA: "Redundância",
    PROTOCOLODETRAN: "Protocolo do DETRAN",
    PRONOMEANTERIOR: "Nome Anterior do Proprietário",
    OUTRAS_RESTRICOES_04: "Outras Restrições (04)",
    OUTRAS_RESTRICOES_03: "Outras Restrições (03)",
    OUTRAS_RESTRICOES_02: "Outras Restrições (02)",
    OUTRAS_RESTRICOES_01: "Outras Restrições (01)",
    NUMERO_TERCEIROEIXO: "Número do Terceiro Eixo",
    NUMERO_EIXOTRASEIRODIF: "Número do Eixo Traseiro Diferencial",
    NUMERO_CARROCERIA: "Número da Carroceria",
    NUMERO_CAIXACAMBIO: "Número da Caixa de Câmbio",
    NUMEROCONTRATOFINANCEIRA: "Número do Contrato Financeiro",
    NOTAFISCAL: "Nota Fiscal",
    MSG_ERRO: "Mensagem de Erro",
    LICEXELIC: "Licenciamento Extra Licenciamento",
    LICDATA: "Data do Licenciamento",
    INTENCAORESTRICAOFINAN: "Intenção de Restrição de Financiamento",
    INTENCAONOMEFINANC: "Nome do Intencionado Financiamento",
    INTENCAONOMEAGENTE: "Nome do Agente na Intenção",
    INTENCAODATAINCLUSAO: "Data de Inclusão da Intenção",
    INTENCAOCPFCNPJFINANC: "Documento Financiamento Intencionado (CPF/CNPJ)",
    INSPECAOSTATUS: "Status da Inspeção",
    INSPECAOSELO: "Selo da Inspeção",
    INSPECAODATA: "Data da Inspeção",
    INSPECAOCENTRO: "Centro de Inspeção",
    INSPECAOANO: "Ano da Inspeção",
    EXISTE_ERRO: "Existe Erro?",
    EXISTEDEBITODEDPVAT: "Débito de DPVAT",
    DEBRENAINF: "Débito Renainf",
    DEBPOLRODFED: "Débito na Polícia Rodoviária Federal",
    DEBMUNICIPAIS: "Débito de Tributos Municipais (R$)",
    DEBDETRAN: "Débito no Detran (R$)",
    DEBDERSA: "Débito no Dersa (R$)",
    DEBDER: "Débito no DER (R$)",
    DEBCETESB: "Débito na Cetesb (R$)",
    DATAVIGENCIACONTRATOFINANCEIRA: "Data de Vigência do Contrato Financeiro",
    DATALIMITERESTRICAOTRIBUTARIA: "Data Limite de Restrição Tributária",
    DATAINCLUSAOINTENCAOTROCAFINANCEIRA:
      "Data de Inclusão na Troca Financeira Intencionada",
    CPFCNPJCOMPRADOR: "Documento do Comprador (CPF/CNPJ)",
    CODIGOTIPO: "Código do Tipo de Veículo",
    CODIGOMARCA: "Código da Marca",
    CODIGOFINANCEIRA: "Código da Financeira",
    CODIGOCOR: "Código da Cor",
    CODIGOCATEGORIA: "Código da Categoria",
    CODIGOAGENTEFINANCEIRO: "Código do Agente Financeiro",
    CHAVERETORNO: "Chave de Retorno",
    CCOMUNICINCLUSAO: "Comunicação de Inclusão",
  },
];
