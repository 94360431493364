import { Panel } from "primereact/panel";
import React from "react";
import { Card } from "../../../../components/Card";
import Divider from "../../../../components/menu/Divider";
import { IAutomotivaCsvCompleto } from "../../../../interface/AutomotivaCsvCompleto";
import { openPDF } from "../../../../utils/open_pdf_base64";

export const AutomotivaCsvCompletoPage = (data: IAutomotivaCsvCompleto) => {
  const { veiculo, renainfDetalhados, comunicacaoVenda, laudos } = data;

  const getValue = (value?: string | number | boolean): string => {
    if (value === undefined || value === null) return "Não informado";
    if (typeof value === "boolean") return value ? "Sim" : "Não";
    return value.toString();
  };

  return (
    <Panel header="Consulta CSV Completo" toggleable>
      <Panel header="Dados do Veículo" toggleable>
        {veiculo && (
          <Card.Container>
            <Card.Item label="Placa" value={getValue(veiculo?.placa)} />
            <Card.Item label="Chassi" value={getValue(veiculo?.chassi)} />
            <Card.Item
              label="Renavam"
              value={getValue(veiculo?.codigoRenavam)}
            />

            <Card.Item
              label="Nome"
              value={getValue(veiculo?.nomeProprietario)}
            />
            <Card.Item
              label="Tipo"
              value={getValue(veiculo?.tipoProprietario.descricao)}
            />
            <Card.Item
              label="Documento"
              value={getValue(veiculo?.numeroIdentificacaoProprietario)}
            />

            <Card.Item
              label="Nome"
              value={getValue(veiculo?.possuidor?.nome)}
            />
            <Card.Item
              label="Documento"
              value={getValue(veiculo?.possuidor?.numeroDocumento)}
            />
            <Card.Item
              label="Origem"
              value={getValue(veiculo?.possuidor?.origem)}
            />

            <Card.Item
              label="Ano de Fabricação"
              value={getValue(veiculo?.anoFabricacao)}
            />
            <Card.Item
              label="Ano do Modelo"
              value={getValue(veiculo?.anoModelo)}
            />
            <Card.Item
              label="Marca/Modelo"
              value={getValue(veiculo?.marcaModelo.descricao)}
            />
            <Card.Item
              label="Categoria"
              value={getValue(veiculo?.categoria.descricao)}
            />
            <Card.Item
              label="Espécie"
              value={getValue(veiculo?.especie.descricao)}
            />
            <Card.Item
              label="Tipo"
              value={getValue(veiculo?.tipoVeiculo?.descricao)}
            />
            <Card.Item
              label="Procedência"
              value={getValue(veiculo?.procedencia)}
            />
            <Card.Item label="Cor" value={getValue(veiculo?.cor.descricao)} />
            <Card.Item label="Lotação" value={getValue(veiculo?.lotacao)} />
            <Card.Item
              label="PBT"
              value={`${getValue(veiculo?.pbt)} toneladas`}
            />
            <Card.Item
              label="Potência"
              value={`${getValue(veiculo?.potencia)} cavalos`}
            />
            <Card.Item
              label="Cilindradas"
              value={`${getValue(veiculo?.cilindradas)} cc`}
            />
            <Card.Item
              label="Tipo de Combustível"
              value={getValue(veiculo?.combustivel.descricao)}
            />
            <Card.Item
              label="Município de Emplacamento"
              value={getValue(veiculo?.municipioEmplacamento.descricao)}
            />
            <Card.Item
              label="Estado (UF)"
              value={getValue(veiculo?.ufJurisdicao)}
            />

            <Card.Item label="Situação" value={getValue(veiculo?.situacao)} />
            <Card.Item
              label="Comunicação de Venda"
              value={getValue(veiculo?.indicadorComunicacaoVenda)}
            />
            <Card.Item
              label="Leilão"
              value={getValue(veiculo?.indicadorLeilao)}
            />
            <Card.Item
              label="Multa Renainf"
              value={getValue(veiculo?.indicadorMultaRenainf)}
            />

            <Card.Item
              label="Renajud"
              value={getValue(veiculo?.indicadorRestricaoRenajud)}
            />
            {[
              veiculo?.restricao1,
              veiculo?.restricao2,
              veiculo?.restricao3,
              veiculo?.restricao4,
            ]
              .filter((r) => r.descricao !== "SEM RESTRICAO")
              .map((restricao, index) => (
                <Card.Item
                  key={index}
                  label={`Restrição ${index + 1}`}
                  value={getValue(restricao.descricao)}
                />
              ))}

            <Card.Item
              label="Permite Baixar CRLV Digital"
              value={getValue(veiculo?.permiteBaixarCrvlDigital)}
            />
          </Card.Container>
        )}

        {!veiculo && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Comunicado de Venda" toggleable>
        {comunicacaoVenda && (
          <Card.Container>
            <Card.Item
              label="Placa"
              value={getValue(comunicacaoVenda?.placa)}
            />
            <Card.Item
              label="Renavam"
              value={getValue(comunicacaoVenda?.renavam)}
            />
            <Card.Item
              label="Proprietário Anterior (Documento)"
              value={getValue(
                comunicacaoVenda?.numeroIdentificacaoProprietario
              )}
            />
            <Card.Item
              label="Data de Venda"
              value={comunicacaoVenda?.dataVenda.formatDate()}
            />
            <Card.Item
              label="Data de Registro da Venda"
              value={comunicacaoVenda?.dataRegistroVenda.formatDate()}
            />
            <Card.Item
              label="Comprador (Nome)"
              value={getValue(comunicacaoVenda?.nomeComprador)}
            />
            <Card.Item
              label="Comprador (Documento)"
              value={getValue(comunicacaoVenda?.numeroIdentificacaoComprador)}
            />
          </Card.Container>
        )}

        {!comunicacaoVenda && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Lista de CSV" toggleable>
        {veiculo?.listaCsv?.map((item, index) => (
          <Card.Container key={item.identificacaoCsv}>
            <Card.Item
              label={`${index + 1} - Identificação CSV`}
              value={item.identificacaoCsv}
            />
          </Card.Container>
        ))}

        {veiculo?.listaCsv?.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Laudos" toggleable>
        {laudos?.map((item, index) => (
          <Card.Container key={item.identificacaoCsv}>
            <Card.Item
              label={`${index + 1} - Identificação CSV`}
              value={
                <>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "blue",
                      fontSize: "16px",
                    }}
                    onClick={() => openPDF(item?.identificadorCsv)}
                  >
                    {item?.identificacaoCsv}
                  </button>
                </>
              }
            />
          </Card.Container>
        ))}

        {laudos?.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Multas" toggleable>
        {renainfDetalhados?.multas?.map((item, index) => (
          <React.Fragment key={item?.codigoRenainf + index}>
            <Card.Container>
              <Card.Item label="Placa" value={getValue(item?.placa)} />
              <Card.Item label="Renavam" value={getValue(item?.renavam)} />
              <Card.Item
                label="Proprietário"
                value={getValue(item?.numeroIdentificacaoProprietario)}
              />
              <Card.Item
                label="Órgão Autuador"
                value={`${getValue(item?.descricaoOrgaoAutuador)} (${getValue(
                  item?.codigoOrgaoAutuador
                )})`}
              />
              <Card.Item
                label="Auto de Infração"
                value={getValue(item?.numeroAutoInfracao)}
              />
              <Card.Item
                label="Código da Infração"
                value={getValue(item?.codigoInfracao)}
              />
              <Card.Item
                label="Descrição da Infração"
                value={getValue(item?.descricaoInfracao)}
              />
              <Card.Item
                label="Local da Ocorrência"
                value={getValue(item?.localOcorrenciaInfracao)}
              />
              <Card.Item
                label="Data e Hora da Infração"
                value={`${item?.dataInfracao.formatDate()} às ${getValue(
                  item?.horaInfracao
                )}`}
              />
              <Card.Item
                label="Município da Infração"
                value={`${getValue(
                  item?.descricaoMunicipioInfracao
                )} (${getValue(item?.ufInfracao)})`}
              />
              <Card.Item
                label="Código Renainf"
                value={getValue(item?.codigoRenainf)}
              />
              <Card.Item
                label="Valor da multa"
                value={`R$ ${getValue(item?.valorMulta?.toFixed(2))}`}
              />
              <Card.Item
                label="Data de Vencimento"
                value={item?.dataVencimento.formatDate()}
              />
              <Card.Item
                label="Data Limite para Defesa"
                value={item?.dataLimiteDefesaPrevia.formatDate()}
              />
              <Card.Item
                label="Notificação da Autuação"
                value={item?.dataNotificacaoAutuacao.formatDate()}
              />
              <Card.Item
                label="Notificação da Penalidade"
                value={item?.dataNotificacaoPenalidade.formatDate()}
              />
              <Card.Item label="Situação" value={getValue(item?.situacao)} />
            </Card.Container>
            <br />
            {index < renainfDetalhados.multas.length - 1 && (
              <>
                <Divider />
                <br />
              </>
            )}
          </React.Fragment>
        ))}

        {!renainfDetalhados && <p>Nada consta</p>}
      </Panel>
    </Panel>
  );
};
