import { Panel } from "primereact/panel";
import { IAutomotivaPrecificacao } from "../../../../interface/AutomotivaPrecificacao";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const AutomotivaPrecificacaoPage = (data: IAutomotivaPrecificacao) => {
  const { OCORRENCIAS, STATUS_RETORNO } = data?.PRECIFICADOR;
  return (
    <Panel
      className="p-panel-header-green"
      header="Dados Automotiva Precificador"
      style={{ width: "98%" }}
      toggleable
    >
      {OCORRENCIAS && (
        <DataTable value={OCORRENCIAS}>
          <Column header="Fabricante/Modelo" body={renderFabModel} />
          <Column header="Ano do Modelo" body={renderYearModel} />
          <Column header="Preço (R$)" body={renderPrice} />
          <Column header="Preço Médio (R$)" body={renderPriceAverage} />
          <Column header="Código" body={renderCode} />
          <Column header="Combustível" body={renderFuel} />
          <Column header="Chassi" body={renderChassi} />
          <Column header="Informante" body={renderInformant} />
          <Column header="Vigência" body={renderDuration} />
        </DataTable>
      )}

      {!OCORRENCIAS && STATUS_RETORNO && <p>{STATUS_RETORNO?.DESCRICAO}</p>}
    </Panel>
  );
};

const renderFabModel = (data: any) => {
  return data?.FABRICANTE_MODELO ? data?.FABRICANTE_MODELO : "Não informado";
};

const renderYearModel = (data: any) => {
  return data?.ANO_MODELO ? data?.ANO_MODELO : "Não informado";
};

const renderPrice = (data: any) => {
  return data?.PRECO ? data?.PRECO : "Não informado";
};

const renderPriceAverage = (data: any) => {
  return data?.PRECO_MEDIO ? data?.PRECO_MEDIO : "Não informado";
};

const renderCode = (data: any) => {
  return data?.CODIGO ? data?.CODIGO : "Não informado";
};

const renderFuel = (data: any) => {
  return data?.COMBUSTIVEL ? data?.COMBUSTIVEL : "Não informado";
};

const renderChassi = (data: any) => {
  return data?.CHASSI ? data?.CHASSI : "Não informado";
};

const renderInformant = (data: any) => {
  return data?.INFORMANTE ? data?.INFORMANTE : "Não informado";
};

const renderDuration = (data: any) => {
  return data?.VIGENCIA ? data?.VIGENCIA : "Não informado";
};
