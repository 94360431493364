import { useMemo } from "react";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import menuItems from "../../content/menuItems";
import usePopstate from "../../hooks/usePopstate";
import MenuItem from "./MenuItem";
import './Sidebar.css';
import { MenuItemInterface } from "../../interface";
import MenuItemTree from "./MenuItemTree";

const Sidebar = () => {
  const { currentPath } = usePopstate();
  const dividedMenuItems = ["dispatchers", "carriers", "configurations"];
  const {state} = useGlobalState();

  const handleIsActive = (url: string) => {
   //const reg = new RegExp(currentPath.split('\/')[currentPath.split('\/').length - 1], g);
   const isActive = currentPath.split('\/')[currentPath.split('\/').length - 1] === url.split('\/')[url.split('\/').length - 1];
   return isActive;
  };

  const userPermissions = useMemo(() => { 
    const result : string[] = state.user.funcoes;
    return result;
  }, []);

  const hasPermission = (permissionName:string) => {
    const has = userPermissions.includes(permissionName);
    
    return has;
  };

  const filteredSUBMenuItems = (itemSub:MenuItemInterface) => {
    if(itemSub.itens){
      let hasDivider = false;
      const filtered = itemSub.itens
      .filter((item:MenuItemInterface) => !item.permission || hasPermission(item.permission))
      .map((item:MenuItemInterface) => {
        if (dividedMenuItems.includes(item.label) && !hasDivider) {
          item.hasDivider = true;
          hasDivider = true;
        }
        filteredSUBMenuItems(item)
        return item;
      });
      
      itemSub.itens = filtered;
    }
    
  }

  const filteredMenuItems = useMemo(() => {
    let hasDivider = false;
    const filtered = menuItems
      .filter((item) => !item.permission || hasPermission(item.permission))
      .map((item) => {
        if (dividedMenuItems.includes(item.label) && !hasDivider) {
          item.hasDivider = true;
          hasDivider = true;
        }
        filteredSUBMenuItems(item)
        return item;
      });
     
    return filtered;
  }, [userPermissions]);

  

  return (
    <div
      key={'div_menu'}
      id="interface-sidebar"
      className={!!state?.sidebarOpen ? "opened" : "closed"}
    >
      <menu>
        
        {filteredMenuItems.map((item:MenuItemInterface) => (
         <div key={'div_menu_' + item.permission}>
            {item.itens ? (<MenuItemTree
            key={item.url}
            isActive={handleIsActive(item.url)}
            {...{ item }}
          />) : (<MenuItem
            key={item.url}
            isActive={handleIsActive(item.url)}
            {...{ item }}
          />)}
        </div>
        ))}
      </menu>
    </div>
  );
};

export default Sidebar;
