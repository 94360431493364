import { Panel } from "primereact/panel";
import { TextField } from "../../../components/TextField";
import { openPDF } from "../../../utils/open_pdf_base64"; 

export default function CRLVSimples({
    crlvSimples
  }: {
    crlvSimples: any
  }) {
    return (
        <Panel header="Dados CRLV" style={{ width: "98%" }}>
          {!crlvSimples?.MENSAGEM && (
            <TextField
              label="PDF CRLV"
              value={
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={() => openPDF(crlvSimples?.arquivo)}
                >
                  Visualizar arquivo pdf
                </button>
              }
            />
          )}

          {crlvSimples?.MENSAGEM && (
            <TextField
              label="Mensagem"
              value={crlvSimples?.MENSAGEM}
            />
          )}
        </Panel>
    )
}