import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import ParametroAplicacaoList from './ParametroAplicacaoList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import ParametroAplicacaoCadastrar from './ParametroAplicacaoCadastrar';
import { Messages } from 'primereact/messages';
import postParametroAplicacao from '../../service/postParametroAplicacao';
import putParametroAplicacao from '../../service/putParametroAplicacao';

export default function ParametroAplicacaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [parametroAplicacao, setParametroAplicacao] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveParametroAplicacao = (parametroAplicacao:any) => {
    if(parametroAplicacao.id){
      putParametroAplicacao(parametroAplicacao).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setParametroAplicacao(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postParametroAplicacao(parametroAplicacao).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setParametroAplicacao(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setParametroAplicacao({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-cog" style={{ fontSize: '2.0rem' }}></i> {t('parametroAplicacao')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!parametroAplicacao && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <ParametroAplicacaoList onEdit={(parametroAplicacao:any) => { setParametroAplicacao(parametroAplicacao);}}></ParametroAplicacaoList>
          </>
        )}
        {parametroAplicacao && (
          <>
           <ParametroAplicacaoCadastrar parametroAplicacao={parametroAplicacao} onSave={saveParametroAplicacao} onClose={() => {setParametroAplicacao(null)}}></ParametroAplicacaoCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
