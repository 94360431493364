import { IConsultaBaseLeilao } from "../../../../interface/ConsultaBaseLeilao";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const ConsultaBaseLeilao = (response: IConsultaBaseLeilao) => {
  const { LEILAOXML_1 } = response.RESPOSTA;
  const getValue = (value?: string): string => value || "Não informado";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Dados Base Leilão</p>
      </div>

      <div className={styles.listaWrapper}>
        {LEILAOXML_1 ? (
          <>
            <TextField label="Marca" value={getValue(LEILAOXML_1?.MARCA)} />
            <TextField label="Modelo" value={getValue(LEILAOXML_1?.MODELO)} />
            <TextField
              label="Ano/Modelo"
              value={getValue(LEILAOXML_1?.ANO_MODELO)}
            />
            <TextField label="Cor" value={getValue(LEILAOXML_1?.COR)} />
            <TextField label="Placa" value={getValue(LEILAOXML_1?.PLACA)} />
            <TextField label="Chassi" value={getValue(LEILAOXML_1?.CHASSI)} />
            <TextField label="Renavam" value={getValue(LEILAOXML_1?.RENAVAM)} />
            <TextField
              label="Combustível"
              value={getValue(LEILAOXML_1?.COMBUSTIVEL)}
            />
            <TextField
              label="Data do Leilão"
              value={getValue(LEILAOXML_1?.DATA)}
            />
            <TextField label="Lote" value={getValue(LEILAOXML_1?.LOTE)} />
            <TextField
              label="Categoria"
              value={getValue(LEILAOXML_1?.CATEGORIA)}
            />
            <TextField
              label="Condição Geral"
              value={getValue(LEILAOXML_1?.CONDICAO_GERAL)}
            />
            <TextField
              label="Condição do Motor"
              value={getValue(LEILAOXML_1?.COND_MOTOR)}
            />
            <TextField
              label="Condição do Câmbio"
              value={getValue(LEILAOXML_1?.COND_CAMBIO)}
            />
            <TextField
              label="Condição do Chassi"
              value={getValue(LEILAOXML_1?.COND_CHASSI)}
            />
            <TextField
              label="Quilometragem (KM)"
              value={getValue(LEILAOXML_1?.KM)}
            />
            <TextField label="Avarias" value={getValue(LEILAOXML_1?.AVARIAS)} />
            <TextField
              label="Comitente"
              value={getValue(LEILAOXML_1?.COMITENTE)}
            />
            <TextField
              label="Leiloeiro"
              value={getValue(LEILAOXML_1?.LEILOEIRO)}
            />
          </>
        ) : (
          <p>NENHUM REGISTRO ENCONTRADO PARA O DADO INFORMADO</p>
        )}
      </div>
    </div>
  );
};
