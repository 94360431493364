import {
  ApiResponseCNDT,
  DadosFormatados,
  CNDTProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDefaultValuesToString } from '../../../../utils/ValidationUtils';
import { openPDF } from '../../../../utils/open_pdf_base64';
import React from "react";

const labelDadosGerais = (key: string): string => {
  const labels: { [key: string]: string } = {
    certidao_codigo: "Certidão Código:",
    consta: "Consta:",
    conseguiu_emitir_certidao_negativa: "Conseguiu Emitir Certidão Negativa:",
    mensagem: "Mensagem:",
    normalizado_cnpj: "CNPJ Normalizado:",
    nome: "Nome:",
    certidao: "Certidão:",
    cnpj: "CNPJ:",
    validade_data: "Data de Validade:",
    normalizado_validade: "Validade Normalizada:",
    validade: "Validade:",
    emissao_data: "Data de Emissão:",
    normalizado_cpf: "CPF Normalizado:",
    site_receipt: "Comprovante do Site:",
    expedicao: "Data de Expedição:",
    cpf: "CPF:",
    normalizado_expedicao: "Expedição Normalizada:",
    total_de_processos: "Total de Processos:",
  };

  return labels[key] || key;
};

const formatarDadosGerais = (dados: any): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosGerais(key) !== key).map(([key, value]) => ({
    label: labelDadosGerais(key),
    value: formatDefaultValuesToString(value as string),
  }));

  const resultado: JSX.Element[] = [];
  if (dados.processos_encontrados) {
    resultado.push(
      <DataTable  size="normal" stripedRows value={dados.processos_encontrados.map((processo: any) => ({ processo }))}>
        <Column field="processo" header="Processos Encontrados"/>
      </DataTable>
    );
  }

  return {
    dados: dadosFormatados,
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseCNDT
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (Array.isArray(data.data)) {
    data.data.forEach((item) => {
      resultado.push(formatarDadosGerais(item));
    });
  }

  return resultado;
};

export function CNDT({
  data,
}: CNDTProps) {
  const dados = getDadosGerais(data);

  const renderValueColumn = (rowData: any) => {
    if (rowData.label === "Comprovante do Site:") {
      return rowData.value ? (
        <button
          style={{
            background: "transparent",
            border: "none",
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
          }}
          onClick={() => openPDF(rowData.value)}
        >
          Abrir PDF
        </button>
      ) : (
        "Não disponível"
      );
    }
    return <span>{rowData.value}</span>;
  };

  return (
    <Panel header="CNDT" style={{ width: "98%" }}>
      {dados.map(({ dados, array }, index) => (
        <React.Fragment key={index}>
            <DataTable
              showGridlines
              size="normal"
              stripedRows
              value={dados}
            >
              <Column className="font-bold" field="label" header="Descrição" />
              <Column body={renderValueColumn} header="Valor" />
            </DataTable>
            <br />
            {array.map((tabela, idx) => (
              <div key={idx}>{tabela}</div>
            ))}
          <br />
        </React.Fragment>
      ))}
    </Panel>
  );
}
