import { Panel } from "primereact/panel";
import { IConsultaAgregado } from "../../../../interface/AutomotivaConsultaAgregada";
import { Card } from "../../../../components/Card";

export const AutomotivaConsultaAgregadaPage = (data: IConsultaAgregado) => {
  const response = data?.resposta?.binXML;

  const getValue = (value?: string): string => value || "Não foi informado";

  return (
    <Panel header="Dados Consulta Agregado" toggleable>
      {response && (
        <Card.Container>
          <Card.Item label="Placa" value={getValue(response?.placa)} />
          <Card.Item label="Chassi" value={getValue(response?.chassi)} />
          <Card.Item label="Renavam" value={getValue(response?.renavam)} />
          <Card.Item
            label="Marca/Modelo"
            value={getValue(response?.marca || response?.modelo)}
          />
          <Card.Item
            label="Ano de Fabricação/Modelo"
            value={`${getValue(
              response?.ano_fabricacao?.toString()
            )} / ${getValue(response?.ano_modelo?.toString())}`}
          />
          <Card.Item label="Cor" value={getValue(response?.cor)} />
          <Card.Item label="Situação" value={getValue(response?.situacao)} />
          <Card.Item
            label="Potência (CV)"
            value={`${getValue(response?.potencia?.toString())} CV`}
          />
          <Card.Item
            label="Cilindradas"
            value={`${getValue(response?.cilindradas?.toString())} cc`}
          />
          <Card.Item
            label="Capacidade de Passageiros"
            value={`${getValue(
              response?.capacidade_passageiros?.toString()
            )} pessoas`}
          />
          <Card.Item
            label="Tipo de Veículo"
            value={getValue(response?.tipo_veiculo)}
          />
          <Card.Item label="Espécie" value={getValue(response?.especie)} />
          <Card.Item
            label="Procedência"
            value={getValue(response?.procedencia)}
          />
          <Card.Item
            label="Combustível"
            value={getValue(response?.combustivel)}
          />
          <Card.Item
            label="Município/UF"
            value={`${getValue(response?.municipio)} / ${getValue(
              response?.uf
            )}`}
          />
          <Card.Item
            label="Restrição 1"
            value={getValue(response?.restricao1)}
          />
          <Card.Item
            label="Restrição 2"
            value={getValue(response?.restricao2)}
          />
          <Card.Item
            label="Restrição 3"
            value={getValue(response?.restricao3)}
          />
          <Card.Item
            label="Restrição 4"
            value={getValue(response?.restricao4)}
          />
          <Card.Item
            label="Última Atualização"
            value={getValue(response?.ultima_atualizacao)}
          />
          <Card.Item
            label="CMT (Capacidade Máxima de Tração)"
            value={`${getValue(response?.CMT?.toString())} kg`}
          />
          <Card.Item
            label="PTB (Peso Bruto Total)"
            value={`${getValue(response?.PTB?.toString())} kg`}
          />
          <Card.Item
            label="Caixa de Câmbio"
            value={getValue(response?.caixa_cambio?.toString())}
          />
          <Card.Item label="Motor" value={getValue(response?.motor)} />
          <Card.Item
            label="Remarcação do Chassi"
            value={getValue(response?.remarcacao_do_chassi)}
          />
          <Card.Item label="Mensagem" value={getValue(response?.mensagem)} />
          <Card.Item
            label="Número da Carroceria"
            value={getValue(response?.num_carroceria)}
          />
          <Card.Item
            label="Número de Eixo"
            value={getValue(response?.num_eixo)}
          />
        </Card.Container>
      )}

      {!response && <p>Nada consta</p>}
    </Panel>
  );
};
