import {
  CadastralLocalizacaoSimplesProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';
import { formatDefaultValuesToString, formatCpfOrCnpj, formatCep, formatPhoneNumber } from '../../../../utils/ValidationUtils';
import OutPutCard from "../../../../components/outputCard";
import { Divider } from "primereact/divider";

const labelRendaPresumida = (key: string): string => {
  const labels: { [key: string]: string } = {
    FAIXA: "Faixa",
    DESCRICAO: "Descrição",
    MENSAGEM: "Observações",
  };

  return labels[key] || key;
};

const labelDadosReceitaFederal = (key: string): string => {
  const labels: { [key: string]: string } = {
    TIPO_PESSOA: "Tipo de Pessoa",
    RAZAO_SOCIAL: "Razão Social",
    SITUACAO_RECEITA: "Situação Receita Federal",
    DATA_ATUALIZACAO: "Data de Atualização",
    NATUREZA_JURIDICA: "Natureza Jurídica",
    DATA_NASCIMENTO_FUNDACAO: "Data de Nascimento/Fundação",
    EMAIL: "E-mail",
    NOME: "Nome",
    NOME_MAE: "Nome da Mãe",
    SEXO: "Sexo",
    ATIVIDADE_ECONOMICA_PRINCIPAL: "Atividade Econômica Principal"
  };

  return labels[key] || key;
};

const getDadosGerais = (dados: any): JSX.Element[] => {
  let dadosFormatados: { label: string; value: string }[] = [];
  
  const renderizarDinamico = (rowData: any, field: string) => {
    const value = rowData[field];
    return formatDefaultValuesToString(value);
  };

  const resultado: JSX.Element[] = [];

  if (dados.INFORMACOES_ALERTAS_RESTRICOES.OCORRENCIAS) {
    resultado.push(
      <div key="INFORMACOES_ALERTAS_RESTRICOES">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Informações, Alertas ou Restrições
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.INFORMACOES_ALERTAS_RESTRICOES.OCORRENCIAS}>
          <Column
            field="DESCRICAO_TIPO_INFORMACAO"
            body={(rowData) => (
              <>
                {rowData.DESCRICAO_TIPO_INFORMACAO == "INFORMACAO" ? <i className="pi pi-info-circle" style={{ fontSize: '1.2rem', color: "var(--primary-color)" }}></i> : rowData.DESCRICAO_TIPO_INFORMACAO}
              </>
            )}
          />
          <Column field="TITULO"  header="Título"/>
          <Column field="OBSERVACOES"  header="Observações"/>
        </DataTable>
        <br></br>
        <br></br> 
      </div>      
    );
  }

  if (dados.DADOS_RECEITA_FEDERAL) {
    dadosFormatados = Object.entries(dados.DADOS_RECEITA_FEDERAL).filter(([key]) => labelDadosReceitaFederal(key) !== key).map(([key, value]) => ({
      label: labelDadosReceitaFederal(key),
      value: formatDefaultValuesToString(value),
    })); 
    resultado.push(
      <div key="DADOS_RECEITA_FEDERAL">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Dados da Receita Federal
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard
                label={item.label}
                value={item.value}
              ></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br> 
      </div>
    );
  }

  if (dados.RENDA_PRESUMIDA) {
    dadosFormatados = Object.entries(dados.RENDA_PRESUMIDA).filter(([key]) => labelRendaPresumida(key) !== key).map(([key, value]) => ({
      label: labelRendaPresumida(key),
      value: formatDefaultValuesToString(value),
    }));    
    resultado.push(
      <div key="RENDA_PRESUMIDA">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Renda Presumida
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard
                label={item.label}
                value={item.value}
              ></OutPutCard>
            </div>
          ))}
        </div>
        <br></br>
        <br></br> 
      </div>
    );
  } 

  if (dados.PARTICIPACAO_EM_EMPRESAS && dados.PARTICIPACAO_EM_EMPRESAS.OCORRENCIAS) {
    resultado.push(
      <div key="PARTICIPACAO_EM_EMPRESAS">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Participação em Empresas
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.PARTICIPACAO_EM_EMPRESAS.OCORRENCIAS}
        >
          <Column field="RAZAO_SOCIAL" header="Empresa"/>
          <Column field="CNPJ" header="CNPJ" body={(rowData) => formatCpfOrCnpj(rowData.CNPJ)}/>
          <Column field="PARTICIPANTE_TIPO" header="Tipo"/>
          <Column field="PARTICIPANTE_CARGO" header="Cargo"/>
          <Column field="PARTICIPACAO" header="Participação (%)"/>
          <Column field="PARTICIPANTE_ENTRADA" header="Entrada"/>
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.TELEFONE_CELULAR.TELEFONES) {
    resultado.push(
      <div key="TELEFONE_CELULAR">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Telefones Celulares
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.TELEFONE_CELULAR.TELEFONES}
        >
          <Column field="NOME" header="Nome"/>
          <Column field="DOCUMENTO" header="CPF/CNPJ" body={(rowData) => formatCpfOrCnpj(rowData.DOCUMENTO)}/>
          <Column field="NUM_TELEFONE" header="Telefone" body={(rowData) => formatPhoneNumber(`${rowData.DDD || ""}${rowData.NUM_TELEFONE || ""}`.trim())}/>
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.PARENTES && dados.PARENTES.INFOCONTATOS) {
    resultado.push(
      <div key="PARENTES">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Parentes
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.PARENTES.INFOCONTATOS}
        >
          <Column field="DOCUMENTO" header="CPF" body={(rowData) => formatCpfOrCnpj(rowData.DOCUMENTO)}/>
          <Column field="NOME" header="Nome"/>          
          <Column field="NASCIMENTO" header="Nascimento"/>
          <Column field="RELACAO" header="Parentesco"/>
          <Column header="Cidade" body={(rowData) => rowData.CIDADE+"/"+rowData.UF}/>
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.SOMENTE_ENDERECO.DADOS) {
    resultado.push(
      <div key="SOMENTE_ENDERECO">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Endereços
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.SOMENTE_ENDERECO.DADOS}
        >
          <Column field="DOCUMENTO" header="CPF/CNPJ" body={(rowData) => formatCpfOrCnpj(rowData.DOCUMENTO)}/>
          <Column field="ENDERECO" header="Endereço"/>
          <Column field="NUMERO" header="Número"/>
          <Column field="COMPLEMENTO" header="Complemento"/>
          <Column field="BAIRRO" header="Bairro"/>
          <Column header="Cidade" body={(rowData) => rowData.CIDADE+"/"+rowData.UF}/>          
          <Column field="CEP" header="CEP" body={(rowData) => formatCep(rowData.CEP)}/>
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.TELEFONE_FIXO.TELEFONES) {
    resultado.push(
      <div key="TELEFONE_FIXO">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Telefones Fixos
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.TELEFONE_FIXO.TELEFONES}
        >
          <Column field="NOME" header="Nome"/>
          <Column field="DOCUMENTO" header="CPF/CNPJ" body={(rowData) => formatCpfOrCnpj(rowData.DOCUMENTO)}/>
          <Column field="NUM_TELEFONE" header="Telefone" body={(rowData) => formatPhoneNumber(`${rowData.DDD || ""}${rowData.NUM_TELEFONE || ""}`.trim())}/>
        </DataTable>
        <br />
        <br />
      </div>
    );
  }
  
  if (dados.EMAILS.INFOEMAILS) {
    resultado.push(
      <div key="EMAILS">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            E-mails de Contato
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.EMAILS.INFOEMAILS}>
          <Column header="E-mail" field="ENDERECO" />
        </DataTable>
        <br />
        <br />
      </div>
    );
  }  

  if (dados.VEICULOS_POR_DOCUMENTO.OCORRENCIAS) {
    const fields = Object.keys(dados.VEICULOS_POR_DOCUMENTO.OCORRENCIAS[0] || {});
    resultado.push(
      <div key="VEICULOS_POR_DOCUMENTO">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Veículos por CPF/CNPJ
          </span>
        </Divider>
        <DataTable size="normal" stripedRows value={dados.VEICULOS_POR_DOCUMENTO.OCORRENCIAS}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={field}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      </div>
    );  
  }

  return resultado;
};

export function CadastralLocalizacaoSimples({
  data,
}: CadastralLocalizacaoSimplesProps) {

  const dadosGerais = getDadosGerais(data);

  return (
    <Panel className="p-panel-header-green" header="Cadastral Localização Simples" style={{ width: '98%' }} toggleable>
      <>
        {dadosGerais.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
        <br></br>
      </>
    </Panel>
  );
}
