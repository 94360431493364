import { AutomotivaRecallProps } from "./types";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./index.css";
import { Divider } from "primereact/divider";

const getDadosGerais = (dados: any): JSX.Element[] => {
  const resultado: JSX.Element[] = [];  

  if (dados.OCORRENCIAS) {
    resultado.push(
      <div key="OCORRENCIAS">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Ocorrências
          </span>
        </Divider>
        <DataTable
          size="normal"
          stripedRows
          value={dados.OCORRENCIAS}
        >
          <Column field="DEFEITO" header="Defeito" />
          <Column field="MENSAGEM_COMPLETA" header="Mensagem Completa" />
          <Column field="RISCO" header="Risco" />
          <Column field="DATA_INICIO" header="Data Início" />
        </DataTable>
      </div>
    );
  }

  return resultado;
};

export function AutomotivaRecall({ data }: AutomotivaRecallProps) {
  const dadosGerais = getDadosGerais(data.RECALL);

  return (
    <Panel
      className="p-panel-header-green"
      header="Automotiva Recall"
      style={{ width: "98%" }}
      toggleable
    >
      <>
        {dadosGerais.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
        <br></br>
      </>
    </Panel>
  );
}
