import { AxiosResponse } from "axios";
import { http } from "../../config/axios";

export type AgregadosModel = {
  PLACA?: string;
  CHASSI?: string;
  FABRICANTE?: string;
  MARCAMODELO?: string;
  ANOMODELO?: null;
  ANOFABRICACAO?: null;
  CORVEICULO?: string;
  COMBUSTIVEL?: string;
  RENAVAM?: string;
  [key: string]: any; // Caso precise de mais campos no futuro
};

export default async function postAgregados(
  placa: string | null,
  chassi: string | null
): Promise<AgregadosModel | null> {
  try {
    const response: AxiosResponse = await http.post(
      "api/gatewaybiro/automotivaagregado",
      { placa, chassi }
    );

    if (
      response.data &&
      response.data.resposta &&
      response.data.resposta.automotivaAgregado &&
      response.data.resposta.automotivaAgregado.RESPOSTA &&
      response.data.resposta.automotivaAgregado.RESPOSTA.RETORNO
    ) {
      return response.data.resposta.automotivaAgregado.RESPOSTA.RETORNO;
    }

    console.warn("Estrutura inesperada na resposta da API:", response.data);
    return null;
  } catch (error) {
    console.error("Erro ao buscar dados da API:", error);
    return null;
  }
}
