import { Panel } from "primereact/panel";
import { IPlacaPdfCrvNexo } from "../../../../interface/PlacaPdfCrvNexo";
import { Card } from "../../../../components/Card";
import { getValue } from "../../../../utils/field_validator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { openPDF } from "../../../../utils/open_pdf_base64";

export const PlacaPdfCrvNexoPage = (data: IPlacaPdfCrvNexo) => {
  return (
    <Panel header="Consulta CRV" toggleable style={{ width: "98%" }}>
      <Card.Container>
        <Card.Item label="Placa" value={getValue(data?.placa)} />
        <Card.Item label="Renavam" value={getValue(data?.renavam)} />
        <Card.Item label="Chassi" value={getValue(data?.chassi)} />
        <Card.Item label="Marca/Modelo" value={getValue(data?.marcaModelo)} />
        <Card.Item label="Cor" value={getValue(data?.cor)} />
        <Card.Item
          label="Ano de Fabricação"
          value={getValue(data?.anoFabricacao)}
        />
        <Card.Item label="Ano do Modelo" value={getValue(data?.anoModelo)} />
        <Card.Item label="Tipo" value={getValue(data?.tipo)} />
        <Card.Item label="Carroceria" value={getValue(data?.carroceria)} />
        <Card.Item label="Espécie" value={getValue(data?.especie)} />
        <Card.Item label="Categoria" value={getValue(data?.categoria)} />
        <Card.Item label="Município/UF" value={getValue(data?.municipioUf)} />
        <Card.Item label="Combustível" value={getValue(data?.combustivel)} />
        <Card.Item
          label="Capacidade de Carga"
          value={getValue(data?.capacidadeCarga)}
        />
        <Card.Item label="Lotação" value={getValue(data?.lotacao)} />
        <Card.Item
          label="Possui Ocorrência de Roubo/Furto"
          value={data?.possuiOcorrenciaRouboFurto ? "Sim" : "Não"}
        />
        <Card.Item
          label="Possui Restrição Judicial"
          value={data?.possuiRestricaoJudicial ? "Sim" : "Não"}
        />
        <Card.Item
          label="Possui Multas"
          value={data?.possuiMulta ? "Sim" : "Não"}
        />
        <Card.Item
          label="Foi Emplacado"
          value={data?.foiEmplacado ? "Sim" : "Não"}
        />
        <Card.Item
          label="Restrição 1"
          value={getValue(data?.restricao1 ?? "")}
        />
        <Card.Item
          label="Restrição 2"
          value={getValue(data?.restricao2 ?? "")}
        />
        <Card.Item
          label="Nome do Estampador"
          value={getValue(data?.nomeEstampador ?? "")}
        />
        <Card.Item
          label="UF do Estampador"
          value={getValue(data?.ufEstampador)}
        />
        <Card.Item
          label="Identificador do Estampador"
          value={getValue(data?.identificadorEstampador)}
        />
        <Card.Item
          label="Data/Hora de Registro da Estampagem"
          value={getValue(data?.dataHoraRegistroEstampagem)}
        />
        <Card.Item
          label="Serial da Placa"
          value={getValue(data?.serialPlaca)}
        />
        <Card.Item
          label="Número do Motor"
          value={getValue(data?.numeroMotor)}
        />
        <Card.Item
          label="Código de Segurança do CRV"
          value={getValue(data?.codigoSegurancaCrv)}
        />
        <Card.Item
          label="Número do Proprietário (NI)"
          value={getValue(data?.niProprietario)}
        />
        <Card.Item
          label="Nome do Proprietário"
          value={getValue(data?.nomeProprietario)}
        />
        <Card.Item
          label="Ano do Último Licenciamento"
          value={getValue(data?.anoUltimoLicenciamento?.toString())}
        />
        <Card.Item
          label="Mostrar Alerta de Observações"
          value={data?.mostrarAlertaObservacoes ? "Sim" : "Não"}
        />
        <Card.Item
          label="Mensagem de Alerta de Observações"
          value={getValue(data?.mensagemAlertaObservacoes)}
        />
        <Card.Item
          label="Placa Pré-Mercosul"
          value={data?.placaPreMercosul ? "Sim" : "Não"}
        />
        <Card.Item
          label="Placa Inutilizada"
          value={data?.placaInutilizada ? "Sim" : "Não"}
        />
        <Card.Item
          label="Documento PDF"
          value={
            <button
              style={{
                background: "transparent",
                border: "none",
                textDecoration: "underline",
                cursor: "pointer",
                color: "blue",
                fontSize: "16px",
              }}
              onClick={() => openPDF(data?.arquivo)}
            >
              Visualizar arquivo pdf
            </button>
          }
        />
      </Card.Container>

      <br />
      <br />

      <Panel header="Observações">
        <DataTable value={data?.observacoes}>
          <Column field="descricao" header="Descricao" />
          <Column field="dataHora" header="Data/Hora" />
        </DataTable>
      </Panel>
    </Panel>
  );
};
