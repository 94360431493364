import "./outputcard.css";
type Props = {
  label: string;
  value: string;
};
const OutPutCard = ({ label, value }: Props) => {
  return (
    <div
      style={{ width: "15rem" }}
      className="flex-score justify-content-between px-3 py-2 border-left-2 cursor-pointer mx-4 border-round-md surface-50 hover:shadow-2 hover:surface-card border-color-500"
    >
      <div className="flex-score justify-content-between gap-3">
        <div className="flex-score flex-column justify-content-center gap-2">
          <span className="font-medium text-color print-text">{label}</span>
          <span className="text-color-secondary print-text">{value}</span>
        </div>
      </div>
    </div>
  );
};

export default OutPutCard;
