import { forwardRef } from "react";

interface PrintCustomProps extends React.ComponentProps<"div"> {}

export const PrintCustom = forwardRef<HTMLDivElement, PrintCustomProps>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} className="flex flex-column align-items-center" {...props}>
        <style type="text/css" media="print">
          {
            "\
  @page { size: portrait; font-size: 6pt; .label-dados {font-size: 6pt;}; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }\
"
          }
        </style>
        {children}
      </div>
    );
  }
);
