import { http } from '../../config/axios'

const findVeiculoTransferencia = (params:any): Promise<any> => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => encodeURIComponent(params[k]))
            .join('/')
        : '';
    return http
        .get('api/veiculotransferencia/find/' + queryParams)
        .then((res) => {
            res.data.veiculosTransferencia.forEach((p:any) => {
                p.dataTransferencia = new Date(p.dataTransferencia);
                p.dataNascimentoComprador = new Date(p.dataNascimentoComprador);
                p.dataFirma = new Date(p.dataFirma);
                p.dataEmissaoCrv = new Date(p.dataEmissaoCrv);
            })
            return res.data as any
        })
        .catch((error) => {
            console.error('Erro findVeiculoTransferencia', error)
            throw Error(error.response.data)
        })
}

export default findVeiculoTransferencia;
