import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import Container from "../../../../components/menu/Container";
import { useQueryModel } from "../../../../hooks/consultaCrlv";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";


export const HomeView = (methods: ReturnType<typeof useQueryModel>) => {
  const {
    listItems,
    selectedItem,
    plate,
    setSelectedItem,
    setPlate,
    onSubmit,
  } = methods;
  const { t } = useTranslation();

  return (
    <Container>
      <h1 style={{
          marginTop: "76px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}><i className="pi pi-search-plus" style={{ fontSize: "2rem" }}></i>{t('consultarCrlv')}</h1>
      <Panel header="Formulário de consulta">
        <form className={styles.formContainer} onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <label htmlFor="placa">Placa</label>
            <InputText
              name="placa"
              id="placa"
              placeholder="Digite a placa"
              value={plate}
              onChange={({ target }) => setPlate(target.value)}
              required
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="estado">Estado</label>
            <Dropdown
              id="estado"
              optionLabel="name"
              placeholder="Selecione o estado"
              options={listItems}
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.value)}
              checkmark
              required
            />
          </div>

          <Button
            type="submit"
            label="Consultar"
            style={{ backgroundColor: "#183462" }}
          />
        </form>

        <br />
        <br />
      </Panel>
    </Container>
  );
};
