import { IAutomotivaSinistro } from "../../../../interface";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const AutomotivaSinistroPage = (data: IAutomotivaSinistro) => {
  const getValue = (value?: string): string => value || "Não informado";
  const { ERRO, SINISTRO } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Dados Sinistro</p>
      </div>

      <div className={styles.listaWrapper}>
        {!ERRO && SINISTRO && (
          <>
            <TextField label="Placa" value={getValue(SINISTRO?.placa)} />
            <TextField label="Chassi" value={getValue(SINISTRO?.chassi)} />
            <TextField label="Data" value={getValue(SINISTRO?.dataCadastro)} />
          </>
        )}

        {ERRO && <p>{ERRO}</p>}
      </div>
    </div>
  );
};
