import { Panel } from "primereact/panel";
import { ILocalizacaoCCFPF } from "../../../../interface/LocalizacaoCCFPF";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "../../../../components/Card";
import React from "react";

export const LocalizacaoCCFPF = (data: ILocalizacaoCCFPF) => {
  const {
    INFORMACOES_ALERTAS_RESTRICOES,
    TELEFONE_CELULAR,
    RENDA_PRESUMIDA,
    DADOS_RECEITA_FEDERAL,
    PARENTES,
    SOMENTE_ENDERECO,
    TELEFONE_FIXO,
    EMAILS,
    VEICULOS_POR_DOCUMENTO,
    PARTICIPACAO_EM_EMPRESAS,
  } = data;

  function getValue(value?: string): string {
    return value || "Não informado";
  }

  function renderPhone(data: any) {
    return (
      <span>
        ({data?.DDD}) {data?.NUM_TELEFONE}
      </span>
    );
  }

  function renderDocument(data: any) {
    return <span>{data?.DOCUMENTO.maskCpfCnpj()}</span>;
  }

  function renderCnpj(data: any) {
    return <span>{getValue(data?.CNPJ.maskCpfCnpj())}</span>;
  }

  function renderDocuments(data: any) {
    return <span>{data?.PARTICIPANTE_DOCUMENTO.maskCpfCnpj()}</span>;
  }

  return (
    <Panel
      header="Dados Localização CCF PF"
      toggleable
      style={{ width: "98%" }}
    >
      <Panel header="Dados da Receita Federal" toggleable>
        <Card.Container>
          <Card.Item
            label="CPF"
            value={getValue(DADOS_RECEITA_FEDERAL?.SITUACAO_RECEITA)}
          />
          <Card.Item
            label="Situação"
            value={getValue(DADOS_RECEITA_FEDERAL?.SITUACAO_RECEITA)}
          />
          <Card.Item
            label="Nome"
            value={getValue(DADOS_RECEITA_FEDERAL?.NOME)}
          />
          <Card.Item
            label="Atualização"
            value={getValue(DADOS_RECEITA_FEDERAL?.DATA_ATUALIZACAO)}
          />
          <Card.Item
            label="Nascimento"
            value={getValue(DADOS_RECEITA_FEDERAL?.DATA_NASCIMENTO_FUNDACAO)}
          />
          <Card.Item
            label="Sexo"
            value={getValue(DADOS_RECEITA_FEDERAL?.SEXO)}
          />
          <Card.Item
            label="Nome da Mãe"
            value={getValue(DADOS_RECEITA_FEDERAL?.NOME_MAE)}
          />
        </Card.Container>
      </Panel>

      <br />
      <br />

      <Panel header="Informações, Alertas ou Restrições" toggleable>
        <DataTable
          value={INFORMACOES_ALERTAS_RESTRICOES?.OCORRENCIAS}
          emptyMessage="Nada consta"
        >
          <Column header="Código" field="CODIGO_TIPO_INFORMACAO" />
          <Column header="Título" field="TITULO" />
          <Column header="Descrição" field="DESCRICAO_TIPO_INFORMACAO" />
          <Column header="Observação" field="OBSERVACOES" />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Renda Presumida" toggleable>
        <Card.Container>
          <Card.Item label="Faixa" value={getValue(RENDA_PRESUMIDA?.FAIXA)} />
          <Card.Item
            label="Observação"
            value={getValue(RENDA_PRESUMIDA?.MENSAGEM)}
          />
        </Card.Container>
      </Panel>

      <br />
      <br />

      <Panel header="Parentes" toggleable>
        {PARENTES?.INFOCONTATOS.map((item) => (
          <React.Fragment key={item?.DOCUMENTO}>
            <Card.Container>
              <Card.Item label="Nome" value={getValue(item?.NOME)} />
              <Card.Item
                label="CPF/CNPJ"
                value={getValue(item?.DOCUMENTO?.maskCpfCnpj())}
              />
              <Card.Item label="Parentesco" value={getValue(item?.RELACAO)} />
              <Card.Item label="Telefone" value={getValue(item?.TELEFONE)} />
              <Card.Item label="Óbito" value={getValue(item?.OBITO)} />
              <Card.Item label="CEP" value={getValue(item?.CEP?.maskCep())} />
              <Card.Item label="Sexo" value={getValue(item?.SEXO)} />
              <Card.Item
                label="Tipo de Pessoa"
                value={getValue(item?.TIPO_PESSOA)}
              />
              <Card.Item label="UF" value={getValue(item?.UF)} />
              <Card.Item label="Bairro" value={getValue(item?.BAIRRO)} />
              <Card.Item
                label="Data de Nascimento"
                value={getValue(item?.NASCIMENTO?.formatDate())}
              />
              <Card.Item label="Endereço" value={getValue(item?.ENDERECO)} />
              <Card.Item label="Cidade" value={getValue(item?.CIDADE)} />
            </Card.Container>
            <br />
            <br />
          </React.Fragment>
        ))}

        {PARENTES?.INFOCONTATOS.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Veículos por CPF/CNPJ" toggleable>
        <DataTable
          value={VEICULOS_POR_DOCUMENTO?.OCORRENCIAS}
          emptyMessage="Nada consta"
        ></DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Email(s)" toggleable>
        {EMAILS?.INFOEMAILS.map((item) => (
          <React.Fragment key={item?.ENDERECO}>
            <Card.Container>
              <Card.Item label="E-mail" value={getValue(item?.ENDERECO)} />
            </Card.Container>
            <br />
            <br />
          </React.Fragment>
        ))}

        {EMAILS?.INFOEMAILS.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Endereços" toggleable>
        {SOMENTE_ENDERECO?.DADOS.map((item) => (
          <React.Fragment key={item?.DOCUMENTO}>
            <Card.Container>
              <Card.Item
                label="Tipo de Pessoa"
                value={getValue(item?.TIPO_PESSOA)}
              />
              <Card.Item
                label="CPF/CNPJ"
                value={getValue(item?.DOCUMENTO?.maskCpfCnpj())}
              />
              <Card.Item label="UF" value={getValue(item?.UF)} />
              <Card.Item label="Bairro" value={getValue(item?.BAIRRO)} />
              <Card.Item
                label="Complemento"
                value={getValue(item?.COMPLEMENTO)}
              />
              <Card.Item label="Número" value={getValue(item?.NUMERO)} />
              <Card.Item label="Endereço" value={getValue(item?.ENDERECO)} />
              <Card.Item label="Cidade" value={getValue(item?.CIDADE)} />
              <Card.Item label="CEP" value={getValue(item?.CEP?.maskCep())} />
            </Card.Container>
            <br />
            <br />
          </React.Fragment>
        ))}

        {SOMENTE_ENDERECO?.DADOS.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Telefone(s) Fixo(s)" toggleable>
        {TELEFONE_FIXO?.TELEFONES.map((item) => (
          <React.Fragment key={item?.DOCUMENTO}>
            <Card.Container>
              <Card.Item label="Nome" value={getValue(item?.NOME)} />
              <Card.Item
                label="Tipo de Pessoa"
                value={getValue(item?.TIPO_PESSOA)}
              />
              <Card.Item
                label="Documento (CPF/CNPJ)"
                value={getValue(item?.DOCUMENTO?.maskCpfCnpj())}
              />
              <Card.Item label="Nome da Mãe" value={getValue(item?.NOME_MAE)} />
              <Card.Item
                label="Data de Nascimento"
                value={getValue(item?.NASCIMENTO?.formatDate())}
              />
              <Card.Item label="Sexo" value={getValue(item?.SEXO)} />
              <Card.Item
                label="Telefone"
                value={getValue(
                  item?.NUM_TELEFONE
                    ? `(${item?.DDD}) ${item?.NUM_TELEFONE}`
                    : ""
                )}
              />
              <Card.Item label="UF" value={getValue(item?.UF)} />
              <Card.Item label="Cidade" value={getValue(item?.CIDADE)} />
              <Card.Item label="Bairro" value={getValue(item?.BAIRRO)} />
              <Card.Item label="Endereço" value={getValue(item?.ENDERECO)} />
              <Card.Item label="Número" value={getValue(item?.NUMERO)} />
              <Card.Item
                label="Complemento"
                value={getValue(item?.COMPLEMENTO)}
              />
              <Card.Item label="CEP" value={getValue(item?.CEP?.maskCep())} />
              <Card.Item label="Óbito" value={getValue(item?.OBITO)} />
            </Card.Container>
            <br />
            <br />
          </React.Fragment>
        ))}

        {TELEFONE_FIXO?.TELEFONES.length === 0 && <p>Nada consta</p>}
      </Panel>

      <br />
      <br />

      <Panel header="Participação em Empresas" toggleable>
        <DataTable
          value={PARTICIPACAO_EM_EMPRESAS?.OCORRENCIAS}
          emptyMessage="Nada consta"
        >
          <Column
            header="Razão Social"
            body={(data: any) => <span>{getValue(data?.RAZAO_SOCIAL)}</span>}
          />
          <Column
            header="Quantidade de Outros Sócios"
            body={(data: any) => (
              <span>{getValue(data?.QTD_OUTROS_SOCIOS)}</span>
            )}
          />
          <Column header="CNPJ" body={renderCnpj} />
          <Column
            header="Participação (%)"
            body={(data: any) => <span>{getValue(data?.PARTICIPACAO)}</span>}
          />
          <Column
            header="Tipo de Participante"
            body={(data: any) => (
              <span>{getValue(data?.PARTICIPANTE_TIPO)}</span>
            )}
          />
          <Column
            header="Cargo do Participante"
            body={(data: any) => (
              <span>{getValue(data?.PARTICIPANTE_CARGO)}</span>
            )}
          />
          <Column
            header="Data de Entrada"
            body={(data: any) => (
              <span>{getValue(data?.PARTICIPANTE_ENTRADA)}</span>
            )}
          />
          <Column header="Documento do Participante" body={renderDocuments} />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Telefone(s) Celular(es)" toggleable>
        <DataTable
          value={TELEFONE_CELULAR?.TELEFONES}
          emptyMessage="Nada consta"
        >
          <Column header="Nome" field="NOME" />
          <Column header="CPF/CNPJ" body={renderDocument} />
          <Column header="Telefone" body={renderPhone} />
        </DataTable>
      </Panel>

      <br />
      <br />
    </Panel>
  );
};
