import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { useTranslation } from "react-i18next";
import { Chip } from "primereact/chip";
import NadaConsta from "../../../../components/nadaConsta";
import { formatValueReais, formatPhoneNumber } from '../../../../utils/ValidationUtils';

export function CadastralProtestoCenprot({
  data,
}: any) {

  const { t } = useTranslation();
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return <div className={className}> {data.PROTESTO_SINTETICO.OCORRENCIAS ? (<span style={{ color: '#C70039', fontSize: '1.5rem' }} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{ marginLeft: '1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color: 'black' }}>{t('protestos')}</span></span>) : (<span className="p-panel-title" style={{ marginLeft: '1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color: 'black' }}>{t('protestos')}</span>)} </div>;
  }
  return (
    <Panel headerTemplate={headerTemplate} style={{ width: '98%' }}>
      {!data.PROTESTO_SINTETICO.OCORRENCIAS && (<NadaConsta />)}
      {data.PROTESTO_SINTETICO.OCORRENCIAS && data.PROTESTO_SINTETICO.OCORRENCIAS.map((protesto: any) => {
        return (
          <>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('cartorio')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.CARTORIO} />
                </div>
              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('uf')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.UF} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('Data Ocorrência')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.DATA_OCORRENCIA} />
                </div>

              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('fone')}</label>
                  <Chip style={{ width: "100%" }} label={formatPhoneNumber(protesto.TELEFONE)} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('Cedente')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.CEDENTE ? protesto.CEDENTE : "N/A"} />
                </div>
              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('valor')}</label>
                  <Chip style={{ width: "100%" }} label={formatValueReais(protesto.VALOR)} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('Info. Cartórios')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.INFO_CARTORIOS ? protesto.INFO_CARTORIOS : "N/A"} />
                </div>
              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('Comarca')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.COMARCA} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('Credor')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.CREDOR ? protesto.CREDOR : "N/A"} />
                </div>
              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('Data Atualização')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.DATA_ATUALIZACAO ? protesto.DATA_ATUALIZACAO : "N/A"} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div>
                  <label className="label-dados">{t('endereco')}</label>
                  <Chip style={{ width: "100%" }} label={protesto.ENDERECO} />
                </div>
              </div>
            </div>
            <br></br>
          </>
        )
      })}
    </Panel>
  )
}
