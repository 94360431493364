import { http } from '../../config/axios'

export default function getTipoParametroAplicacaoLivre(): Promise<any> {
  return http
    .get('api/parametroaplicacao/tipoparametrolivre')
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro getTipoParametroAplicacaoLivre', error)
      throw Error(error.response.data)
    })
}
