import {
  ApiResponseAutoRenainf,
  DadosFormatados,
  AutoRenainfProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';
import { formatDefaultValuesToString, formatValueReais } from '../../../../utils/ValidationUtils';


const labelDadosGerais = (key: string): string => {
  const labelDadosGerais: { [key: string]: string } = {
    Indicador_Elegibilidade: "Indicador Elegibilidade:",
    Placa: "Placa:",
    Qualtidade_de_Ocorrencias: "Quantidade Ocorrências:",
  };

  return labelDadosGerais[key] || key;
};

const formatarDadosGerais = (dados: any): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosGerais(key) !== key).map(([key, value]) => ({
    label: labelDadosGerais(key),
    value: formatDefaultValuesToString(value as string),
  }));

  const resultado: JSX.Element[] = [];
  if (dados.Multas) {
    const renderizarDinamico = (rowData: any, field: string) => {
      const value = rowData[field];
      return formatDefaultValuesToString(value);
    };
    
    const camposPreenchidos = Object.keys(dados.Multas[0]).filter((key) =>
      dados.Multas.some((multa: any) => {
        const value = multa[key];
        return (
          value !== null &&
          value !== undefined &&
          value !== "" &&
          (typeof value !== "object" || Object.keys(value).length > 0)
        );
      })
    );

    resultado.push(
      <DataTable header="Multas" size="normal" stripedRows value={dados.Multas}>
        <Column field="Valor_da_Infracao" header="Valor Infração" body={(rowData) => formatValueReais(rowData.Valor_da_Infracao)}/>
        {camposPreenchidos.includes("Data_de_Registro") && (
          <Column field="Data_de_Registro" header="Data de Registro" body={(rowData) => renderizarDinamico(rowData, "Data_de_Registro")} />
        )}
        {camposPreenchidos.includes("Aceite_UF_Jurisdicao") && (
          <Column field="Aceite_UF_Jurisdicao" header="Aceite UF Jurisdição" body={(rowData) => renderizarDinamico(rowData, "Aceite_UF_Jurisdicao")} />
        )}
        <Column field="Data_da_Infracao" header="Data Infração" />
        <Column field="Hora_da_Infracao" header="Hora Infração" />
        {camposPreenchidos.includes("UF_Pagamento") && (
          <Column field="UF_Pagamento" header="UF Pagamento" body={(rowData) => renderizarDinamico(rowData, "UF_Pagamento")} />
        )}
        <Column field="Marca/Modelo_do_Veiculo" header="Marca/Modelo do Veículo" />
        {camposPreenchidos.includes("Origem") && (
          <Column field="Origem" header="Origem" body={(rowData) => renderizarDinamico(rowData, "Origem")} />
        )}
        <Column field="UF_jurisdicao_do_Veiculo" header="UF Jurisdição do Veículo" />
        {camposPreenchidos.includes("Num_da_CNH_do_Infrator") && (
          <Column field="Num_da_CNH_do_Infrator" header="Número CNH do Infrator" body={(rowData) => renderizarDinamico(rowData, "Num_da_CNH_do_Infrator")} />
        )}
        {camposPreenchidos.includes("UF_do_Orgao_Autuador") && (
          <Column field="UF_do_Orgao_Autuador" header="UF Órgão Autuador" body={(rowData) => renderizarDinamico(rowData, "UF_do_Orgao_Autuador")} />
        )}
        <Column field="Cadastramento_Infracao" header="Cadastramento da Infração" />
        <Column field="Emissao_da_Penalidade" header="Emissão da Penalidade" />
        {camposPreenchidos.includes("Notificacao_da_Infracao") && (
          <Column field="Notificacao_da_Infracao" header="Notificação da Infração" body={(rowData) => renderizarDinamico(rowData, "Notificacao_da_Infracao")} />
        )}
        <Column field="Orgao_Autuador" header="Órgão Autuador" />
        <Column field="Local_da_Infracao" header="Local da Infração" />
        <Column field="Cod_da_Infracao" header="Código Infração" />
        {camposPreenchidos.includes("Num_da_CNH_do_Condutor") && (
          <Column field="Num_da_CNH_do_Condutor" header="Número CNH do Condutor" body={(rowData) => renderizarDinamico(rowData, "Num_da_CNH_do_Condutor")} />
        )}
        <Column field="Valor_Pago" header="Valor Pago" body={(rowData) => formatValueReais(rowData.Valor_Pago)}/>
        {camposPreenchidos.includes("Data_de_Registro_do_Pagamento") && (
          <Column field="Data_de_Registro_do_Pagamento" header="Data de Registro do Pagamento" body={(rowData) => renderizarDinamico(rowData, "Data_de_Registro_do_Pagamento")} />
        )}
        <Column field="Num_do_Auto_de_Infracao" header="Número do Auto de Infração" />
        <Column field="Unidade_de_Medida" header="Unidade de Medida" />
        <Column field="Cod_Municipal_Infracao" header="Código Municipal da Infração" />
        {camposPreenchidos.includes("UF_de_Emplacamento") && (
          <Column field="UF_de_Emplacamento" header="UF de Emplacamento" body={(rowData) => renderizarDinamico(rowData, "UF_de_Emplacamento")} />
        )}
        <Column field="Medicao_Real" header="Medição Real" />
        <Column field="Medicao_Considerada" header="Medição Considerada" />
        {camposPreenchidos.includes("Tipo") && (
          <Column field="Tipo" header="Tipo" body={(rowData) => renderizarDinamico(rowData, "Tipo")} />
        )}
        <Column field="Limite_Permitido" header="Limite Permitido" />
        <Column field="Tipo_Auto_de_Infracao" header="Tipo do Auto de Infração" />
        <Column field="Cod_Municipal_Emplacamento" header="Código Municipal de Emplacamento" />
        {camposPreenchidos.includes("Data_do_Pagamento_da_Infracao") && (
          <Column field="Data_do_Pagamento_da_Infracao" header="Data Pagamento da Infração" body={(rowData) => renderizarDinamico(rowData, "Data_do_Pagamento_da_Infracao")} />
        )}
      </DataTable>
    );
  }

  return {
    dados: dadosFormatados,
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseAutoRenainf
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data)
    resultado.push(formatarDadosGerais(data));

  return resultado;
};

export function AutoRenainf({
  data,
}: AutoRenainfProps) {
  const dados = getDadosGerais(data);

  return (
    <>
      <Panel className="p-panel-header-green" header="Automotiva Renainf" style={{ width: '98%' }} toggleable>
        {dados.map(({ dados, array }) => (
          <><DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          <br></br></>
        ))}
      </Panel>
    </>
  );
}
