import React from "react";
import "./styles.css";

interface IndicatorProps extends React.ComponentProps<"div"> {
  value: string;
}

const indicators: Record<string, string>[] = [
  { indicator: "A", color: "#16a34a" },
  { indicator: "B", color: "#4ade80" },
  { indicator: "C", color: "#facc15" },
  { indicator: "D", color: "#f59e0b" },
  { indicator: "E", color: "#ef4444" },
];

export const Indicator: React.FC<IndicatorProps> = ({
  className,
  value,
  ...props
}) => {
  return (
    <div className={`indicator-container ${className}`} {...props}>
      {indicators.map((item) => (
        <p
          key={item.indicator}
          className={value.isEquals(item.indicator) ? "active" : "inactive"}
        >
          {value.isEquals(item.indicator) && (
            <i className="pi pi-sort-down-fill" />
          )}
          <span className="circle" style={{ backgroundColor: item.color }} />{" "}
          {item.indicator}
        </p>
      ))}
    </div>
  );
};
