export const openImage = (base64Data: any) => {
  if (!base64Data) return; // Evita erro se o campo estiver vazio

  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: "image/png" });

  const url = URL.createObjectURL(blob);
  window.open(url, "_blank", "noopener,noreferrer");
};

export const transformBase64ToPng = (base64Data: string): string => {
  if (!base64Data) return "";

  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: "image/png" });

  const url = URL.createObjectURL(blob);
  return url;
};
