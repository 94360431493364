import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findLeilao from '../../service/findLeilao';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Nullable } from 'primereact/ts-helpers';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';




export default function LeilaoList({onEdit}:{onEdit:(leilao:any)=>void}) {
  const { t } = useTranslation();
  const [leiloes, setLeiloes] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [usuario, setUsuario] = useState<Nullable<String | null>>(null);
  const [placa, setPlaca] = useState<Nullable<String | null>>(null);
  const [chassi, setChassi] = useState<Nullable<String | null>>(null);
  const [data, setData] = useState<Nullable<Date>>(null);
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(null);
  
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };
  const formatDate = (date: Nullable<Date>) => {
    if (date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-");
    }
    return 'null';
  };
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    placa: placa,
    chassi: chassi,
    usuario: usuario,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    sortField: 'dataInclusao',
    sortOrder: SortOrder.DESC
  });

const cols = [
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'email', header: 'Email' },
  { field: 'inativo', header: 'Inativo' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page:  1,
            placa: placa,
            chassi: chassi,
            usuario: usuario,
            data: formatDate(data),
            dataFinal: formatDate(dataFinal),
            sortField: 'dataInclusao',
            sortOrder: SortOrder.DESC
          }
          findLeilao(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.clientes);
            doc.save('clientes.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page:  1,
    placa: placa,
    chassi: chassi,
    usuario: usuario,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    sortField: 'dataInclusao',
    sortOrder: SortOrder.DESC
  }
  findLeilao(lazy).then(data => {
    const users: any[] = []
    data.clientes.forEach((client:any) => users.push({nome:client.nome, email:client.email, documento:client.documento, inativo:client.inativo}))
    
    const worksheet = xlsx.utils.json_to_sheet(users);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'clientes');
  });

    
});
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};

const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);




  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      placa: placa,
      chassi: chassi,
      usuario: usuario,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      placa: placa,
      chassi: chassi,
      usuario: usuario,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  

  const loadLazyData = () => {
    findLeilao(lazyState).then(data => {setTotalRecords(data.total);setLeiloes(data.leiloes)}).catch(() => {setTotalRecords(0);setLeiloes(undefined)});
  }

  const editBodyTemplate = (client:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(client)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };

  const changeData = (data: Nullable<Date>) => {
    if (data) {
      setData(data);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: lazyState.first / lazyState.rows + 1,
        placa: placa,
        chassi: chassi,
        usuario: usuario,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        sortField: "dataInclusao",
        sortOrder: SortOrder.DESC,
      });
    }
  };

  const changeDataFinal = (dataFi: Nullable<Date>) => {
    if (dataFi) {
      setDataFinal(dataFi);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: lazyState.first / lazyState.rows + 1,
        placa: placa,
        chassi: chassi,
        usuario: usuario,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        sortField: "dataInclusao",
        sortOrder: SortOrder.DESC,
      });
    }
  };

  const changeUsuario = (user: Nullable<String>) => {
    user = user ? user : null;
    setUsuario(user);
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: lazyState.first / lazyState.rows + 1,
      placa: placa,
      chassi: chassi,
      usuario: user,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: "dataInclusao",
      sortOrder: SortOrder.DESC,
    });
  };

  const changePlaca = (pla: Nullable<String>) => {
    pla = pla ? pla : null;
    setPlaca(pla);
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: lazyState.first / lazyState.rows + 1,
      placa: pla,
      chassi: chassi,
      usuario: usuario,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: "dataInclusao",
      sortOrder: SortOrder.DESC,
    });
  };

  const changeChassi = (cha: Nullable<String>) => {
    cha = cha ? cha : null;
    setChassi(cha);
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: lazyState.first / lazyState.rows + 1,
      placa: placa,
      chassi: cha,
      usuario: usuario,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: "dataInclusao",
      sortOrder: SortOrder.DESC,
    });
  };

  const startContent = (
    <React.Fragment>
      <div style={{ background: "transparent" }}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label htmlFor="data" className="font-bold block mb-2">
                {t("dataInicial")}{" "}
              </label>
              <Calendar
                id="data"
                value={data}
                onChange={(e) => changeData(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                locale="pt"
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="usuario" className="font-bold block mb-2">
                  {t("usuario")}{" "}
                </label>
                <InputText
                  id="usuario"
                  onBlur={(e) => changeUsuario(e.target.value)}
                  style={{ width: "20rem" }}
                  required
                ></InputText>
              </div>
       
            </div>
          </div>
       
          
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="placa" className="font-bold block mb-2">
                  {t("placa")}
                </label>
                <InputText
                  id="placa"
                  maxLength={40}
                  onBlur={({ target }) => changePlaca(target.value)}
                  style={{ width: "20rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "transparent", marginLeft: "10rem" }}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <label htmlFor="dataFinal" className="font-bold block mb-2">
                {t("dataFinal")}{" "}
              </label>
              <Calendar
                id="dataFinal"
                value={dataFinal}
                onChange={(e) => changeDataFinal(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                locale="pt"
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="documento" className="font-bold block mb-2">
                  {t("chassi")}
                </label>
                <InputText
                  id="chassi"
                  maxLength={40}
                  onBlur={({ target }) => changeChassi(target.value)}
                  style={{ width: "20rem" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto" style={{visibility:'hidden'}}>
                    <label htmlFor="text" className="font-bold block mb-2">{t('nome')} </label>
                    <InputText id="text"  style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
          </div>
      </div>
     
    </React.Fragment>
  );
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div className="card">
        <Toolbar start={startContent} />
        <br></br>
        <br></br>
        <DataTable value={leiloes} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="usuario" header={t('usuario')}  />               
           <Column field="placa" sortable header={t('placa')}  />
           <Column field="chassi" sortable header={t('chassi')}  />
           <Column field="marca" sortable header={t('marca')}  />
           <Column field="modelo" sortable header={t('modelo')}  />
           <Column field="cor" sortable header={t('cor')}  />
           <Column field="combustivel" sortable header={t('combustivel')}  />
           <Column field="dataInclusao" sortable header={t('dtInclusao')}  />
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


