import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from "primereact/tree";
import getPermissaoUser from "../../service/getPermissaoUser";
import { Dropdown } from 'primereact/dropdown';
import { SortOrder } from "primereact/api";
import findClient from "../../service/findClient";
import findFranquia from "../../service/findFranquia";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";
import { Password } from "primereact/password";
import { Messages } from "primereact/messages";

export default function UsuarioCadastrar({user, onClose, onSave}:{user:any, onClose: () => void, onSave: (user:any) => void}) {
  const { t } = useTranslation();
  const [inativo, setInativo] = useState(user.inativo ? user.inativo : false);
  const [cache, setCache] = useState(user.cache ? user.cache : false);
  const [nodes, setNodes] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null>(null); 
  const [expandedKeyss] = useState<any>({'QUODPF': true});
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [clients, setClients] = useState<any>([]);
  const [selectedFranquia, setSelectedFranquia] = useState<any>(null);
  const [franquias, setFranquias] = useState<any>([]);
  const [idsPermissoes, setIdsPermissoes] = useState<any[]>([]);
  const [selectedUrlRedirect, setSelectedUrlRedirect] = useState(user.urlRedirect);
  const {state} = useGlobalState();
  const msgs = useRef<Messages>(null);
  const urlsRedirect = [
        { name: 'Selecione', code: null },
        { name: 'Home', code: '/creditbureau/home' },
        { name: 'Consultar', code: '/creditbureau/consultar' }
    ];
  const defaultValues = {
    login: '',
    nome: '',
    email: '',
    senha:'',
    senhaRepetir: ''
  };
  defaultValues.login = user.login ? user.login.toString() : ''
  defaultValues.nome = user.nome ? user.nome.toString() : ''
  defaultValues.email = user.email ? user.email.toString() : ''
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.nome && save(data);
  };

 
  const save = (data:any) => {
    if(data.senha === data.senhaRepetir){
      const permissoes: any[] = [];
      if(selectedKeys){
        Object.getOwnPropertyNames(selectedKeys).map((item) => permissoes.push({id:item}));
      }
  
      const usuario = {
        id: user.id? user.id : undefined,
        nome:data.nome,
        login:data.login,
        senha:data.senha,
        inativo:inativo,
        email: data.email,
        cache:cache,
        idCliente: selectedClient ? selectedClient.code : null,
        permissoes:permissoes,
        urlRedirect: selectedUrlRedirect,
        idFranquia: selectedFranquia?.id
      }
      window.scrollTo(0, 0);
      onSave(usuario);
    }
    else{
      window.scrollTo(0, 0);
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: "Senha e Repetir senha não são iguais", closable: false }
         
      ]);
    }
    
  }
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const createPermissoes = () => {
    const option : any[] = [];
    getPermissaoUser().then(data => {
      data.sort((s1:{ label: string}, s2:{label: string}) => {
        const nameA = s1.label.toUpperCase(); // ignore upper and lowercase
        const nameB = s2.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });
      data.forEach((element: { label: string; id: string; permissoes:any[] }) => {
        verifyChecked(element);
        option.push({label:element.label, key:element.id, Collapse:true, children: createPermissoesFilhos(element.permissoes) });
      });
    })
    
   
    return option;
  }
  const createPermissoesFilhos = (permissoes:any[]) => {
    const option : any[] = [];
    if(permissoes){
      permissoes.sort((s1:{ label: string}, s2:{label: string}) => {
        const nameA = s1.label.toUpperCase(); // ignore upper and lowercase
        const nameB = s2.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });
      permissoes.forEach((element: { label: string; id: string; permissoes:any[]  }) => {
        verifyChecked(element);
        option.push({label:element.label, key:element.id, Collapse:true, children: createPermissoesFilhos(element.permissoes)});
      });
    }
    return option;
  }

  const verifyChecked = (element:any) => {

    if(user.permissoes){
      const checked = user.permissoes.find((k: { value: any; }) => k.value === element.value);
      if(checked){
        idsPermissoes.push(element.id)
        const selectedKeysChecked: SetStateAction<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null> = {
      
        }

        idsPermissoes.forEach(id => {
          selectedKeysChecked[id] = {
            partialChecked: false,
            checked: true
          }
        });
        
       setSelectedKeys(selectedKeysChecked);
      }
    }
  }

  useEffect(() => {
    setNodes(createPermissoes());
   
    const lazyClient = {
      first: 0,
      rows: 99999999,
      page: 1,
      cliente: 'null',
      documento: 'null',
      codigoClienteErp: 'null',
      idFranquia : state.user.franquiaSelected?.id,
      sortField: 'nome',
      sortOrder: SortOrder.DESC
    }
    const clientes : any[] = [];
    findClient(lazyClient).then(data => {
      data.clientes.forEach( (c: { nome: string; id: string; }) => {
        clientes.push({name: c.nome, code:c.id})
        if(user.id && user.idCliente && c.nome == user.nomeCliente){
          setSelectedClient({name:user.nomeCliente, code:c.id})
        }
       
        if(state.user.cliente && c.nome == state.user.cliente.nome){
          setSelectedClient({name:state.user.cliente.nome, code:c.id})
        }
      })
    })
    setClients(clientes);
    const lazyFranquia = {
      first: 0,
      rows: 9999,
      page: 1,
      franquia: null,
      documento: null,
      sortField: 'nome',
      sortOrder: SortOrder.DESC
    };
   
    findFranquia(lazyFranquia).then(data => {
      data.franquias.forEach( (c: { nome: string; id: string; }) => {
        if(user.id && user.idFranquia && c.nome == user.nomeFranquia){
          setSelectedFranquia(c)
        }
       
        if(state.user.franquiaSelected && c.nome == state.user.franquiaSelected.nome){
          setSelectedFranquia(c)
        }
      })
      setFranquias(data.franquias);
    })
   
    
  },[])

  return (
    <>
    <Messages ref={msgs} />
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="nome"
                      control={control}
                      rules={{ required: 'Nome é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.nome })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="nome" className="font-bold block mb-2 required">{t('nome')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="login"
                      control={control}
                      rules={{ required: 'Login é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.login })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="login" className="font-bold block mb-2 required">{t('login')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          {!user.id ? (
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="senha"
                      control={control}
                      rules={{ required: 'Senha é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.senha })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="nome" className="font-bold block mb-2 required">{t('senha')} </label>
                                <Password style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="senhaRepetir"
                      control={control}
                      rules={{ required: 'Senha é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.senhaRepetir })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="senhaRepetir" className="font-bold block mb-2 required">{t('repetirNovaSenha')} </label>
                                <Password style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          ): (
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="senha"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.senha })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="nome" className="font-bold block mb-2 required">{t('senha')} </label>
                                <Password style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                </div>
                <div className="col">
                  <Controller
                      name="senhaRepetir"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.senhaRepetir })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="senhaRepetir" className="font-bold block mb-2 required">{t('repetirNovaSenha')} </label>
                                <Password style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              </div>
          )}
          <div className="grid">
          <div className="col p-fluid flex flex-wrap">
                
                <Controller
                    name="email"
                    control={control}
                    rules={{ required: 'Email é Obrigatório' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.nome })}></label>
                            <span className="">
                            <div>
                              <label htmlFor="email" className="font-bold block mb-2 required">{t('email')} </label>
                              <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                  
             
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('cliente')}</label>
                <Dropdown disabled={state.user.cliente} style={{width: '50%'}} value={selectedClient} onChange={(e) => setSelectedClient(e.value)} options={clients} optionLabel="name" placeholder="Selecione Cliente" 
                        filter className="w-full md:w-14rem" />  
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados" style={{paddingLeft:'0'}}>{t('urlRedirect')}</label>
                <Dropdown style={{width: '50%'}} value={selectedUrlRedirect} onChange={(e) => setSelectedUrlRedirect(e.value)} options={urlsRedirect} optionLabel="name" 
                    placeholder="Selecione" optionValue='code' className="w-full md:w-14rem" />
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              {!state.user.franquia && (
                <div>
                  <label className="label-dados" style={{paddingLeft:'0'}}>{t('franquia')}</label>
                  <Dropdown disabled ={state.user.franquiaSelected} style={{width: '50%'}} value={selectedFranquia} onChange={(e) => setSelectedFranquia(e.value)} options={franquias} optionLabel="nome" 
                      placeholder="Selecione" className="w-full md:w-14rem" />
                </div>
              )}
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                  <label className="label-dados" style={{paddingLeft:'0'}}>{t('inativo')}</label>
                  <div style={{width:'100%'}}>
                    <InputSwitch checked={inativo} value={inativo} onChange={() => setInativo(!inativo)} />
                  </div>
              </div>
            </div>
            <div className="col">
            {state.user.funcoes.includes('USUARIO_CACHE') && (
              <div >
                  <label className="label-dados">{t('cache')}</label>
                  <div style={{width:'100%'}}>
                    <InputSwitch checked={cache} onChange={() => setCache(!cache)} />
                  </div>
              </div>
            )}
            
            </div>
          </div>
        </div>
        <Tree expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandedKeyss} value={nodes} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => setSelectedKeys(e.value)} />
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    </>
  )
}

