import React, {
    createContext,
    useState,
    useContext,
    Dispatch,
    SetStateAction,
    useEffect
  } from "react";
import { User } from "../../interface/User";
  
  export interface GlobalStateInterface {
    init: boolean;
    sidebarOpen: boolean;
    user: any;
    token: string;
    relatorios: string;
    franquias: [] | undefined
    submenu: String[]
  }

  const GlobalStateContext = createContext({
    state: {} as Partial<GlobalStateInterface>,
    setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
  });
  
  interface GlobalStateProviderInterface {
    children: React.ReactNode;
    value?: GlobalStateInterface;
  }
  
  const GlobalStateProvider = ({
    children,
    value = {} as GlobalStateInterface,
  }: GlobalStateProviderInterface) => {
    //const [state, setState] = useState(value);
   
   const valueDefault = localStorage.getItem('localState') != null ? JSON.parse(localStorage.getItem('localState')!) as Partial<GlobalStateInterface> : value
   
    //const [state, setState] = React.useReducer(
      //(state: any, newValue: any) => ({ ...state, ...newValue }),
      //valueDefault);
   
    const [state, setState] = useState(valueDefault);
    if(state.user){
  
     
    }
    
    useEffect(() => {
        localStorage.setItem('localState', JSON.stringify(state));
    }, [{state}]);
    return (
      <GlobalStateContext.Provider value={{state, setState}}>
        {children}
      </GlobalStateContext.Provider>
    );
  };

  
  const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
   
    if (!context) {
      throw new Error("useGlobalState must be used within a GlobalStateContext");
    }
    return context;
  };

  const getState = () => {
    return  JSON.parse(localStorage.getItem('localState')!)
  }
  
  const Debug = () => {
    const { state } = useContext(GlobalStateContext);
    return <pre>{JSON.stringify(state || {}, null, "\t")}</pre>;
  };
  
  export { GlobalStateProvider, useGlobalState, Debug, getState};