import { IAutomotivaPerdaTotal } from "../../../../interface/AutomotivaPerdaTotal";
import styles from "./styles.module.css";

interface AutomotivaPerdaTotalProps {
  data: IAutomotivaPerdaTotal | null;
}

export const AutomotivaPerdaTotal = ({ data }: AutomotivaPerdaTotalProps) => {
  const infoVehicle = data?.PTXML.CHAVE.split(" ").map(
    (entry, index, array) => {
      return {
        placa: array[0],
        chassi: array[1],
      };
    }
  )[0];

  return (
    <div style={{ width: "98%" }}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>Detalhes Automotiva Perda Total</p>
        </div>
        <div>
          <div className={styles.listaWrapper}>
            <div className={styles.dadosWrapper}>
              <p className={styles.text}>Placa: </p>&nbsp;
              <p className={`${styles.text} ${styles?.value}`}>
                {infoVehicle?.placa}
              </p>
            </div>
            <div className={styles.dadosWrapper}>
              <p className={styles.text}>Chassi: </p>&nbsp;
              <p className={`${styles.text} ${styles.value}`}>
                {infoVehicle?.chassi}
              </p>
            </div>
            <div className={styles.dadosWrapper}>
              <p className={styles.text}>Sinistro: </p>&nbsp;
              <p className={`${styles.text} ${styles.value}`}>
                {data?.PTXML.MSG_ERRO !== ""
                  ? data?.PTXML.MSG_ERRO
                  : data.PTXML.MENSAGEM.replace("0-", "")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
