import { SenatranVeicularProps } from "./types";
import { Panel } from "primereact/panel";
import "./index.css";
import { formatDefaultValuesToString } from "../../../../utils/ValidationUtils";
import OutPutCard from "../../../../components/outputCard";
import { Divider } from "primereact/divider";

const labelVeiculo = (key: string): string => {
  const labels: { [key: string]: string } = {
    chassi: "Chassi",
    placa: "Placa",
    codigoRenavam: "Código RENAVAM",
    situacao: "Situação",
    codigoMunicipioEmplacamento: "Código do Município de Emplacamento",
    descricaoMunicipioEmplacamento: "Descrição do Município de Emplacamento",
    ufJurisdicao: "UF de Jurisdição",
    codigoRemarcacaoChassi: "Código de Remarcação do Chassi",
    descricaoRemarcacaoChassi: "Descrição de Remarcação do Chassi",
    codigoTipoVeiculo: "Código do Tipo de Veículo",
    descricaoTipoVeiculo: "Descrição do Tipo de Veículo",
    codigoMarcaModelo: "Código da Marca/Modelo",
    descricaoMarcaModelo: "Descrição da Marca/Modelo",
    codigoEspecieVeiculo: "Código da Espécie do Veículo",
    descricaoEspecieVeiculo: "Descrição da Espécie do Veículo",
    codigoTipoCarroceria: "Código do Tipo de Carroceria",
    descricaoTipoCarroceria: "Descrição do Tipo de Carroceria",
    codigoCor: "Código da Cor",
    descricaoCor: "Descrição da Cor",
    codigoCategoria: "Código da Categoria",
    descricaoCategoria: "Descrição da Categoria",
    anoModelo: "Ano do Modelo",
    anoFabricacao: "Ano de Fabricação",
    potencia: "Potência",
    cilindradas: "Cilindradas",
    codigoCombustivel: "Código do Combustível",
    descricaoCombustivel: "Descrição do Combustível",
    numeroMotor: "Número do Motor",
    cmt: "CMT",
    pbt: "PBT",
    cmc: "CMC",
    procedencia: "Procedência",
    numeroCambio: "Número do Câmbio",
    codigoTipoProprietario: "Código do Tipo de Proprietário",
    descricaoTipoProprietario: "Descrição do Tipo de Proprietário",
    numeroIdentificacaoProprietario: "Número de Identificação do Proprietário",
    numeroCarroceria: "Número da Carroceria",
    codigoRestricao1: "Código da Restrição 1",
    descricaoRestricao1: "Descrição da Restrição 1",
    codigoRestricao2: "Código da Restrição 2",
    descricaoRestricao2: "Descrição da Restrição 2",
    codigoRestricao3: "Código da Restrição 3",
    descricaoRestricao3: "Descrição da Restrição 3",
    codigoRestricao4: "Código da Restrição 4",
    descricaoRestricao4: "Descrição da Restrição 4",
    qtdEixos: "Quantidade de Eixos",
    numeroEixoTraseiro: "Número do Eixo Traseiro",
    numeroEixoAuxiliar: "Número do Eixo Auxiliar",
    nomeProprietario: "Nome do Proprietário",
    lotacao: "Lotação",
    dataEmissaoCrv: "Data de Emissão do CRV",
    naturezaImportacao: "Natureza da Importação",
    descricaoDocImportador: "Descrição do Documento do Importador",
    numeroIdImportador: "Número de Identificação do Importador",
    codigoOrgaoRfb: "Código do Órgão da RFB",
    descricaoOrgaoRfb: "Descrição do Órgão da RFB",
    registroAduaneiro: "Registro Aduaneiro",
    numeroDeclaracaoImportacao: "Número da Declaração de Importação",
    dataDistImportacao: "Data de Distribuição da Importação",
    naturezaFaturamento: "Natureza do Faturamento",
    tipoDocFaturado: "Tipo de Documento Faturado",
    numeroIdFaturamento: "Número de Identificação do Faturamento",
    ufFaturado: "UF do Faturamento",
    dataLimite: "Data Limite",
    dataUltimaAtualizacaoImportacao: "Data da Última Atualização da Importação",
    codigoTipoImportacao: "Código do Tipo de Importação",
    descricaoTipoImportacao: "Descrição do Tipo de Importação",
    numeroProcessoImportacao: "Número do Processo de Importação",
    dataBaixaImportacao: "Data de Baixa da Importação",
    codigoPaisTransferencia: "Código do País de Transferência",
    descricaoPaisTransferencia: "Descrição do País de Transferência",
    indicadorMultaRenainf: "Indicador de Multa RENAINF",
    indicadorComunicacaoVenda: "Indicador de Comunicação de Venda",
    indicadorPendenciaEmissao: "Indicador de Pendência de Emissão",
    indicadorRestricaoRenajud: "Indicador de Restrição RENAJUD",
    indicadorRecall1: "Indicador de Recall 1",
    indicadorRecall2: "Indicador de Recall 2",
    indicadorRecall3: "Indicador de Recall 3",
    indicadorRecall4: "Indicador de Recall 4",
    tipoDocProprietarioIndicado: "Tipo de Documento do Proprietário Indicado",
    descricaoDocProprietarioIndicado:
      "Descrição do Documento do Proprietário Indicado",
    numeroDocProprietarioIndicado:
      "Número do Documento do Proprietário Indicado",
    dataAtualizacaoMre: "Data de Atualização MRE",
    indicadorSiniav: "Indicador SINIAV",
    codigoOrigemPropriedade: "Código de Origem da Propriedade",
    indicadorRestricaoRfb: "Indicador de Restrição RFB",
    descricaoRestricaoRfb: "Descrição de Restrição RFB",
    indicadorLeilao: "Indicador de Leilão",
    indicadorRouboFurto: "Indicador de Roubo/Furto",
    indicadorAlarme: "Indicador de Alarme",
    isPesquisado: "É Pesquisado",
    dataCrlv: "Data do CRLV",
    numeroCrlv: "Número do CRLV",
    dataTransferencia: "Data de Transferência",
    servicoConsultado: "Serviço Consultado",
    anoExercicioLicenciamentoPago: "Ano do Exercício de Licenciamento Pago",
    dataEmissaoCRLV: "Data de Emissão do CRLV",
  };

  return labels[key] || key;
};

const getDadosGerais = (dados: any): JSX.Element[] => {
  let dadosFormatados: { label: string; value: string }[] = [];

  const resultado: JSX.Element[] = [];

  if (dados) {
    dadosFormatados = Object.entries(dados)
      .filter(
        ([key, value]) =>
          labelVeiculo(key) !== key &&
          value !== null &&
          value !== undefined &&
          value !== ""
      )
      .map(([key, value]) => ({
        label: labelVeiculo(key),
        value: formatDefaultValuesToString(value),
      }));
    resultado.push(
      <div key="dados">
        <Divider align="left">
          <span
            className="p-tag"
            style={{ backgroundColor: "#183462", fontSize: "1.125rem" }}
          >
            Dados Gerais
          </span>
        </Divider>
        <div className="grid">
          {dadosFormatados.map((item: any, index: number) => (
            <div className="col p-fluid flex flex-wrap" key={index}>
              <OutPutCard label={item.label} value={item.value}></OutPutCard>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return resultado;
};

export function SenatranVeicular({ data }: SenatranVeicularProps) {
  const dadosGerais = getDadosGerais(data);

  return (
    <Panel
      className="p-panel-header-green"
      header="Automotiva Senatran Veicular"
      style={{ width: "98%" }}
      toggleable
    >
      <>
        {dadosGerais.map((tabela) => (
          <div key={tabela.key}>{tabela}</div>
        ))}
        <br></br>
      </>
    </Panel>
  );
}
