import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import getTipoParametroAplicacaoLivre from "../../service/getTipoParametroAplicacaoLivre";
import getTipoParametroAplicacao from "../../service/getTipoParametroAplicacao";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";


export default function ParametroAplicacaoCadastrar({parametroAplicacao, onClose, onSave}:{parametroAplicacao:any, onClose: () => void, onSave: (parametroAplicacao:any) => void}) {
  const { t } = useTranslation();
  const [tipos, setTipos] = useState<any>([]);
  const defaultValues = {
    valor: '',
    tipo: null
  };
  defaultValues.valor = parametroAplicacao.valor ? parametroAplicacao.valor.toString() : ''
  defaultValues.tipo = parametroAplicacao.tipo ? parametroAplicacao.tipo.toString() : null
 
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.valor && save(data);
  };

 
  const save = (data:any) => {
    const parametro = {
      id: parametroAplicacao.id? parametroAplicacao.id : undefined,
      valor:data.valor,
      tipo:data.tipo
    }
    window.scrollTo(0, 0);
    onSave(parametro);
  }
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  useEffect(() => {
    if(parametroAplicacao.id){
      getTipoParametroAplicacao().then( (data) => setTipos(data))
    }
    else{
      getTipoParametroAplicacaoLivre().then( (data) => setTipos(data))
    }
    
  }, [])



  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="tipo"
                      control={control}
                      rules={{ required: 'Tipo é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipo })}></label>
                              <span className="">
                              <div>
                              <label htmlFor="tipoBilhete" className="font-bold block mb-2 required">{t('tipo')} </label>
                                <Dropdown disabled={parametroAplicacao.id} style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tipos} optionLabel="label" placeholder="Selecione Tipo" 
                                  filter className="w-full md:w-14rem" />  
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                  
                    
               
              </div>
              <div className="col">
                <Controller
                      name="valor"
                      control={control}
                      rules={{ required: 'Valor é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.valor })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="valor" className="font-bold block mb-2 required">{t('valor')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          
       
        </div>
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}

