import {
  ApiResponseAutomotivaIndicioSinistro01,
  DadosFormatados,
  AutomotivaIndicioSinistro01Props,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from 'primereact/message';
import './index.css';
import { formatDefaultValuesToString } from '../../../../utils/ValidationUtils';


const labelDadosGerais = (key: string): string => {
  const labelDadosGerais: { [key: string]: string } = {
    CODIGO: "Código:",
    DESCRICAO: "Descrição:",
  };

  return labelDadosGerais[key] || key;
};

const formatarDadosGerais = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados.STATUS_RETORNO).filter(([key]) => labelDadosGerais(key) !== key).map(([key, value]) => ({
    label: labelDadosGerais(key),
    value: formatDefaultValuesToString(value as string),
  }));

  const resultado: JSX.Element[] = [];
  if (dados.OCORRENCIAS) {
    const renderizarDinamico = (rowData: any, field: string) => {
      const value = rowData[field];
      return formatDefaultValuesToString(value);
    };

    resultado.push(
      <DataTable header="Ocorrências" size="normal" stripedRows value={dados.OCORRENCIAS}>
        <Column field="STATUS_RETORNO" header="Status Retorno" body={(rowData) => renderizarDinamico(rowData, 'STATUS_RETORNO')} />
        <Column field="NOME_BASE" header="Nome Base" />
        <Column field="EXISTE_OCORRENCIA" header="Existe Ocorrências" />
        <Column field="DESCRICAO_OCORRENCIA" header="Descrição Ocorrências" />
      </DataTable>
    );
  }

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    array: resultado,
  };
};

const getDadosGerais = (
  data: ApiResponseAutomotivaIndicioSinistro01
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.INDICIO_SINISTRO_CONJUGADO)
    resultado.push(formatarDadosGerais(data.INDICIO_SINISTRO_CONJUGADO, "Indício de Sinistro Conjugado"));

  return resultado;
};

export function AutomotivaIndicioSinistro01({
  data,
}: AutomotivaIndicioSinistro01Props) {
  const dados = getDadosGerais(data);

  return (
    <>
      <Panel className="p-panel-header-green" header="Automotiva Indício de Sinistro 01" style={{ width: '98%' }} toggleable>
        {dados.map(({ categoria, dados, array }) => (
          <><Panel className="p-panel-header-green" header={categoria} toggleable>
            {data.INDICIO_SINISTRO_CONJUGADO.QUANTIDADE_OCORRENCIAS && <><Message severity="info" text={"Quantidade de Ocorrências: " + data.INDICIO_SINISTRO_CONJUGADO.QUANTIDADE_OCORRENCIAS} /><br></br><br></br></>}
            <DataTable header="Status Retorno" showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          </Panel><br></br></>
        ))}
      </Panel>
    </>
  );
}
