import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { currencyFormatter } from "../../../utils/formatters";
 
 
 
export default function PendenciaCompany({
  pendenciaCompany
}: {
  pendenciaCompany: any
}) {
  const { t } = useTranslation();
 
  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  }
 
  const avalista = (item:any) => {
    return item.avalista ? 'Sim' : 'Não';
  };
 
  const valor = (item:any) => {
    return currencyFormatter(item.valorPendencia);
  };
 
  const valorSPC = (item:any) => {
    return currencyFormatter(item.valor);
  };
 
  const dataConsulta = (item:any) => {
    return formatDate(new Date(item.dataConsulta));
  };
 
  const dataUltimoCheque = (item:any) => {
    return formatDate(new Date(item.dataUltimoCheque));
  };
  
 
  const dataInclusao = (item:any) => {
    return formatDate(new Date(item.dataInclusao));
  };
 
  const dataVencimento = (item:any) => {
    return formatDate(new Date(item.dataVencimento));
  };
 
  const dataRegistro = (item:any) => {
    return formatDate(new Date(item.dataOcorrencia));
  };
 
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> <span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('pendencias')}</span></span></div>;
  }
 
  const headerTemplateNegativacao = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> <span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('negativacoes')}</span></span></div>;
  }
 
  const headerTemplateCCF = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> <span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('ccf')}</span></span></div>;
  }
  
  return (
    <>
      {pendenciaCompany.consumidor.consumidorPessoaJuridica && (
        <Panel header={t('consumidor')}>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('razaoSocial')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.razaoSocial} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('nomeFantasia')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.nomeComercial} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('documento')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.cnpj.numero} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('dataFundacao')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.dataFundacao} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('dataSituacao')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.situacaoCnpj?.dataSituacao} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('situacao')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.situacaoCnpj?.descricaoSituacao} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('naturezaJuridica')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.naturezaJuridica?.codigo + ' - ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.naturezaJuridica?.descricao} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('atividadePrincipal')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.atividadeEconomicaPrincipal?.codigo + ' - ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.atividadeEconomicaPrincipal?.descricao} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('email')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.email} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('atividadeSecundaria')}</label>
                    {pendenciaCompany.consumidor.consumidorPessoaJuridica.atividadeEconomicaSecundaria.map((item:any) => {
                      return <Chip style={{width:"100%"}} label={item.codigo + ' - ' + item.descricao} />
                    })}
                    
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('endereco')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.logradouro + ', ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.numero + ' ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.bairro + (pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.complemento ? ' ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.complemento : '' ) + ' - ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.cidade.nome + '/' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.cidade.estado.siglaUf + ' - ' + pendenciaCompany.consumidor.consumidorPessoaJuridica.endereco.cep} />
                </div>
              </div>
              
          </div>
        </Panel>
      )}
      
      {pendenciaCompany.consumidor.consumidorPessoaFisica && (
        <Panel header={t('consumidor')}>
          
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('nome')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.nome} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('nomeMae')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.nomeMae} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('nomePai')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.nomePai ? pendenciaCompany.consumidor.consumidorPessoaFisica.nomePai : 'Não Informado'} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('estadoCivil')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.estadoCivil ? pendenciaCompany.consumidor.consumidorPessoaFisica.estadoCivil : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('rg')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.numeroRg ? pendenciaCompany.consumidor.consumidorPessoaFisica.numeroRg : 'Não Informado'} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('ufRg')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.estadoRg ? pendenciaCompany.consumidor.consumidorPessoaFisica.estadoRg.siglaUf : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('documento')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.cpf.numero} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('origem')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.cpf.regiaoOrigem} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('dataSituacao')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.situacaoCpf?.dataSituacao} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('situacao')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.situacaoCpf?.descricaoSituacao} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('dataNascimento')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.dataNascimento} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('sexo')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.sexo} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('fone')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneResidencial ? '(' + pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneResidencial.numeroDdd + ')' + ' ' + pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneResidencial.numero : 'Não Informado'} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('celular')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneCelular ? '(' + pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneCelular.numeroDdd + ')' + ' ' + pendenciaCompany.consumidor.consumidorPessoaFisica.telefoneCelular.numero : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('data')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.data} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('email')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.email ? pendenciaCompany.consumidor.consumidorPessoaFisica.email : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('endereco')}</label>
                    <Chip style={{width:"100%"}} label={pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.logradouro + ', ' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.numero + ' ' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.bairro + (pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.complemento ? ' ' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.complemento : '' ) + ' - ' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.cidade.nome + '/' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.cidade.estado.siglaUf + ' - ' + pendenciaCompany.consumidor.consumidorPessoaFisica.endereco.cep} />
                </div>
              </div>
              
          </div>
        </Panel>
      )}
      {pendenciaCompany.ultimoEnderecoInformado && pendenciaCompany.ultimoEnderecoInformado.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && (
        <>
          <br></br>
          <Panel header={t('historicoEndereco')}>
            <DataTable size="small" value={pendenciaCompany.ultimoEnderecoInformado.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column field="endereco.logradouro" header={t('logradouro')}></Column>
              <Column field="endereco.bairro" header={t('bairro')}></Column>
              <Column field="endereco.numero" header={t('numero')}></Column>
              <Column field="endereco.cep" header={t('cep')}></Column>
              <Column field="endereco.cidade.nome" header={t('cidade')}></Column>
              <Column field="endereco.cidade.estado.siglaUf" header={t('uf')}></Column>
            </DataTable>
          </Panel>
        </>
      )}
      {pendenciaCompany.consultaRealizada && pendenciaCompany.consultaRealizada.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && (
        <>
          <br></br>
          <Panel header={t('consultas')}>
            <DataTable size="small" value={pendenciaCompany.consultaRealizada.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column body={dataConsulta} header={t('data')}></Column>
              <Column field="nomeAssociado" header={t('empresa')}></Column>
              <Column field="nomeEntidadeOrigem" header={t('origem')}></Column>
              <Column field="origemAssociado.nome" header={t('cidade')}></Column>
              <Column field="origemAssociado.estado.siglaUf" header={t('uf')}></Column>
            </DataTable>
          </Panel>
        </>
      )}
       {pendenciaCompany.pendenciaFinanceira && pendenciaCompany.pendenciaFinanceira.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && pendenciaCompany.pendenciaFinanceira.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista.length > 0 && (
        <>
          <br></br>
          <Panel headerTemplate={headerTemplate}>
            <DataTable size="small" value={pendenciaCompany.pendenciaFinanceira.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column body={dataRegistro} header={t('dataRegistro')}></Column>
              <Column body={avalista} header={t('avalista')}></Column>
              <Column field="contrato" header={t('contrato')}></Column>
              <Column body={valor} header={t('valor')}></Column>
              <Column field="origem" header={t('empresa')}></Column>
              <Column field="tituloOcorrencia" header={t('natureza')}></Column>
              <Column field="cidade.nome" header={t('cidade')}></Column>
              <Column field="cidade.estado.siglaUf" header={t('uf')}></Column>
            </DataTable>
          </Panel>
        </>
      )}
 
      {pendenciaCompany.spc && pendenciaCompany.spc.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && pendenciaCompany.spc.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista.length > 0 && (
        <>
          <br></br>
          <Panel headerTemplate={headerTemplateNegativacao}>
            <DataTable size="small" value={pendenciaCompany.spc.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column body={dataInclusao} header={t('dataRegistro')}></Column>
              <Column body={dataVencimento} header={t('dataVencimento')}></Column>
              <Column field="compradorFiadorAvalista" header={t('tipo')}></Column>
              <Column field="contrato" header={t('contrato')}></Column>
              <Column body={valorSPC} header={t('valor')}></Column>
              <Column field="nomeAssociado" header={t('empresa')}></Column>
              <Column field="cidadeAssociado.nome" header={t('cidade')}></Column>
              <Column field="cidadeAssociado.estado.siglaUf" header={t('uf')}></Column>
            </DataTable>
          </Panel>
        </>
      )}
 
      {pendenciaCompany.ccf && pendenciaCompany.ccf.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && pendenciaCompany.ccf.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista.length > 0 && (
        <>
          <br></br>
          <Panel headerTemplate={headerTemplateCCF}>
            <DataTable size="small" value={pendenciaCompany.ccf.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column field="ultimoCheque.dadosBancarios.banco.codigo" header={t('codigoBanco')}></Column>
              <Column field="ultimoCheque.dadosBancarios.banco.nome" header={t('banco')}></Column>
              <Column field="ultimoCheque.dadosBancarios.numeroAgencia" header={t('agencia')}></Column>
              <Column field="motivo.codigo" header={t('motivo')}></Column>
              <Column field="quantidade" header={t('quantidade')}></Column>
              <Column body={dataUltimoCheque} header={t('dataUltimaOcorrencia')}></Column>
            </DataTable>
          </Panel>
        </>
      )}
 
      {pendenciaCompany.grafiaPj && pendenciaCompany.grafiaPj.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista && pendenciaCompany.grafiaPj.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista.length > 0 && (
        <>
          <br></br>
          <Panel header="Grafias">
            <DataTable size="small" value={pendenciaCompany.grafiaPj.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista} tableStyle={{ minWidth: '50rem' }}>
              <Column field="razaoSocial" header={t('razaoSocial')}></Column>
              
            </DataTable>
          </Panel>
        </>
      )}
 
 
    </>
  )
}