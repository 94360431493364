/* eslint-disable no-extend-native */

String.prototype.maskCPF = function (): string {
  return this.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

String.prototype.formatDate = function (): string {
  if (!this) return "";
  const newDate = new Date(this);
  return newDate.toLocaleDateString("pt-BR");
};

String.prototype.isEquals = function (value: string): boolean {
  if (typeof value !== "string") return false;
  return this === value;
};

String.prototype.maskCpfCnpj = function (): string {
  if (this.length > 14) {
    return this.replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2");
  }

  return this.replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};

String.prototype.maskCep = function (): string {
  return this.replace(/\D/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
};

export {};
