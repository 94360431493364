import { Image } from "primereact/image";
import styles from "./styles.module.css";
import { images } from "../../assets";
import { useState } from "react";

interface AlertStatusProps {
  date: string;
  data?: {
    tipo: string;
    status: string;
  }[];
}

export const AlertaStatus = ({ date, data }: AlertStatusProps) => {
  const newValue = date.split(" ");
  const restriction = "CONSTA";
  const withoutRestriction = "NADA_CONTA";

  const [consult] = useState({
    date: newValue[0],
    time: newValue[1],
  });

  function compareTo(type: string) {
    let status = "";

    if (data === null) return null;

    data?.forEach((item) => {
      if (item.tipo === type) {
        return restriction === item.status
          ? (status = restriction)
          : (status = withoutRestriction);
      }
    });

    return status;
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerItem}>
          <i className={`pi pi-calendar ${styles.headerIcon}`}></i>
          <span className={styles.label}>DATA DA PESQUISA:</span>
          <span className={styles.value}>{consult.date}</span>
        </div>
        <div className={styles.headerItem}>
          <i className={`pi pi-clock ${styles.headerIcon}`}></i>
          <span className={styles.value}>{consult.time}</span>
        </div>
      </div>

      <div className={styles.alertContainer}>
        {alertStatus.map((status, index) => {
          if (compareTo(status.idLabel) === restriction) {
            return (
              <div key={index} className={`${styles.alertBox} ${styles.red}`}>
                <Image
                  key={status.text}
                  src={status.icon}
                  alt={`${status.text}`}
                  width="100px"
                  height="100px"
                />
              </div>
            );
          }

          if (compareTo(status.idLabel) === withoutRestriction) {
            return (
              <div key={index} className={`${styles.alertBox} ${styles.gray}`}>
                <Image
                  key={status.text}
                  src={status.icon}
                  alt={`${status.text}`}
                  width="100px"
                  height="100px"
                />
              </div>
            );
          }

          return compareTo(status.idLabel) === null && <></>;
        })}
      </div>
    </>
  );
};

const alertStatus = [
  {
    text: "RESTRIÇÕES",
    idLabel: "",
    icon: images.RestricaoImg,
  },
  {
    text: "MULTAS E DÉBITOS",
    idLabel: "",
    icon: images.MultasDebitosImg,
  },
  {
    text: "INDÍCIO DE SINISTRO",
    idLabel: "",
    icon: images.SinistroImg,
  },
  {
    text: "COMUNICAÇÃO DE VENDA",
    idLabel: "",
    icon: images.ComunicacaoVendaImg,
  },
  {
    text: "ROUBO E FURTO",
    idLabel: "ROUBO_FURTO",
    icon: images.RouboFurtoImg,
  },
  {
    text: "VENDA DIRETA",
    idLabel: "",
    icon: images.VendaDiretaImg,
  },
  {
    text: "LEILÃO",
    idLabel: "LEILAO",
    icon: images.LeilaoImg,
  },
  {
    text: "REMARKETING",
    idLabel: "",
    icon: images.RemarketingImg,
  },
  {
    text: "DUPLICIDADE DE MOTOR",
    idLabel: "",
    icon: images.DuplicidadeMotorImg,
  },
  {
    text: "CHASSI REMARCADO",
    idLabel: "",
    icon: images.ChassiRemarcadoImg,
  },
  {
    text: "RENAJUD",
    idLabel: "",
    icon: images.RenajudImg,
  },
  {
    text: "OUTROS ALERTAS",
    idLabel: "",
    icon: images.OutrosAltertasImg,
  },
];
